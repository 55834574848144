import { NavLink } from 'react-router-dom';
import { Typography } from '@mui/material';
import { NetworkOrTokenIcon } from 'modules/common/components/NetworkOrTokenIcon';
import { ETH_ROUNDING } from 'modules/common/const';
import { StatusIcon } from 'modules/nft-manager/components/StatusIcon';
import { INftItem, NftStatus } from 'modules/nft-manager/types';
import { t } from 'modules/utils/intl';
import { useCopyHook } from 'modules/common/utils/useCopyHook';
import { getShortId } from 'modules/common/utils/getShortId';
import { useNftTileStyles } from './useNftTileStyles';
import { Box } from '@mui/system';
import { uid } from 'react-uid';
import { RoutesConfig } from 'modules/nft-manager/Routes';
import { MiragePlazaLink } from 'modules/nft-manager/components/MiragePlazaLink';
import { StopSaleButton } from 'modules/nft-manager/components/StopSaleButton';
import { LaunchButton } from 'modules/nft-manager/components/LaunchButton';
import { CopyWrappedText } from 'modules/nft-manager/components/CopyWrappedText';
import { getTokenStandartName } from 'modules/common/utils/getTokenStandartName';

export const NftTile = ({
  id,
  tokenId,
  name,
  network,
  token,
  tokenPrice,
  usdPrice,
  nftAsset,
  status,
  address,
  tokenTemplate,
  royalty,
  instances,
  plazaLink,
  stopSale,
  launchOnMarketplace,
}: INftItem): JSX.Element => {
  const { classes, cx } = useNftTileStyles(undefined);

  const { isCopied: isAddressCopied, handleCopy: handleAddressCopy } =
    useCopyHook();
  const { isCopied: isIdCopied, handleCopy: handleIdCopy } = useCopyHook();

  return (
    <div className={classes.root}>
      <div className={classes.mainContent}>
        <img
          alt=""
          className={classes.image}
          src={nftAsset ? nftAsset.fileUri : ''}
        />
        <div className={classes.info}>
          <div className={classes.spaceBetween}>
            <Box display="flex">
              <Typography className={classes.name} variant="h4">
                {name}
              </Typography>
              <Typography variant="h4">
                {t('utils.hashtag', {
                  value: tokenId,
                })}
              </Typography>
            </Box>
            <NetworkOrTokenIcon
              key={uid(network)}
              name={network}
              className={classes.tokenIcon}
            />
          </div>
          <div className={classes.stats}>
            <StatusIcon className={classes.mrIcon} status={status} />

            {status === NftStatus.NotListed ? (
              <Typography variant="captionAll" color="textSecondary">
                {t('nft.not-listed')}
              </Typography>
            ) : (
              <>
                <Typography variant="body2" mr={4}>
                  {t('formats.token-value', {
                    value: tokenPrice.decimalPlaces(ETH_ROUNDING).toFixed(),
                    token,
                  })}
                </Typography>
                <Typography variant="captionAll" color="textSecondary">
                  {t('formats.usd', {
                    value: usdPrice.decimalPlaces(2).toFixed(),
                  })}
                </Typography>
              </>
            )}
          </div>
        </div>
      </div>

      <NavLink
        to={RoutesConfig.nft.generatePath(id)}
        className={classes.detailsContent}
      >
        <div className={cx(classes.spaceBetween, classes.mb1)}>
          <Box display="flex">
            <Typography className={classes.name} variant="h4">
              {name}
            </Typography>
            <Typography variant="h4">
              {t('utils.hashtag', {
                value: tokenId,
              })}
            </Typography>
          </Box>
          <NetworkOrTokenIcon
            key={uid(network)}
            name={network}
            className={classes.tokenIcon}
          />
        </div>
        <StatusIcon
          withText
          className={cx(classes.mrIcon, classes.mb5)}
          status={status}
        />
        <div className={cx(classes.spaceBetween, classes.mb2)}>
          <Typography variant="body2">
            {t('nft.collection-info.contract-address')}
          </Typography>
          <CopyWrappedText
            text={getShortId(address)}
            textForCopy={address}
            isCopied={isAddressCopied}
            handleCopy={handleAddressCopy}
          />
        </div>
        <div className={cx(classes.spaceBetween, classes.mb2)}>
          <Typography variant="body2">
            {t('nft.collection-info.token-id')}
          </Typography>
          <CopyWrappedText
            text={tokenId}
            textForCopy={tokenId}
            isCopied={isIdCopied}
            handleCopy={handleIdCopy}
          />
        </div>
        <div className={cx(classes.spaceBetween, classes.mb2)}>
          <Typography variant="body2">
            {t('nft.collection-info.token-template')}
          </Typography>
          <Typography variant="body2">
            {getTokenStandartName(tokenTemplate)}
          </Typography>
        </div>
        <div className={cx(classes.spaceBetween, classes.mb2)}>
          <Typography variant="body2">
            {t('nft.collection-info.royalty')}
          </Typography>
          <Typography variant="body2">
            {t('formats.percent', {
              value: royalty,
            })}
          </Typography>
        </div>
        {/* <div className={cx(classes.spaceBetween, classes.mb2)}>
          <Typography variant="body2">
            {t('nft.collection-info.instances')}
          </Typography>
          <Typography variant="body2">{instances}</Typography>
        </div> */}

        {status === NftStatus.LISTED && (
          <StopSaleButton
            className={cx(classes.mb4, classes.button)}
            onClick={stopSale}
          />
        )}

        {status !== NftStatus.NotListed && (
          <MiragePlazaLink
            className={status !== NftStatus.LISTED ? classes.button : undefined}
            link={plazaLink}
          />
        )}

        {status === NftStatus.NotListed && (
          <LaunchButton
            className={classes.button}
            onClick={launchOnMarketplace}
          />
        )}
      </NavLink>
    </div>
  );
};
