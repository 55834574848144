export const getPagesArray = (
  totalPages: number,
  page: number,
  arraySize: number,
): number[] => {
  const arrayMiddle = Math.ceil(arraySize / 2);

  let result: number[] = [];
  for (let i = 0; i < totalPages; i++) {
    result.push(i + 1);
  }

  if (totalPages <= arraySize) {
    return result;
  }

  const pageIndex = page - 1;

  if (page < arrayMiddle) {
    return result.slice(0, arraySize);
  }

  if (page > arrayMiddle) {
    if (pageIndex + 2 < totalPages) {
      result.splice(0, pageIndex - 2);
    } else if (pageIndex + 1 < totalPages) {
      result.splice(0, pageIndex - 3);
    } else {
      result.splice(0, pageIndex - 4);
    }
  }

  if (page + 2 < totalPages) {
    const newPageIndex = result.indexOf(page);
    result.splice(newPageIndex + 3);
  }

  return result;
};
