import { ReactNode, useCallback, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as ExpandIcon } from 'uiKit/icons/chevron-down.svg';
import { useTableStyles } from './useTableStyles';
import { Box, Collapse, IconButton, TableCell } from '@mui/material';

interface ITableBodyRowProps {
  children: ReactNode;
  expandable?: boolean;
  expandSlot?: ReactNode;
}

export const TableBodyRow = ({
  children,
  expandable = false,
  expandSlot,
  ...props
}: ITableBodyRowProps): JSX.Element => {
  const { classes, cx } = useTableStyles();

  const [isOpened, setIsOpened] = useState(false);

  const handleOpen = useCallback(() => {
    setIsOpened(opened => !opened);
  }, [setIsOpened]);

  const icon = isOpened ? (
    <ExpandIcon className={classes.invertedIcon} />
  ) : (
    <ExpandIcon />
  );

  return (
    <>
      <TableRow
        className={cx(classes.row, isOpened && classes.expandedRow)}
        {...props}
      >
        {children}

        {expandable && (
          <TableCell style={{ width: 50 }} className={classes.expandCell}>
            {!!expandSlot && (
              <IconButton className={classes.iconWrap} onClick={handleOpen}>
                {icon}
              </IconButton>
            )}
          </TableCell>
        )}
      </TableRow>

      {isOpened && (
        <TableRow className={cx(classes.row, classes.expandedSlot)} {...props}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={isOpened} timeout="auto" unmountOnExit>
              <Box>{expandSlot}</Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
