import { useCallback, useState } from 'react';
import { Box, ButtonBase, Typography } from '@mui/material';
import { t } from 'modules/utils/intl';
import { ReactComponent as ChevronDownIcon } from 'uiKit/icons/chevron-down.svg';
import { useDescriptionStyles } from './useDescriptionStyles';

interface IDescriptionProps {
  text: string;
}

export const Description = ({ text }: IDescriptionProps): JSX.Element => {
  const { classes, cx } = useDescriptionStyles();
  const [fullText, setFullText] = useState(false);

  const handleShowMore = useCallback(() => {
    setFullText(opened => !opened);
  }, [setFullText]);

  const isLongText = text.length > 200;

  return (
    <div className={classes.root}>
      <Typography className={classes.text}>
        {fullText || !isLongText ? text : `${text.substring(0, 100)}...`}
      </Typography>
      {isLongText && (
        <ButtonBase onClick={handleShowMore} className={classes.button}>
          <Box display="flex" alignItems="center">
            <Typography mr={2}>
              {fullText ? t('utils.show-less') : t('utils.show-more')}
            </Typography>
            <ChevronDownIcon
              className={cx(classes.icon, fullText && classes.transformed)}
            />
          </Box>
        </ButtonBase>
      )}
    </div>
  );
};
