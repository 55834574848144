import { makeStyles } from 'tss-react/mui';

export const useTokenCardStyles = makeStyles()(theme => ({
  tokenCardRoot: {
    borderRadius: 16,
    border: `1px solid ${theme.palette.grey[500]}`,
    padding: theme.spacing(5),
    marginBottom: 0,
    marginTop: 0,
    display: 'flex',
    boxShadow: 'none',
    alignItems: 'center',
    height: 52,
    flex: 1,
    width: '100%',
  },
  active: {
    border: `1px solid ${theme.palette.violet.main}`,
    backgroundColor: theme.palette.violet[10],
  },
  radioWrapper: {
    marginRight: theme.spacing(2),
  },
}));
