import { ProviderManager } from '@ankr.com/provider';
import BigNumber from 'bignumber.js';
import {
  configFromEnv,
  METAMASK_ID,
  queryFnNotifyWrapper,
  web3Api,
  web3ModalTheme,
} from 'modules/api';

interface IGetEthTxFeeArgs {
  byteCode: string;
}

interface IGetEthTxFeeData {
  gasFeeValue: number;
  ethFeeValue: BigNumber;
}

const {
  web3Config: { ethChainId },
} = configFromEnv();

// TODO Refactor to singleton
export const providerManager = new ProviderManager(web3ModalTheme);

export const { useLazyGetEthTxFeeQuery } = web3Api.injectEndpoints({
  endpoints: build => ({
    getEthTxFee: build.query<IGetEthTxFeeData, IGetEthTxFeeArgs>({
      queryFn: queryFnNotifyWrapper<IGetEthTxFeeArgs, never, IGetEthTxFeeData>(
        async ({ byteCode }) => {
          const provider = await providerManager.getETHWriteProvider(
            METAMASK_ID,
          );

          const web3 = provider.getWeb3();

          if (provider.currentChain !== ethChainId) {
            await provider.switchNetwork(ethChainId);
          }

          const fee = await web3.eth.estimateGas({
            from: provider.currentAccount,
            data: byteCode,
          });

          const ethFee = await provider.getContractMethodFee(fee * 1.5);

          return {
            data: {
              gasFeeValue: fee,
              ethFeeValue: ethFee,
            },
          };
        },
      ),
    }),
  }),
});
