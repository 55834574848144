import { makeStyles } from 'tss-react/mui';

export const useDescriptionStyles = makeStyles()(theme => ({
  root: {
    maxWidth: 544,
    marginBottom: theme.spacing(9),
  },
  text: {
    marginBottom: theme.spacing(3),
  },
  button: {
    color: theme.palette.violet.main,
  },
  icon: {
    width: 20,
    height: 20,
    transition: '0.3s',
  },
  transformed: {
    transform: 'rotate(180deg)',
  },
}));
