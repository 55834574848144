import { uid } from 'react-uid';
import { NetworkOrTokenIcon } from '../NetworkOrTokenIcon/NetworkOrTokenIcon';
import { Network } from '../../types';
import { useNetworksListStyles } from './useNetworksListStyles';

interface INetworksListProps {
  networks: Network[];
  isWidthAdjustment?: boolean;
  className?: string;
}

export const NetworksList = ({
  networks,
  isWidthAdjustment = false,
  className,
}: INetworksListProps): JSX.Element => {
  const { classes, cx } = useNetworksListStyles();

  return (
    <div className={cx(classes.root, className)}>
      {networks.map(network => (
        <NetworkOrTokenIcon
          name={network}
          key={uid(network)}
          className={cx(
            isWidthAdjustment
              ? classes.adjustTokenIcon
              : classes.commonTokenIcon,
          )}
        />
      ))}
    </div>
  );
};
