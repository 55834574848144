import { NftStatus } from 'modules/nft-manager/types';
import { ReactComponent as SuccessIcon } from 'uiKit/icons/status-success.svg';
import { ReactComponent as CanceledIcon } from 'uiKit/icons/status-canceled.svg';
import { useStatusIconStyles } from './useStatusIconStyles';
import { Box, Typography } from '@mui/material';
import { t } from 'modules/utils/intl';

interface IStatusIconProps {
  status: NftStatus;
  withText?: boolean;
  className?: string;
}

export const StatusIcon = ({
  status,
  withText = false,
  className,
}: IStatusIconProps): JSX.Element | null => {
  const { classes, cx } = useStatusIconStyles();

  switch (status) {
    case NftStatus.LISTED:
      return (
        <Box display="flex">
          <SuccessIcon
            className={cx(className, classes.green, withText && classes.mr2)}
          />
          {withText && (
            <Typography variant="captionAll" color="textSecondary">
              {t('nft.listed')}
            </Typography>
          )}
        </Box>
      );
    case NftStatus.NotListed:
      return (
        <Box display="flex">
          <CanceledIcon className={cx(className, withText && classes.mr2)} />
          {withText && (
            <Typography variant="captionAll" color="textSecondary">
              {t('nft.not-listed')}
            </Typography>
          )}
        </Box>
      );
    case NftStatus.SOLD:
      return (
        <Box display="flex">
          <SuccessIcon
            className={cx(className, classes.grey, withText && classes.mr2)}
          />
          {withText && (
            <Typography variant="captionAll" color="textSecondary">
              {t('nft.sold')}
            </Typography>
          )}
        </Box>
      );
    default:
      return null;
  }
};
