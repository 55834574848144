import {
  COMMON_ERROR,
  configFromEnv,
  GET_PROJECT_INFO_INVITE_URL,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
} from '../../api';
import { oryLoginAsync } from 'modules/auth';
import { RESPONSE_CODES } from '../../common/types';
import { ProjectCacheTags } from '../const';

const {
  gatewayConfig: { gamingUrl },
} = configFromEnv();

interface IGetProjectInfoForInviteArgs {
  id?: string;
}

interface IResponse {
  id: string;
  projectName: string;
  organizationId: string;
}

export const { useGetProjectInfoForInviteQuery } = web3Api.injectEndpoints({
  endpoints: build => ({
    getProjectInfoForInvite: build.query<
      IResponse,
      IGetProjectInfoForInviteArgs
    >({
      queryFn: queryFnNotifyWrapper<
        IGetProjectInfoForInviteArgs,
        never,
        IResponse
      >(async ({ id }: IGetProjectInfoForInviteArgs) => {
        const request = await fetch(
          gamingUrl + GET_PROJECT_INFO_INVITE_URL + id,
          {
            method: 'GET',
            credentials: 'include',
          },
        );

        if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
          await oryLoginAsync();
          throw UNAUTHORIZED_ERROR;
        }
        if (!request.ok) throw COMMON_ERROR;

        return { data: await request.json() };
      }),
      providesTags: (res, err, args) => [
        ProjectCacheTags.projectData + args.id,
      ],
    }),
  }),
});
