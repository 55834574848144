import { makeStyles } from 'tss-react/mui';

export const useBadgeStyles = makeStyles()(theme => ({
  root: {
    borderRadius: 8,
    color: theme.palette.common.white,
    height: 20,
    width: 'fit-content',

    '& > span': {
      padding: theme.spacing(0, 2, 0),
    },
  },
  grey: {
    backgroundColor: theme.palette.grey[900],
  },
  black: {
    backgroundColor: theme.palette.common.black,
  },
  violent: {
    backgroundColor: theme.palette.violet[80],
  },
  orange: {
    backgroundColor: theme.palette.orange.main,
  },
}));
