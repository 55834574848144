import { ProviderManager } from '@ankr.com/provider';
import {
  configFromEnv,
  METAMASK_ID,
  queryFnNotifyWrapper,
  web3Api,
  web3ModalTheme,
} from '../../api';
import ABI_ERC_721 from '../api/ERC-721.json';
import ABI_ERC_1155 from '../api/ERC-1155.json';
import { Address, NftMintNetwork } from 'modules/common/types';
import { TokenStandart } from '../types';
import { t } from 'modules/utils/intl';

const {
  web3Config: { polygonChainId, bscChainId, ethSepoliaChainId },
} = configFromEnv();

interface IMintNftResponse {
  txHash: string;
}

interface IMintNftArgs {
  uri: string;
  signature: string;
  tokenId: number;
  instances: number;
  address: Address;
  network: NftMintNetwork;
  tokenStandart: TokenStandart;
}

// TODO Refactor to singleton
export const providerManager = new ProviderManager(web3ModalTheme);

export const { useMintNftMutation } = web3Api.injectEndpoints({
  endpoints: build => ({
    mintNft: build.mutation<IMintNftResponse, IMintNftArgs>({
      queryFn: queryFnNotifyWrapper<IMintNftArgs, never, IMintNftResponse>(
        async ({
          uri,
          signature,
          address,
          tokenId,
          instances,
          network,
          tokenStandart,
        }) => {
          const provider = await providerManager.getETHWriteProvider(
            METAMASK_ID,
          );

          if (
            network === NftMintNetwork.SepETH &&
            provider.currentChain !== ethSepoliaChainId
          ) {
            await provider.switchNetwork(ethSepoliaChainId);
          }
          if (
            network === NftMintNetwork.BSC &&
            provider.currentChain !== bscChainId
          ) {
            await provider.switchNetwork(bscChainId);
          }
          if (
            network === NftMintNetwork.Polygon &&
            provider.currentChain !== polygonChainId
          ) {
            await provider.switchNetwork(polygonChainId);
          }

          if (tokenStandart === TokenStandart.ERC721) {
            const contract = provider.createContract(ABI_ERC_721, address);

            const mintData = contract.methods
              .safeMint(uri, signature)
              .encodeABI();

            const txData = await provider.sendTransactionAsync(
              provider.currentAccount,
              address,
              { data: mintData, estimate: true },
            );

            return {
              data: {
                txHash: txData.transactionHash,
              },
            };
          } else {
            const contract = provider.createContract(ABI_ERC_1155, address);

            const mintData = contract.methods
              .safeMint(tokenId, instances, uri, signature)
              .encodeABI();

            const txData = await provider.sendTransactionAsync(
              provider.currentAccount,
              address,
              { data: mintData, estimate: true },
            );

            return {
              data: {
                txHash: txData.transactionHash,
              },
            };
          }
        },
        error => {
          return t('errors.web3-error');
        },
      ),
    }),
  }),
});
