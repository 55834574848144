import { Network, NftMintNetwork, Token } from '../types';

export const getNativeToken = (network?: Network | NftMintNetwork): Token => {
  switch (network) {
    case Network.Polygon:
    case NftMintNetwork.Polygon:
      return Token.MATIC;
    case NftMintNetwork.ARB:
      return Token.ARB;
    case Network.Eth:
    case NftMintNetwork.Goerli:
    case NftMintNetwork.SepETH:
    default:
      return Token.ETH;
  }
};
