import { useParams } from 'react-router';
import { ACTION_CACHE_SEC } from '../../../common/const';
import { useGetProjectInfoForInviteQuery } from 'modules/projects/actions/useGetProjectInfoForInviteQuery';

interface IUseProjectInfoForInviteData {
  id?: string;
  name: string;
  organizationId: string;
  isLoading: boolean;
}

export const useProjectInfoForInviteData = (): IUseProjectInfoForInviteData => {
  const { id } = useParams();

  const { data, isFetching } = useGetProjectInfoForInviteQuery(
    { id },
    {
      skip: !id,
      refetchOnMountOrArgChange: ACTION_CACHE_SEC,
    },
  );

  return {
    id: data?.id,
    name: data?.projectName ?? '',
    organizationId: data?.organizationId ?? '',
    isLoading: isFetching,
  };
};
