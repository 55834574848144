import { makeStyles } from 'tss-react/mui';

export const useEditProjectStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  mb1: {
    marginBottom: theme.spacing(1),
  },
  mb6: {
    marginBottom: theme.spacing(6),
  },
  textfield: {
    '& .MuiInputBase-root': {
      height: 'unset',

      '& .MuiFilledInput-input': {
        padding: theme.spacing(3, 2, 3),
      },
    },
    '& .MuiFormHelperText-root': {
      textAlign: 'right',
      position: 'absolute',
      right: theme.spacing(3),
      bottom: theme.spacing(3),
    },
  },
}));
