import { Button, Typography } from '@mui/material';
import { RoutesConfig } from 'modules/nft-manager/Routes';
import { t } from 'modules/utils/intl';
import { NavLink } from 'react-router-dom';
import { useEmptyStyles } from './useEmptyStyles';

export const Empty = (): JSX.Element => {
  const { classes } = useEmptyStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h3">
        {t('nft.collections-list.empty-title')}
      </Typography>

      <NavLink to={RoutesConfig.createCollection.generatePath()}>
        <Button className={classes.button} variant="outlined">
          {t('nft.collections-list.create-collection')}
        </Button>
      </NavLink>
    </div>
  );
};
