import {
  COMMON_ERROR,
  configFromEnv,
  GET_POST_COLLECTION,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
} from '../../api';
import { oryLoginAsync } from 'modules/auth';
import { RESPONSE_CODES } from '../../common/types';
import { NftCacheTags } from '../const';
import { ICollectionTokensItem } from '../types';

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

interface IGetCollectionArgs {
  id?: string;
}

export const { useGetCollectionQuery } = web3Api.injectEndpoints({
  endpoints: build => ({
    getCollection: build.query<ICollectionTokensItem, IGetCollectionArgs>({
      queryFn: queryFnNotifyWrapper<
        IGetCollectionArgs,
        never,
        ICollectionTokensItem
      >(async ({ id }: IGetCollectionArgs) => {
        const request = await fetch(
          nftManagerUrl + GET_POST_COLLECTION + id + '/tokens',
          {
            method: 'GET',
            credentials: 'include',
          },
        );

        if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
          await oryLoginAsync();
          throw UNAUTHORIZED_ERROR;
        }
        if (!request.ok) throw COMMON_ERROR;

        return { data: await request.json() };
      }),
      providesTags: (res, err, args) => [NftCacheTags.collectionData + args.id],
    }),
  }),
});
