import { MenuItem, Typography } from '@mui/material';
import { ReactText } from 'modules/common/types';
import { ReactComponent as DoneIcon } from 'uiKit/icons/done-small.svg';
import { useSelectItemStyles } from './useSelectItemStyles';

interface ISelectItemProps {
  itemValue: ReactText;
  value: ReactText;
  itemText: string;
  selectValue: ReactText;
  children: ReactText;
  isRightIcon?: boolean;
}

export const SelectItem = ({
  itemValue,
  itemText,
  selectValue,
  value,
  isRightIcon = true,
  ...restProps
}: ISelectItemProps): JSX.Element => {
  const { classes, cx } = useSelectItemStyles();

  const isActiveLeftIcon = !isRightIcon && selectValue === itemValue;

  return (
    <MenuItem
      className={cx(
        classes.root,
        isRightIcon ? classes.spaceBetween : classes.pl0,
      )}
      {...restProps}
    >
      {!isRightIcon && selectValue === itemValue && (
        <DoneIcon className={cx(classes.icon, classes.mr2)} />
      )}
      <Typography
        className={cx(
          !isRightIcon && classes.ml7,
          isActiveLeftIcon && classes.ml0,
        )}
      >
        {itemText}
      </Typography>

      {isRightIcon && selectValue === itemValue && (
        <DoneIcon className={classes.icon} />
      )}
    </MenuItem>
  );
};
