import { ReactComponent as SpinnerIcon } from '../../icons/spinnerIcon.svg';
import { useSpinnerStyles } from './useSpinnerStyles';

interface ISpinnerProps {
  className?: string;
}

export const Spinner = ({ className }: ISpinnerProps): JSX.Element => {
  const { classes, cx } = useSpinnerStyles();
  return <SpinnerIcon className={cx(classes.root, className)} />;
};
