import { NFT_TILE_MAX_WIDTH } from 'modules/nft-manager/const';
import { makeStyles } from 'tss-react/mui';

export const useNftListStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
  },
  wrap: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: NFT_TILE_MAX_WIDTH * 4 + 80,
  },
}));
