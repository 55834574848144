import { IProject } from 'modules/projects/types';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { SEARCH_DEBOUNCE_TIME } from '../../../common/const';
import { ReactText } from '../../../common/types';
import { useLazyGetProjectListQuery } from '../../actions/useLazyGetProjectListQuery';

interface IUseProjectInfoData {
  projects?: IProject[];
  totalProjectsCount: number;
  isLoading: boolean;
  page: number;
  totalPages: number;
  searchValue: ReactText;
  searchDelay: boolean;
  changePage: (value: number) => void;
  setSearch: (value: ReactText) => void;
}

export const useProjectListData = (): IUseProjectInfoData => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<ReactText>('');
  const [searchDelay, setSearchDelay] = useState(false);
  const [getProjectList, { data, isFetching }] = useLazyGetProjectListQuery();

  useEffect(() => {
    getProjectList({
      pageNumber: page,
    });
  }, [getProjectList, page]);

  const fetchProjectsWithSearchField = (value: ReactText) => {
    getProjectList({
      name: value.toString(),
    })
      .unwrap()
      .finally(() => setSearchDelay(false));
  };

  const debouncedFetchProjectList = useDebouncedCallback(
    fetchProjectsWithSearchField,
    SEARCH_DEBOUNCE_TIME,
  );

  const handleSearchChange = (value: ReactText) => {
    setSearch(value);
    setSearchDelay(true);
    debouncedFetchProjectList(value);
  };

  const changePage = (page: number) => {
    setPage(page);
  };

  return {
    projects: data?.items,
    isLoading: isFetching,
    totalProjectsCount: data?.totalCount ?? 0,
    page,
    totalPages: data?.totalPages ?? 1,
    searchValue: search,
    searchDelay,
    changePage,
    setSearch: handleSearchChange,
  };
};
