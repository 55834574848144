import { makeStyles } from 'tss-react/mui';

export const useProjectInfoStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 544,
  },
  mb6: {
    marginBottom: theme.spacing(6),
  },
  mb15: {
    marginBottom: theme.spacing(15),
  },
  button: {
    width: 128,
    marginBottom: theme.spacing(4),
    '& a': {
      color: theme.palette.text.primary,
    },
  },
}));
