import {
  configFromEnv,
  METAMASK_ID,
  queryFnNotifyWrapper,
  web3Api,
  web3ModalTheme,
} from 'modules/api';
import { ProviderManager } from '@ankr.com/provider';
import { t } from 'modules/utils/intl';

interface IPublishContractResponse {
  txHash: string;
  chainId: number;
}

interface IPublishContractArgs {
  byteCode: string;
  gas: number;
}

const {
  web3Config: { ethChainId },
} = configFromEnv();

// TODO Refactor to singleton
export const providerManager = new ProviderManager(web3ModalTheme);

export const { usePublishContractMutation } = web3Api.injectEndpoints({
  endpoints: build => ({
    publishContract: build.mutation<
      IPublishContractResponse | null,
      IPublishContractArgs
    >({
      queryFn: queryFnNotifyWrapper<
        IPublishContractArgs,
        never,
        IPublishContractResponse | null
      >(
        async (data: IPublishContractArgs) => {
          const provider = await providerManager.getETHWriteProvider(
            METAMASK_ID,
          );

          const web3 = provider.getWeb3();

          if (provider.currentChain !== ethChainId) {
            await provider.switchNetwork(ethChainId);
          }

          const tx = await web3.eth.sendTransaction({
            from: provider.currentAccount,
            data: data.byteCode,
            gas: data.gas,
          });

          return {
            data: {
              txHash: tx.transactionHash,
              chainId: provider.currentChain,
            },
          };
        },
        error => {
          return t('errors.web3-error');
        },
      ),
    }),
  }),
});
