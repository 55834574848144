import { makeStyles } from 'tss-react/mui';

export const useTooltipIconStyles = makeStyles()(theme => ({
  btn: {
    color: theme.palette.text.secondary,
  },

  icon: {
    display: 'block',

    '&:hover': {
      '--main': theme.palette.common.black,
      '--item': theme.palette.common.white,
    },

    '& circle, & path': {
      transition: 'fill .2s ease',
    },
  },
}));
