import { makeStyles } from 'tss-react/mui';

export const useLaunchDialogStyles = makeStyles()(theme => ({
  image: {
    height: 104,
    width: 104,
    borderRadius: 12,
    marginRight: theme.spacing(5),
  },
  icon: {
    height: 16,
    width: 16,
  },
  inputWrapper: {
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  button: {
    flex: 1,
  },
  tokenIcon: {
    height: 22,
    width: 22,
    marginRight: theme.spacing(2),
  },
}));
