import { makeStyles } from 'tss-react/mui';

export const useListStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(10),
  },
  wrap: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));
