import { Box, Checkbox, Paper, Typography } from '@mui/material';
import { usePlatformItemStyles } from './usePlatformItemStyles';
import {
  EPlatform,
  PLATFORM_ICON,
  PLATFORM_TITLE,
} from 'modules/projects/const';
import { cloneElement } from 'react';
import { t } from 'modules/utils/intl';

interface IPlatformItemProps {
  value: EPlatform;
  isActive: boolean;
  isError?: boolean;
  onChange: () => void;
}

export const PlatformItem = ({
  value,
  isActive,
  isError = false,
  onChange,
}: IPlatformItemProps): JSX.Element => {
  const { classes, cx } = usePlatformItemStyles();

  return (
    <Paper
      component="label"
      className={cx(
        classes.root,
        isActive && classes.active,
        isError && classes.error,
      )}
    >
      <Box display="flex" alignItems="center">
        <Checkbox
          checked={isActive}
          value={value}
          onChange={onChange}
          className={classes.radio}
        />
        <Typography>{t(PLATFORM_TITLE[value])}</Typography>
      </Box>
      {cloneElement(PLATFORM_ICON[value], {
        className: classes.icon,
      })}
    </Paper>
  );
};
