import { makeStyles } from 'tss-react/mui';

export const useNftInfoStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
  },
  main: {
    display: 'flex',
    gap: theme.spacing(5),
    marginBottom: theme.spacing(10),
  },
  mainSection: {
    flex: 1,

    '& + &': {
      marginLeft: theme.spacing(5),
    },
  },
}));
