import { ThemeColors } from 'web3modal';
// import { t } from '../utils/intl';

// TODO: fix t functions. it doesn't work for some reasons
export const UNAUTHORIZED_ERROR = new Error('Unauthorized request');
export const COMMON_ERROR = new Error('Something went wrong...');

export const METAMASK_ID = 'injected';

export const web3ModalTheme: ThemeColors = {
  background: 'rgb(255,255,255)',
  main: 'rgb(137, 137, 137)',
  secondary: 'rgb(137, 137, 137)',
  border: 'rgba(195,195,195,0.14)',
  hover: 'rgb(239, 239, 239)',
};
