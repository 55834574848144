import { Components, Palette } from '@mui/material';

export function getMuiLink(palette: Palette): Components['MuiLink'] {
  return {
    styleOverrides: {
      root: {
        color: palette.violet.main,
        textDecoration: 'none',
      },
    },
  };
}
