import { makeStyles } from 'tss-react/mui';

export const useProjectIdStyles = makeStyles()(theme => ({
  icon: {
    height: 20,
    width: 20,
  },
  doneIcon: {
    color: theme.palette.text.primary,
  },
}));
