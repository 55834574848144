import { Typography } from '@mui/material';
import MuiLink from '@mui/material/Link';
import { t } from 'modules/utils/intl';
import { ReactComponent as ShareIcon } from 'uiKit/icons/share.svg';
import { useMiragePlazaLinkStyles } from './useMiragePlazaLinkStyles';

interface IMiragePlazaLinkProps {
  link?: string;
  className?: string;
}

export const MiragePlazaLink = ({
  link,
  className,
}: IMiragePlazaLinkProps): JSX.Element => {
  const { classes } = useMiragePlazaLinkStyles();
  return (
    <MuiLink
      className={className}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Typography className={classes.root} component="span">
        {t('nft.view-on-mirage-plaza')}
        <ShareIcon className={classes.icon} />
      </Typography>
    </MuiLink>
  );
};
