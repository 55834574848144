import { makeStyles } from 'tss-react/mui';
import dots from './assets/dots.svg';

export const useUploadStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    height: '100%',
    width: '100%',

    // border: `1px dashed ${theme.palette.common.black}`,
    // borderRadius: 16,

    backgroundImage: `url(${dots})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
  },
  wrapper: {
    height: '100%',
    width: '100%',
    marginBottom: theme.spacing(4),

    '& > label': {
      height: '100%',
      width: '100%',
    },
  },
  icon: {
    marginBottom: theme.spacing(3),
  },
  secondColor: {
    color: theme.palette.text.secondary,
  },
  tableWrapper: {
    marginBottom: theme.spacing(10),
  },
  addMoreButton: {
    marginRight: theme.spacing(5),
  },
  plusIcon: {
    marginRight: theme.spacing(2.5),
  },
}));
