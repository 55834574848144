import { uid } from 'react-uid';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { t } from 'modules/utils/intl';
import { TableBodyRow, TableHeadCell } from 'uiKit/components/Table';
import { Pagination } from 'modules/common/components/Pagination/Pagination';
import { RoutesConfig } from '../../../../Routes';
import { ContractId } from '../ContractId';
import { NetworksList } from 'modules/common/components/NetworksList';
import { UpdatesItem } from '../UpdatesItem';
import { usePublishedContractsData } from './usePublishedContractsData';
import { LoaderCentered } from 'modules/common/components/Loader';
import { Empty } from '../Empty';

export const List = (): JSX.Element => {
  const { data, totalPages, totalContractsCount, page, isLoading, changePage } =
    usePublishedContractsData();

  return (
    <>
      {isLoading && <LoaderCentered />}

      {!isLoading && (!data || data.length === 0) && <Empty />}

      {!isLoading && !!data?.length && data.length > 0 && (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={4}
          >
            <Typography variant="h3">
              {t('smart-contracts.manager.published-contracts', {
                value: totalContractsCount,
              })}
            </Typography>
            <NavLink to={RoutesConfig.upload.generatePath()}>
              <Button variant="outlined">
                {t('smart-contracts.manager.publish')}
              </Button>
            </NavLink>
          </Box>

          <Box mb={4}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadCell title={t('smart-contracts.manager.name')} />
                    <TableHeadCell title={t('smart-contracts.manager.id')} />
                    <TableHeadCell
                      title={t('smart-contracts.manager.updates')}
                    />
                    <TableHeadCell
                      title={t('smart-contracts.manager.network')}
                    />
                    {/* <TableHeadCell /> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map(row => (
                    <TableBodyRow key={uid(row)}>
                      <TableCell>
                        <Typography>{row.name}</Typography>
                      </TableCell>
                      <TableCell>
                        <ContractId id={row.id} />
                      </TableCell>
                      <TableCell>
                        <UpdatesItem
                          lastUpdate={row.lastModified}
                          versionsNumber={row.contractVersionCount}
                        />
                      </TableCell>
                      <TableCell style={{ width: 150 }}>
                        <NetworksList
                          isWidthAdjustment
                          networks={row.deployedTo}
                        />
                      </TableCell>
                      {/* <TableCell align="right" style={{ width: 100 }}>
                  <Typography>action</Typography>
                </TableCell> */}
                    </TableBodyRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {totalPages > 1 && (
            <Pagination
              page={page}
              totalPages={totalPages}
              changePage={changePage}
            />
          )}
        </>
      )}
    </>
  );
};
