import { ProviderManager } from '@ankr.com/provider';
import {
  COMMON_ERROR,
  configFromEnv,
  GET_NFT_MINT_DATA,
  METAMASK_ID,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
  web3ModalTheme,
} from '../../api';
import { NftMintNetwork, RESPONSE_CODES } from '../../common/types';
import { oryLoginAsync } from 'modules/auth';

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

interface IReponse {
  nftContractName: string;
  nftContractDescription: string;
  uri: string;
  chainCode: string;
  contractAddress: string;
  signature: string;
}

interface IGetMintDataArgs {
  NftContractId: string;
  NftTokenId: string;
  ChainCode: NftMintNetwork;
  Address?: string;
}

// TODO Refactor to singleton
export const providerManager = new ProviderManager(web3ModalTheme);

export const { useLazyGetNftContractMintDataQuery } = web3Api.injectEndpoints({
  endpoints: build => ({
    getNftContractMintData: build.query<IReponse, IGetMintDataArgs>({
      queryFn: queryFnNotifyWrapper<IGetMintDataArgs, never, IReponse>(
        async ({ NftContractId, NftTokenId, ChainCode }) => {
          const provider = await providerManager.getETHWriteProvider(
            METAMASK_ID,
          );

          const address = provider.currentAccount;

          const params: IGetMintDataArgs = {
            NftContractId,
            NftTokenId,
            ChainCode,
            Address: address,
          };
          const request = await fetch(
            nftManagerUrl +
              GET_NFT_MINT_DATA +
              '?' +
              new URLSearchParams({
                ...params,
              }),
            {
              method: 'GET',
              credentials: 'include',
            },
          );

          if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
            await oryLoginAsync();
            throw UNAUTHORIZED_ERROR;
          }
          if (!request.ok) throw COMMON_ERROR;

          return { data: await request.json() };
        },
      ),
    }),
  }),
});
