import { ReactComponent as AndroidIcon } from 'uiKit/icons/android.svg';
import { ReactComponent as AppleIcon } from 'uiKit/icons/apple.svg';
import { ReactComponent as PCIcon } from 'uiKit/icons/pc.svg';
import { ReactComponent as IOSIcon } from 'uiKit/icons/ios.svg';
import { ReactComponent as WebIcon } from 'uiKit/icons/web.svg';
import { ReactComponent as LinuxIcon } from 'uiKit/icons/linux.svg';
import { getUniqueId } from '../utils/getUniqueId';

export const ProjectCacheTags = {
  commonList: getUniqueId(),
  projectData: getUniqueId(),
};

export enum EPlatform {
  PC,
  MAC,
  LINUX,
  IOS,
  ANDROID,
  WEB,
}

export const PLATFORM_ICON = {
  [EPlatform.PC]: <PCIcon />,
  [EPlatform.MAC]: <AppleIcon />,
  [EPlatform.LINUX]: <LinuxIcon />,
  [EPlatform.IOS]: <IOSIcon />,
  [EPlatform.ANDROID]: <AndroidIcon />,
  [EPlatform.WEB]: <WebIcon />,
};

export const PLATFORM_TITLE = {
  [EPlatform.PC]: 'projects.projectList.pc',
  [EPlatform.MAC]: 'projects.projectList.mac',
  [EPlatform.LINUX]: 'projects.projectList.linux',
  [EPlatform.IOS]: 'projects.projectList.ios',
  [EPlatform.ANDROID]: 'projects.projectList.android',
  [EPlatform.WEB]: 'projects.projectList.web',
};
