import { Network } from 'modules/common/types';

export enum FILE_STATUS {
  UPLOADING,
  UPLOADED,
  UPLOAD_ERROR,
  CANCELLED,

  COMPILE_WAIT,
  COMPILING,
  COMPILE_ERROR,
  COMPILED,
}

export interface IContractFileFrontItem {
  id?: string;
  name: string;
  status: FILE_STATUS;
  size: number;
  // sourceLink?: string;
}

export interface ICompiledFilesData {
  name: string;
  lastModified: string;
  files: IContractFileItem[];
  errors: ICompileErrorItem[];
}

export interface ICompileErrorItem {
  errorCode: string;
  errorMessage: string;
  identifier: string | null;
  severity: number;
}

export interface IContractFileItem {
  id: string;
  name: string;
  created: string;
  lastModified: string;
  description: string;
  sizeBytes: number;
}

export interface ICVPublishData {
  id: string;
  name: string;
  created: string;
  lastModified: string;
  network: Network;
}

export enum STEP {
  UPLOAD = 1,
  // MERGE = 2,
  COMPILE = 2,
  PUBLISH = 3,
  MERGE = 1000,
}

export enum CV_STATE {
  Draft = 0,
  Compiling = 1, // not used
  Compiled = 2,
  Publishing = 3,
  Published = 4,
  Deleted = 5,
}
