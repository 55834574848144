import { Box, Paper, Radio, Typography } from '@mui/material';
import { useInviteUserStyles } from './useInviteUserStyles';

interface IRoleCardArgs {
  roleName: string;
  roleDescription: string;
  value: string|number;
  isActive: boolean;
  isError: boolean;
  className?: string;
}

export const RoleCard = ({
  roleName,
  roleDescription,
  value,
  isActive,
  isError,
  className,
}: IRoleCardArgs): JSX.Element => {
  const { classes, cx } = useInviteUserStyles();

  return (
    <Paper
      component="label"
      className={cx(
        classes.roleCardRoot,
        className,
        isActive && classes.active,
        isError && classes.error,
      )}
    >
      <Radio className={classes.radioWrapper} value={value} />
      <Box display="flex" flexDirection="column" mt={1}>
        <Typography className={classes.mb2}>{roleName}</Typography>
        <Typography className={classes.secondaryColor} variant="body2">
          {roleDescription}
        </Typography>
      </Box>
    </Paper>
  );
};
