import { toast, TypeOptions, ToastOptions } from 'react-toastify';
import { mainPalette } from '../../../../uiKit/mainPalette';
import { mainTheme } from '../../../../uiKit/mainTheme';
import { Milliseconds, ReactText } from '../../types';
import { CloseButton } from './CloseButton';
import { SuccessNotification } from './SuccessNotification';

interface INotifyArgs {
  key?: ReactText;
  message: string;
  type: TypeOptions;
  autoClose?: Milliseconds | false;
  closeOnClick?: boolean;
}

export const notify = ({
  message,
  type,
  closeOnClick = true,
  autoClose = 5_000,
}: INotifyArgs): void => {
  const options: ToastOptions = {
    type,
    autoClose,
    closeOnClick,
    closeButton: false,
    position: 'top-center',
    style: {
      borderRadius: 12,
      color: mainPalette.background.paper,
      width: 456,
    },
  };
  if (type === 'error') {
    toast.error(message, {
      ...options,
      style: { ...options.style, backgroundColor: mainPalette.error.main },
    });
  } else if (type === 'success') {
    toast.success(message, {
      ...options,
      style: { ...options.style, backgroundColor: mainPalette.success.main },
    });
  } else if (type === 'info') {
    toast(<SuccessNotification message={message} />, {
      type,
      autoClose,
      closeOnClick,
      closeButton: CloseButton,
      position: 'bottom-right',
      style: {
        ...mainTheme.typography.caption,
        width: 248,
        minHeight: 48,
        borderRadius: 16,
        color: mainPalette.text.primary,
        padding: mainTheme.spacing(0.5),
        boxShadow:
          '0px 0.5px 4px rgba(0, 0, 0, 0.12), 2px 2px 10px rgba(0, 0, 0, 0.12)',
        backgroundColor: mainPalette.background.paper,
      },
    });
  } else {
    toast(message, {
      ...options,
      style: { ...options.style, backgroundColor: mainPalette.grey[400] },
    });
  }
};
