import {
  COMMON_ERROR,
  configFromEnv,
  CONTRACT,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
} from 'modules/api';
import { oryLoginAsync } from 'modules/auth';
import { PAGE_SIZE } from 'modules/common/const';
import { Network, RESPONSE_CODES } from 'modules/common/types';
import { ContractsManagerCacheTags } from '../../../const';

export interface IPublishedContractItem {
  id: string;
  name: string;
  contractVersionCount: number;
  deployedTo: Network[];
  lastModified: string;
}

interface IReponse {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  items: IPublishedContractItem[];
  pageNumber: number;
  totalCount: number;
  totalPages: number;
}

interface IGetPublishedContractsargs {
  pageNumber?: number;
  pageSize?: number;
}

interface IGetPublishedContractsParams {
  PageNumber?: string;
  PageSize?: string;
}

const {
  gatewayConfig: { gamingUrl },
} = configFromEnv();

export const { useLazyGetPublishedContractsQuery } = web3Api.injectEndpoints({
  endpoints: build => ({
    getPublishedContracts: build.query<IReponse, IGetPublishedContractsargs>({
      queryFn: queryFnNotifyWrapper<
        IGetPublishedContractsargs,
        never,
        IReponse
      >(async ({ pageNumber = '1', pageSize = PAGE_SIZE }) => {
        const params: IGetPublishedContractsParams = {};
        if (pageNumber) {
          params.PageNumber = pageNumber.toString();
        }
        if (pageSize) {
          params.PageSize = pageSize.toString();
        }

        const request = await fetch(
          gamingUrl +
            CONTRACT +
            '?' +
            new URLSearchParams({
              ...params,
            }),
          {
            method: 'GET',
            credentials: 'include',
          },
        );

        if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
          await oryLoginAsync();
          throw UNAUTHORIZED_ERROR;
        }
        if (!request.ok) throw COMMON_ERROR;

        return { data: await request.json() };
      }),
      providesTags: [ContractsManagerCacheTags.commonList],
    }),
  }),
});
