import {
  FormControl,
  TextField,
  Input,
  InputLabel,
  Typography,
  RadioGroup,
} from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import { Controller, useForm } from 'react-hook-form';
import { t, tHTML } from 'modules/utils/intl';
import { ReactComponent as UploadIcon } from 'modules/common/assets/upload.svg';
import { useCreateCollectionForm } from './useCreateCollectionForm';
import { useCreateCollectionStyles } from './useCreateCollectionStyles';
import { Button } from 'uiKit/components/Button';
import { INewCollectionData } from 'modules/nft-manager/types';
import { uid } from 'react-uid';
import { ProjectItem } from './components/ProjectItem';
import { LoaderCentered } from 'modules/common/components/Loader';
import { IMAGE_MAX_SIZE_MB } from 'modules/common/const';

const FILE_TYPES = ['JPG', 'JPEG', 'PNG'];

export const CreateCollection = (): JSX.Element => {
  const { classes, cx } = useCreateCollectionStyles();
  const { projects, isDataLoading, isLoading, onSubmit } =
    useCreateCollectionForm();

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<INewCollectionData>({
    defaultValues: {
      name: '',
      description: '',
      url: '',
      logo: undefined,
      featured: undefined,
      banner: undefined,
      projectId: undefined,
    },
  });

  const onPrepareSubmit = (data: INewCollectionData): void => {
    if (isValid) {
      onSubmit(data);
    }
  };

  if (isDataLoading) return <LoaderCentered />;

  return (
    <form
      className={classes.root}
      onSubmit={handleSubmit(onPrepareSubmit)}
      noValidate
    >
      <Typography variant="h2" className={classes.header}>
        {t('nft.create-collection.title')}
      </Typography>

      <Typography variant="h3" className={classes.mb6}>
        {t('nft.create-collection.1-header')}
      </Typography>

      <Controller
        name="name"
        control={control}
        rules={{
          required: t('validation.required'),
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl>
              <div className={classes.mb8}>
                <InputLabel className={classes.requiredStar}>
                  {tHTML('nft.create-collection.name')}
                </InputLabel>
                <Input
                  {...field}
                  fullWidth
                  required
                  error={!!fieldState.error}
                  inputProps={{ maxLength: 50 }}
                  onChange={field.onChange}
                />
              </div>
            </FormControl>
          );
        }}
      />

      <Controller
        name="description"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <TextField
              {...field}
              fullWidth
              multiline
              rows={8}
              helperText={t('nft.create-collection.characters-number', {
                value: field.value?.length ?? 0,
              })}
              label={t('nft.create-collection.description')}
              error={!!fieldState.error}
              inputProps={{ maxLength: 1_000 }}
              className={cx(classes.mb12, classes.textfield)}
              onChange={field.onChange}
            />
          );
        }}
      />

      <Typography
        variant="h3"
        className={cx(classes.mb05, classes.requiredStar)}
      >
        {tHTML('nft.create-collection.2-header')}
      </Typography>
      <Typography className={classes.mb2}>
        {t('nft.create-collection.logo-size')}
      </Typography>
      <Typography variant="body1" className={classes.mb5} color="textSecondary">
        {t('nft.create-collection.2-description')}
      </Typography>
      <Controller
        name="logo"
        control={control}
        rules={{
          required: t('validation.required'),
        }}
        render={({ field, fieldState }) => {
          const errorText = fieldState.error?.message;
          if (!!field.value) {
            return (
              <img
                alt=""
                src={URL.createObjectURL(field.value)}
                className={cx(classes.logoWrapper, classes.mb16)}
              />
            );
          }

          return (
            <>
              <div
                className={cx(
                  classes.uploaderWrapper,
                  classes.logoWrapper,
                  classes.mb4,
                  !!errorText && classes.errorUploderWrapper,
                )}
              >
                <FileUploader
                  multiple={false}
                  maxSize={IMAGE_MAX_SIZE_MB}
                  name="logo"
                  types={FILE_TYPES}
                  handleChange={field.onChange}
                >
                  <UploadIcon />
                </FileUploader>
              </div>
              <Typography
                variant="captionAll"
                className={classes.mb16}
                color="textSecondary"
              >
                {t('nft.create-collection.not-uploaded')}
              </Typography>
            </>
          );
        }}
      />

      <Typography
        variant="h3"
        className={cx(classes.mb05, classes.requiredStar)}
      >
        {tHTML('nft.create-collection.3-header')}
      </Typography>
      <Typography className={classes.mb2}>
        {t('nft.create-collection.feature-size')}
      </Typography>
      <Typography variant="body1" className={classes.mb5} color="textSecondary">
        {t('nft.create-collection.3-description')}
      </Typography>
      <Controller
        name="featured"
        control={control}
        rules={{
          required: t('validation.required'),
        }}
        render={({ field, fieldState }) => {
          const errorText = fieldState.error?.message;
          if (!!field.value) {
            return (
              <img
                alt=""
                src={URL.createObjectURL(field.value)}
                className={cx(classes.featuredWrapper, classes.mb12)}
              />
            );
          }

          return (
            <>
              <div
                className={cx(
                  classes.uploaderWrapper,
                  classes.featuredWrapper,
                  classes.mb4,
                  !!errorText && classes.errorUploderWrapper,
                )}
              >
                <FileUploader
                  multiple={false}
                  maxSize={IMAGE_MAX_SIZE_MB}
                  name="logo"
                  types={FILE_TYPES}
                  handleChange={field.onChange}
                >
                  <UploadIcon />
                </FileUploader>
              </div>
              <Typography
                variant="captionAll"
                className={classes.mb16}
                color="textSecondary"
              >
                {t('nft.create-collection.not-uploaded')}
              </Typography>
            </>
          );
        }}
      />

      <Typography
        variant="h3"
        className={cx(classes.mb05, classes.requiredStar)}
      >
        {tHTML('nft.create-collection.4-header')}
      </Typography>
      <Typography className={classes.mb2}>
        {t('nft.create-collection.banner-size')}
      </Typography>
      <Typography variant="body1" className={classes.mb5} color="textSecondary">
        {tHTML('nft.create-collection.4-description')}
      </Typography>
      <Controller
        name="banner"
        control={control}
        rules={{
          required: t('validation.required'),
        }}
        render={({ field, fieldState }) => {
          const errorText = fieldState.error?.message;
          if (!!field.value) {
            return (
              <img
                alt=""
                src={URL.createObjectURL(field.value)}
                className={cx(classes.bannerWrapper, classes.mb16)}
              />
            );
          }

          return (
            <>
              <div
                className={cx(
                  classes.uploaderWrapper,
                  classes.bannerWrapper,
                  classes.mb4,
                  !!errorText && classes.errorUploderWrapper,
                )}
              >
                <FileUploader
                  multiple={false}
                  maxSize={IMAGE_MAX_SIZE_MB}
                  name="logo"
                  types={FILE_TYPES}
                  handleChange={field.onChange}
                >
                  <UploadIcon />
                </FileUploader>
              </div>
              <Typography
                variant="captionAll"
                className={classes.mb16}
                color="textSecondary"
              >
                {t('nft.create-collection.not-uploaded')}
              </Typography>
            </>
          );
        }}
      />

      {/* <Typography variant="h3" className={classes.mb2}>
        {t('nft.create-collection.5-header')}
      </Typography>
      <Typography variant="body1" className={classes.mb6} color="textSecondary">
        {tHTML('nft.create-collection.5-description', {
          href: 'test',
        })}
      </Typography> */}

      {/* <Controller
        name="url"
        control={control}
        rules={{
          required: t('validation.required'),
        }}
        render={({ field, fieldState }) => {
          const errorText = fieldState.error?.message;
          return (
            <FormControl>
              <div className={classes.mb17}>
                <InputLabel>
                  {t('nft.create-collection.5-placeholder')}
                </InputLabel>
                <Input
                  {...field}
                  fullWidth
                  required
                  error={!!fieldState.error}
                  inputProps={{ maxLength: 100 }}
                  onChange={field.onChange}
                />
                {!!errorText && (
                  <FormHelperText error>{errorText}</FormHelperText>
                )}
              </div>
            </FormControl>
          );
        }}
      /> */}

      <Typography variant="h3" mb={2} className={classes.requiredStar}>
        {tHTML('nft.create-collection.7-header')}
      </Typography>
      <Typography variant="body1" className={classes.mb6} color="textSecondary">
        {tHTML('nft.create-collection.7-description')}
      </Typography>
      <Controller
        name="projectId"
        control={control}
        rules={{
          required: t('validation.required'),
          validate: v => {
            return !!v || t('validation.required');
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <RadioGroup onChange={field.onChange}>
              {projects.map((project, index) => (
                <ProjectItem
                  key={uid(project)}
                  isActive={project.id === field.value}
                  project={project}
                  index={index}
                  isError={!!fieldState.error}
                />
              ))}
            </RadioGroup>
          );
        }}
      />
      <Button
        isLoading={isLoading}
        type="submit"
        variant="contained"
        size="large"
        className={classes.button}
      >
        {t('nft.create-collection.launch')}
      </Button>
    </form>
  );
};
