import { ProviderManager } from '@ankr.com/provider';
import {
  COMMON_ERROR,
  configFromEnv,
  GET_WALLET_VERIFICATION,
  METAMASK_ID,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
  web3ModalTheme,
} from '../../api';
import { oryLoginAsync } from 'modules/auth';
import { RESPONSE_CODES } from '../../common/types';

const {
  gatewayConfig: { mirageIdUrl },
} = configFromEnv();

interface IReponse {
  id: string;
}

const MOCK_MESSAGE = 'verify me';

// TODO Refactor to singleton
export const providerManager = new ProviderManager(web3ModalTheme);

export const { useLazyVerifyWalletQuery } = web3Api.injectEndpoints({
  endpoints: build => ({
    verifyWallet: build.query<IReponse, void>({
      queryFn: queryFnNotifyWrapper<void, never, IReponse>(async () => {
        const provider = await providerManager.getETHWriteProvider(METAMASK_ID);

        const address = provider.currentAccount;
        const web3 = provider.getWeb3();
        const signedMessage = await web3.eth.personal.sign(
          MOCK_MESSAGE,
          address,
          '',
        );

        const request = await fetch(
          mirageIdUrl +
            GET_WALLET_VERIFICATION +
            '?' +
            new URLSearchParams({
              Message: MOCK_MESSAGE,
              Address: address,
              Signature: signedMessage,
            }),
          {
            method: 'GET',
            credentials: 'include',
          },
        );

        if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
          await oryLoginAsync();
          throw UNAUTHORIZED_ERROR;
        }
        if (!request.ok) {
          throw COMMON_ERROR;
        }
        return { data: await request.json() };
      }),
    }),
  }),
});
