import { Components, inputLabelClasses, Palette } from '@mui/material';

export function getMuiInputLabel(
  palette: Palette,
): Components['MuiInputLabel'] {
  return {
    styleOverrides: {
      root: {
        color: palette.common.black,
        fontSize: 15,
        transform: 'translate(20px, 35px)',

        [`&.${inputLabelClasses.shrink}`]: {
          fontSize: 13,
          transform: 'translate(20px, 20px)',
          color: palette.grey[700],
        },

        [`&.${inputLabelClasses.disabled}`]: {
          color: palette.grey[700],
        },
      },
    },
  };
}
