import BigNumber from 'bignumber.js';
import {
  ACTION_CACHE_SEC,
  AVG_TX_TIME,
  NETWORK_NATIVE_TOKEN,
  ZERO,
} from 'modules/common/const';
import { Network } from 'modules/common/types';
import { useEffect } from 'react';
import { useLazyGetEthTxFeeQuery } from '../../actions/useLazyGetEthTxFeeQuery';
import { useGetEthPriceQuery } from '../../../../../common/actions/useGetEthPriceQuery';
import { IFeeItemProps } from './FeeItem';

interface IUseTransactionDataProps {
  networks: Network[];
  byteCode?: string;
}

interface IUseTransactionData {
  feeItems: IFeeItemProps[];
  totalUsdFee: BigNumber;
  gas: number;
  isLoading: boolean;
}

export const useTransactionData = ({
  networks,
  byteCode,
}: IUseTransactionDataProps): IUseTransactionData => {
  const [getEthTxFee, { data: ethTxFeeData, isLoading: isEthTxFeeLoading }] =
    useLazyGetEthTxFeeQuery();
  const { data: ethPrice } = useGetEthPriceQuery(undefined, {
    refetchOnMountOrArgChange: ACTION_CACHE_SEC,
  });

  useEffect(() => {
    if (byteCode) {
      getEthTxFee({
        byteCode,
      });
    }
  }, [byteCode, getEthTxFee]);

  const ethUsdPrice = ethPrice ?? ZERO;

  const result = networks.map(network => {
    let tokenFee = ZERO;
    if (network === Network.Eth && ethTxFeeData) {
      tokenFee = tokenFee.plus(ethTxFeeData.ethFeeValue);
    }

    return {
      network: network,
      token: NETWORK_NATIVE_TOKEN[network],
      tokenFee: tokenFee,
      usdFee: tokenFee.multipliedBy(ethUsdPrice),
      waitingMinutes: AVG_TX_TIME[network],
    };
  });

  return {
    feeItems: result,
    totalUsdFee: result.reduce((acc, item) => acc.plus(item.usdFee), ZERO),
    gas: ethTxFeeData?.gasFeeValue ?? 0,
    isLoading: isEthTxFeeLoading,
  };
};
