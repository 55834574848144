// import { push } from '@lagunovsky/redux-react-router';
// import { notify } from 'modules/common/components/Notifications';
// import { tHTML } from 'modules/utils/intl';
// import { uid } from 'react-uid';
import {
  COMMON_ERROR,
  configFromEnv,
  GET_POST_NFT_TOKEN,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
} from '../../api';
import { oryLoginAsync } from 'modules/auth';
import { RESPONSE_CODES } from '../../common/types';
// import { RoutesConfig } from '../Routes';

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

interface INewNft {
  id: string;
  collectionId: string;
  transactionHash: string;
  contractAddress: string;
}

export const { usePutNftMutation } = web3Api.injectEndpoints({
  endpoints: build => ({
    putNft: build.mutation<boolean, INewNft>({
      queryFn: queryFnNotifyWrapper<INewNft, never, boolean>(
        async (data: INewNft) => {
          const request = await fetch(
            nftManagerUrl +
              GET_POST_NFT_TOKEN +
              'id/?' +
              new URLSearchParams({
                id: data.id,
              }),
            {
              method: 'PUT',
              body: JSON.stringify({
                contractAddress: data.contractAddress,
                transactionHash: data.transactionHash,
              }),
              headers: new Headers({
                'Content-Type': 'application/json',
              }),
              credentials: 'include',
            },
          );

          if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
            await oryLoginAsync();
            throw UNAUTHORIZED_ERROR;
          }
          if (!request.ok) {
            throw COMMON_ERROR;
          }
          return { data: true };
        },
      ),
      // async onQueryStarted(args, { dispatch, queryFulfilled }) {
      //   return queryFulfilled.then(() => {
      //     dispatch(
      //       push(RoutesConfig.collection.generatePath(args.collectionId)),
      //     );
      //     notify({
      //       message: tHTML('notifications.nft-minted-successfully'),
      //       type: 'info',
      //       key: uid(args),
      //     });
      //   });
      // },
    }),
  }),
});
