import {
  Button as MuiButton,
  FormControl,
  Input,
  InputLabel,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import BigNumber from 'bignumber.js';
import { NetworkOrTokenIcon } from 'modules/common/components/NetworkOrTokenIcon';
import { NftMintNetwork, Token } from 'modules/common/types';
import { getShortId } from 'modules/common/utils/getShortId';
import { useCopyHook } from 'modules/common/utils/useCopyHook';
import { RoutesConfig } from 'modules/nft-manager/Routes';
import { INftMintedData } from 'modules/nft-manager/types';
import { t, tHTML } from 'modules/utils/intl';
import { useCallback, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { NavLink } from 'react-router-dom';
import { Button } from 'uiKit/components/Button';
import { ReactComponent as CopyIcon } from 'uiKit/icons/copy.svg';
import { ReactComponent as DoneIcon } from 'uiKit/icons/done.svg';
import { useSuccessMintStyles } from './useSuccessMintStyles';

const MAX_PRICE = 1_000;

interface ISuccessMintProps extends INftMintedData {
  isLoading: boolean;
  handleClickLaunch: (price: string) => void;
}

export const SuccessMint = ({
  collectionId,
  imageSrc,
  name,
  address,
  tokenId,
  quantity,
  fee,
  network,
  isLoading,
  handleClickLaunch,
}: ISuccessMintProps): JSX.Element => {
  const { classes } = useSuccessMintStyles();

  const regExp = new RegExp(`^(\\d*\\.{0,1}\\d{0,${8}}$)`);

  const [price, setPrice] = useState<string>('');
  const { isCopied, handleCopy } = useCopyHook();

  let token = Token.ETH;
  if (network === NftMintNetwork.Polygon) token = Token.MATIC;
  if (network === NftMintNetwork.BSC) token = Token.BNB;

  const handleLaunch = useCallback(
    () => handleClickLaunch(price),
    [handleClickLaunch, price],
  );

  return (
    <Box>
      <Box display="flex">
        <img alt="" src={imageSrc} className={classes.image} />
        <Box mb={16}>
          <Typography variant="body1" mb={3}>
            {name}
          </Typography>

          <Typography variant="body2" color="textSecondary">
            {t('nft.success-mint.contract-address')}
          </Typography>
          <Box display="flex" alignItems="center" mb={4}>
            <Typography variant="body2">{getShortId(address)} </Typography>
            <CopyToClipboard text={address} onCopy={handleCopy}>
              {isCopied ? (
                <DoneIcon className={classes.icon} />
              ) : (
                <CopyIcon className={classes.icon} />
              )}
            </CopyToClipboard>
          </Box>

          {!!tokenId && (
            <>
              <Typography variant="body2" color="textSecondary">
                {t('nft.success-mint.token-id')}
              </Typography>
              <Typography variant="body2">{tokenId}</Typography>
            </>
          )}

          {!!quantity && (
            <>
              <Typography variant="body2" color="textSecondary">
                {t('nft.success-mint.quantity')}
              </Typography>
              <Typography variant="body2">{quantity}</Typography>
            </>
          )}
        </Box>
      </Box>

      <Typography variant="h4" mb={1}>
        {t('nft.success-mint.launch-on-mirage')}
      </Typography>
      <Typography variant="body2" color="textSecondary" mb={1}>
        {tHTML('nft.success-mint.launch-on-mirage-description', {
          href: 'href',
        })}
      </Typography>

      <FormControl className={classes.inputWrapper}>
        <InputLabel>{t('nft.success-mint.price')}</InputLabel>
        <Input
          fullWidth
          value={price}
          placeholder="0"
          onChange={e => {
            const { value: inputValue } = e.target;
            const validated = inputValue.match(regExp);
            const isMoreThanMaxValue = new BigNumber(inputValue).isGreaterThan(
              MAX_PRICE,
            );
            if (
              (validated || !inputValue) &&
              !isMoreThanMaxValue &&
              inputValue !== '.'
            ) {
              if (!!inputValue) {
                setPrice(inputValue);
              } else {
                setPrice('');
              }
            }
          }}
          endAdornment={
            <Box display="flex" alignItems="center">
              <NetworkOrTokenIcon
                className={classes.tokenIcon}
                name={network}
              />

              <Typography>{token}</Typography>
            </Box>
          }
        />
      </FormControl>

      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography variant="body2">{t('nft.success-mint.fee')}</Typography>
        <Typography variant="body2">
          {t('formats.percent', {
            value: fee,
          })}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={8}>
        <Typography variant="body2">{t('nft.success-mint.receive')}</Typography>
        <Typography variant="body2">
          {t('formats.token-value', {
            value: !!price
              ? new BigNumber(price).multipliedBy(1 - fee / 100).toFormat(2)
              : '',
            token,
          })}
        </Typography>
      </Box>

      <Box display="flex" gap={3}>
        <NavLink to={RoutesConfig.collectionsList.generatePath()}>
          <MuiButton className={classes.button} variant="outlined" size="large">
            {t('nft.success-mint.skip')}
          </MuiButton>
        </NavLink>
        <Button
          onClick={handleLaunch}
          className={classes.button}
          disabled={!price}
          isLoading={isLoading}
          size="large"
        >
          {t('nft.success-mint.launch')}
        </Button>
      </Box>
    </Box>
  );
};
