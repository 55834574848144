import { uid } from 'react-uid';
import {
  COMMON_ERROR,
  configFromEnv,
  GET_PROJECTS_LIST_URL,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
} from '../../api';
import { oryLoginAsync } from 'modules/auth';
import { notify } from '../../common/components/Notifications';
import { EProjectTypes, RESPONSE_CODES } from '../../common/types';
import { tHTML } from '../../utils/intl';
import { EPlatform, ProjectCacheTags } from '../const';

const {
  gatewayConfig: { gamingUrl },
} = configFromEnv();

interface IUpdateProject {
  id: string;
  projectName: string;
  image: string;
  imageName: string;
  banner: string;
  bannerName: string;
  description: string;
  descriptionLong: string;
  platformTypes: EPlatform[];
  projectType: EProjectTypes;
}

export const { useUpdateProjectInfoMutation } = web3Api.injectEndpoints({
  endpoints: build => ({
    updateProjectInfo: build.mutation<boolean, IUpdateProject>({
      queryFn: queryFnNotifyWrapper<IUpdateProject, never, boolean>(
        async (data: IUpdateProject) => {
          console.log(data);
          const request = await fetch(
            gamingUrl + GET_PROJECTS_LIST_URL + data.id,
            {
              method: 'PUT',
              body: JSON.stringify({
                projectName: data.projectName,
                description: data.description,
                descriptionLong: data.descriptionLong,
                platformTypes: data.platformTypes,
                // image: data.image,
                // imageName: data.imageName,
                // banner: data.banner,
                // bannerName: data.bannerName,
                gameProjectType: data.projectType,
              }),
              headers: new Headers({
                'Content-Type': 'application/json;charset=utf-8',
              }),
              credentials: 'include',
            },
          );
          if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
            await oryLoginAsync();
            throw UNAUTHORIZED_ERROR;
          }
          if (!request.ok) throw COMMON_ERROR;

          return { data: true };
        },
      ),
      async onQueryStarted(args, { queryFulfilled }) {
        return queryFulfilled.then(() => {
          notify({
            message: tHTML('notifications.updated-successfully'),
            type: 'info',
            key: uid(args),
          });
        });
      },
      invalidatesTags: (res, err, args) => [
        ProjectCacheTags.projectData + args.id,
      ],
    }),
  }),
});
