import { TableCell as MuiTableCell, Typography } from '@mui/material';
import { useTableStyles } from './useTableStyles';
import { TooltipIcon } from '../../../modules/common/components/TooltipIcon';

interface ITableCellProps {
  title?: string;
  tooltip?: string;
}

export const TableHeadCell = ({
  title,
  tooltip,
  ...props
}: ITableCellProps): JSX.Element => {
  const { classes } = useTableStyles();
  return (
    <MuiTableCell {...props}>
      <Typography className={classes.headerCell} variant="captionAll">
        {title?.toUpperCase()}
      </Typography>
      {tooltip && (
        <TooltipIcon
          className={classes.tooltip}
          placement="top"
          title={tooltip}
        />
      )}
    </MuiTableCell>
  );
};
