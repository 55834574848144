import { isLocal, isProd } from './env';

export const featuresConfig = {
  isActiveFooterLinks: isLocal,
  isActiveHeaderIcons: !isProd,
  isActiveMemberActions: isLocal,
  isExistArchivedProjects: false,
  isAvailableMerging: false,
  isAvailableMultipleSCFiles: false,
  isActiveWizard: false,
  isActiveNFTManager: true,
  isAvailableNftFilters: false,
};
