import {
  ButtonBase,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ReactComponent as CopyIcon } from 'uiKit/icons/copy.svg';
import { ReactComponent as DoneIcon } from 'uiKit/icons/done.svg';
import { ReactComponent as EditIcon } from 'uiKit/icons/edit.svg';
import { mainPalette } from 'uiKit/mainPalette';
import { EProjectTypes, projectTypesNaming } from 'modules/common/types';
import { Dialog } from 'modules/common/components/Dialog';
import { useDialog } from 'modules/common/hooks';
import { useCopyHook } from 'modules/common/utils/useCopyHook';
import { t } from 'modules/utils/intl';
import { EditingType, EditProject } from '../EditProject';
import { useBasicInfoStyles } from './useBasicInfoStyles';
import { IImageData } from 'modules/projects/types';
import { EPlatform, PLATFORM_TITLE } from 'modules/projects/const';
import { uid } from 'react-uid';

const inputStyles = {
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: mainPalette.text.primary,
  },
};

interface IBasicInfoArgs {
  id?: string;
  name: string;
  description: string;
  platforms?: EPlatform[];
  image?: IImageData;
  banner?: IImageData;
  projectId: string;
  secret?: string;
  type: EProjectTypes;
}

export const BasicInfo = ({
  id,
  name,
  description,
  platforms,
  image,
  banner,
  projectId,
  secret,
  type,
}: IBasicInfoArgs): JSX.Element => {
  const { classes, cx } = useBasicInfoStyles();
  const {
    isOpened: isRenameOpened,
    onOpen: onRenameOpen,
    onClose: onRenameClose,
  } = useDialog();
  const {
    isOpened: isDescriptionOpened,
    onOpen: onDescriptionOpen,
    onClose: onDescriptionClose,
  } = useDialog();
  const {
    isOpened: isChangeTypeOpened,
    onOpen: onChangeTypeOpen,
    onClose: onChangeTypeClose,
  } = useDialog();
  const {
    isOpened: isChangePlatformOpened,
    onOpen: onChangePlatformOpen,
    onClose: onChangePlatformClose,
  } = useDialog();

  const { isCopied: isIdCopied, handleCopy: handleIdCopy } = useCopyHook();
  const { isCopied: isSecretCopied, handleCopy: handleSecretCopy } =
    useCopyHook();

  return (
    <>
      <Typography variant="h3" className={classes.mb6}>
        {t('projects.projectInfo.title')}
      </Typography>

      <FormControl>
        <InputLabel>{t('projects.projectInfo.name')}</InputLabel>
        <Input
          disabled
          fullWidth
          className={classes.mb6}
          value={name}
          sx={inputStyles}
          endAdornment={
            secret && (
              <ButtonBase onClick={onRenameOpen}>
                <EditIcon className={classes.icon} />
              </ButtonBase>
            )
          }
        />
      </FormControl>

      <FormControl>
        <TextField
          disabled
          fullWidth
          multiline
          rows={8}
          sx={inputStyles}
          label={t('projects.projectInfo.description')}
          className={cx(classes.description, classes.mb6)}
          value={description}
          inputProps={{ maxLength: 1_000 }}
          InputProps={{
            endAdornment: (
              <ButtonBase onClick={onDescriptionOpen}>
                <EditIcon className={classes.icon} />
              </ButtonBase>
            ),
          }}
        />
      </FormControl>

      {id && (
        <FormControl>
          <InputLabel>{t('projects.projectInfo.id')}</InputLabel>
          <Input
            disabled
            fullWidth
            className={classes.mb6}
            value={id}
            sx={inputStyles}
            endAdornment={
              secret && (
                <CopyToClipboard text={id} onCopy={handleIdCopy}>
                  <ButtonBase>
                    {isIdCopied ? (
                      <DoneIcon className={classes.icon} />
                    ) : (
                      <CopyIcon className={classes.icon} />
                    )}
                  </ButtonBase>
                </CopyToClipboard>
              )
            }
          />
        </FormControl>
      )}

      {secret && (
        <FormControl color="success">
          <InputLabel>{t('projects.projectInfo.secret')}</InputLabel>
          <Input
            disabled
            fullWidth
            className={classes.mb6}
            value={secret}
            sx={inputStyles}
            endAdornment={
              <CopyToClipboard text={secret} onCopy={handleSecretCopy}>
                <ButtonBase>
                  {isSecretCopied ? (
                    <DoneIcon className={classes.icon} />
                  ) : (
                    <CopyIcon className={classes.icon} />
                  )}
                </ButtonBase>
              </CopyToClipboard>
            }
          />
          <FormHelperText>
            {t('projects.projectInfo.secret-helper-text')}
          </FormHelperText>
        </FormControl>
      )}

      <FormControl>
        <InputLabel>{t('projects.projectInfo.type-of-app')}</InputLabel>
        <Input
          disabled
          fullWidth
          className={classes.mb6}
          value={projectTypesNaming[type]}
          sx={inputStyles}
          endAdornment={
            secret && (
              <ButtonBase onClick={onChangeTypeOpen}>
                <EditIcon className={classes.icon} />
              </ButtonBase>
            )
          }
        />
      </FormControl>

      {!!platforms && platforms.length > 0 && (
        <FormControl key={uid(platforms)}>
          <InputLabel>
            {t('projects.projectInfo.connected-platform')}
          </InputLabel>
          <Input
            disabled
            fullWidth
            className={classes.mb15}
            value={platforms
              .map(platform => t(PLATFORM_TITLE[platform]))
              .join(', ')}
            sx={inputStyles}
            endAdornment={
              secret && (
                <ButtonBase onClick={onChangePlatformOpen}>
                  <EditIcon className={classes.icon} />
                </ButtonBase>
              )
            }
          />
        </FormControl>
      )}

      <Dialog
        title={t('projects.projectInfo.rename-project')}
        open={isRenameOpened}
        onClose={onRenameClose}
      >
        <EditProject
          editingType={EditingType.Rename}
          id={projectId}
          initName={name}
          initPlatforms={platforms}
          initDescription={description}
          initImage={image}
          initBanner={banner}
          initProjectType={type}
        />
      </Dialog>

      <Dialog
        title={t('projects.projectInfo.edit-description')}
        open={isDescriptionOpened}
        onClose={onDescriptionClose}
      >
        <EditProject
          editingType={EditingType.Description}
          id={projectId}
          initName={name}
          initPlatforms={platforms}
          initDescription={description}
          initImage={image}
          initBanner={banner}
          initProjectType={type}
        />
      </Dialog>

      <Dialog
        title={t('projects.projectInfo.edit-type')}
        open={isChangeTypeOpened}
        onClose={onChangeTypeClose}
      >
        <EditProject
          editingType={EditingType.ProjectType}
          id={projectId}
          initName={name}
          initPlatforms={platforms}
          initDescription={description}
          initImage={image}
          initBanner={banner}
          initProjectType={type}
        />
      </Dialog>

      <Dialog
        title={t('projects.projectInfo.edit-platform')}
        open={isChangePlatformOpened}
        onClose={onChangePlatformClose}
      >
        <EditProject
          editingType={EditingType.Platform}
          id={projectId}
          initName={name}
          initPlatforms={platforms}
          initDescription={description}
          initImage={image}
          initBanner={banner}
          initProjectType={type}
        />
      </Dialog>
    </>
  );
};
