import BigNumber from 'bignumber.js';
import {
  Address,
  NftMintNetwork,
  ReactText,
  TTokenStandart,
} from 'modules/common/types';
import { TimeFrames } from './const';

export enum NftStatus {
  'LISTED',
  'SOLD',
  'NotListed',
}

export interface INftTokenNftCollectionItem {
  id: string;
  nftTokenId: string;
  nftCollectionId: string;
}

export interface IGameProjectNftCollectionItem {
  gameProjectName?: string | null;
  gameProjectId: string;
  nftCollectionId: string;
}

export interface IGameProjectNftCollectionIdItem
  extends IGameProjectNftCollectionItem {
  id: string;
}

export interface ICollectionImageItem {
  id: string;
  fileUri: string;
  fileName: string;
  domainEvents: [];
}

export interface ISimpleProjectData {
  id: string;
  name: string;
}

export interface ICollectionItem {
  id: string;
  name: string;
  description: string;
  url: string | null;
  created: string;
  lastModified: string;
  // TODO: change it to ISimpleProjectData
  gameProject?: string | null;
  logoImage: ICollectionImageItem;
  countOfNftInCollection: number;
}

export interface INewCollectionData {
  name: string;
  description?: string;
  logo?: File;
  featured?: File;
  banner?: File;
  url: string;
  projectId: string;
  projectName: string;
}

export interface ICollectionsListData {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  data?: ICollectionItem[];
  totalCount: number;
  page: number;
  totalPages: number;
  isLoading: boolean;
  searchValue: ReactText;
  nftSold: number;
  usdSold: BigNumber;
  timeFrame: TimeFrames;
  handleChangeTimeFrame: (timeFrame: TimeFrames) => void;
  changePage: (value: number) => void;
  setSearch: (value: ReactText) => void;
}

interface INftProperty {
  id: string;
  name: string;
  type: string;
  value: string;
}

interface IContentItem {
  id: string;
  domainEvents: unknown[];
  fileUri: string;
  fileName: string;
}

export interface INftInfoData {
  id?: string;
  name?: string;
  description?: string;
  tokenId: string;
  status: NftStatus;
  royalty?: number;
  quantity: number | null;
  price?: number;
  chainCode: NftMintNetwork | null;
  contractAddress: string;
  uri?: string;
  nftContractId: string;
  nftAsset?: IContentItem;
  nftProperties?: INftProperty[]; // TODO: add it
  nftCollectionId: string;
}

export interface INftItem {
  name: string;
  id: string;
  tokenId: string;
  network: NftMintNetwork;
  token: string;
  tokenPrice: BigNumber;
  usdPrice: BigNumber;
  status: NftStatus;
  address: Address;
  nftAsset?: IContentItem;
  tokenTemplate: TTokenStandart; // TODO: change it to enum ?
  royalty: number;
  instances: number;
  plazaLink?: string;
  stopSale?: () => void;
  launchOnMarketplace?: () => void;
}

export interface ICollectionData {
  id?: string;
  isLoading: boolean;
  banner: string;
  logo: string;
  name: string;
  description: string;
  created: string;
  lastModified: string;
  project?: string;
  nftItems: INftItem[];
  nftSold: number;
  usdSold: BigNumber;
  timeFrame: TimeFrames;
  editLink: string;
  addNftLink: string;
  marketplaceLink: string;
  isCardView: boolean;
  handleChangeView: () => void;
  handleChangeTimeFrame: (timeFrame: TimeFrames) => void;
}

export interface INftCollectionItem {
  id: string;
  name: string;
  description: string;
  tokenId: string;
  status: number;
  nftAsset: {
    id: string;
    domainEvents: unknown[];
    fileUri: string;
    fileName: string;
  };
  chainCodes: string | null;
  royalty: number;
  price: number;
  contractAddress: string | null;
}

export interface ICollectionTokensItem {
  name: string;
  description: string;
  collectionId: string;
  logoImage: ICollectionImageItem;
  bannerImage: ICollectionImageItem;
  created: string;
  lastModified: string;
  gameProject: string;
  nfts: INftCollectionItem[];
}

export interface IAttribute {
  id: string;
  name: string;
  value: string;
}

export interface INftContract {
  id: string;
  name: string;
  description: string;
  royalty: number;
  nftTemplateId: string;
  contractAddress: string;
  chainCode: NftMintNetwork;
  nftDeployments: unknown[];
}

export interface INftTemplate {
  id: string;
  name: string;
  description: string;
  contractCode: string;
  isPrototype: boolean;
}

export enum TokenStandart {
  ERC721 = 'ERC-721',
  ERC1155 = 'ERC-1155',
}

export interface INftMintedData {
  collectionId: string;
  imageSrc: string;
  name: string;
  address: string;
  tokenId: string;
  quantity: number | null;
  fee: number;
  network: NftMintNetwork;
}
