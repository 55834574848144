// mosaic-game-services section
export const GET_PROJECTS_LIST_URL = 'GameProject/';
export const GET_PROJECT_INFO_INVITE_URL = 'GameProject/invite/';

export const CONTRACT = 'Contract';

export const CONTRACT_VERSION = 'ContractVersion';
export const POST_COMPILE_FILES = `${CONTRACT_VERSION}/compile`;
export const GET_DRAFT_CV = `${CONTRACT_VERSION}/draft`;
export const GET_CV_BYTECODE = `${CONTRACT_VERSION}/bytecode`;
export const POST_CV_PUBLISH = `${CONTRACT_VERSION}/publish`;
export const GET_UNPUBLISHED_C_V = `${CONTRACT_VERSION}/`;

export const CONTRACT_FILES = 'ContractFile';
export const POST_CONTRACT_FILE_UPLOAD = `${CONTRACT_FILES}/upload`;

// mirage-id section
export const GET_ORGANIZATIONS_URL = 'Organizations';
export const GET_GROUPS = 'Groups';
export const POST_USER_INVITE = 'Users/invite';
export const GET_WALLET_VERIFICATION = 'Wallet/VerifySignature';

// external section
export const COINGECKO_API = 'https://api.coingecko.com/api/';
export const GET_COINGECKO_ETH_DATA = `${COINGECKO_API}v3/coins/ethereum`;
export const GET_COINGECKO_MATIC_DATA = `${COINGECKO_API}v3/coins/matic-network`;

// nft-manager section
export const GET_POST_COLLECTION = 'NftCollection/';
export const GET_POST_NFT_TOKEN = 'NftToken/';
export const GET_POST_NFT_LISTING = 'NftListing/';
export const POST_PUBLISH_ON_PLAZA_DATA = `${GET_POST_NFT_TOKEN}mint-data`;
export const GET_POST_NFT_CONTRACT = 'NftContract/';
export const GET_NFT_TEMPLATE = 'NftTemplate/';
export const GET_NFT_MINT_DATA = `${GET_POST_NFT_CONTRACT}mint-data`;
export const GET_IS_WALLET_CONNECTED = 'Wallet/isWalletConnected';
