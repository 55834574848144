import { CSSObject } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

const TRANSFORM_STYLES: CSSObject = {
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 100,
  left: 20,

  '& + &': {
    left: 50,
    zIndex: 99,
  },
  '& + & + &': {
    left: 80,
    zIndex: 98,
  },
};

export const useNetworksListStyles = makeStyles()(theme => ({
  root: {
    color: theme.palette.text.secondary,
    position: 'relative',
  },
  adjustTokenIcon: {
    [theme.breakpoints.up('md')]: TRANSFORM_STYLES,
  },
  commonTokenIcon: TRANSFORM_STYLES,
}));
