import { makeStyles } from 'tss-react/mui';

export const useMintNftStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    gap: theme.spacing(28),
    maxWidth: 544,
  },
  mb3: {
    marginBottom: theme.spacing(3),
  },
  mb4: {
    marginBottom: theme.spacing(4),
  },
  mb5: {
    marginBottom: theme.spacing(5),
  },
  mb6: {
    marginBottom: theme.spacing(6),
  },
  mb7: {
    marginBottom: theme.spacing(7),
  },
  mb8: {
    marginBottom: theme.spacing(8),
  },
  mb12: {
    marginBottom: theme.spacing(12.5),
  },
  textfield: {
    '& .MuiInputBase-root': {
      height: 'unset',

      '& .MuiFilledInput-input': {
        padding: theme.spacing(3, 2, 3),
      },
    },
    '& .MuiFormHelperText-root': {
      textAlign: 'right',
      position: 'absolute',
      right: theme.spacing(3),
      bottom: theme.spacing(3),
    },
  },
  uploaderWrapper: {
    border: '1px dashed #000000',
    borderRadius: 16,

    '& > label': {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  imageSize: {
    width: 188,
    height: 188,
    borderRadius: 16,
  },
  errorUploderWrapper: {
    border: `1px dashed ${theme.palette.error.main}`,
  },
  plusIcon: {
    marginRight: theme.spacing(2.5),
  },
  previewRoot: {
    height: 452,
    width: 356,
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  previewImage: {
    height: 356,
    width: 356,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
  },
  fullWidth: {
    width: '100%',
  },
  changeImageText: {
    color: theme.palette.violet.main,
    cursor: 'pointer',
  },
  smallNetworkIcon: {
    height: 32,
    width: 32,

    '& + &': {
      marginLeft: theme.spacing(-2),
      zIndex: -1,
    },

    '& + & + &': {
      marginLeft: theme.spacing(-2),
      zIndex: -2,
    },
  },
  doneIcon: {
    marginRight: theme.spacing(3),
    height: 28,
    width: 28,
  },
  attributeRoot: {
    display: 'flex',
    gap: theme.spacing(5),
    position: 'relative',
  },
  input: {
    width: 262,
  },
  removeAttributeButton: {
    position: 'absolute',
    right: theme.spacing(-10),
    top: theme.spacing(9),
  },
}));
