import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  createRouterMiddleware,
  createRouterReducer,
} from '@lagunovsky/redux-react-router';
import { persistStore } from 'redux-persist';
import { i18nPersistReducer } from '../i18n/store/i18nPersistReducer';
import { web3Api } from '../modules/api';
import { historyInstance } from '../modules/common/utils/historyInstance';

const rootReducer = combineReducers({
  i18n: i18nPersistReducer,
  [web3Api.reducerPath]: web3Api.reducer,
  router: createRouterReducer(historyInstance),
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    })
      .concat(web3Api.middleware)
      .concat(createRouterMiddleware(historyInstance)),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
