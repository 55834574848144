import { makeStyles } from 'tss-react/mui';

export const useHeaderStyles = makeStyles()(theme => ({
  root: {
    height: 240,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(7),
    position: 'relative',
    backgroundColor: '#1E1C33',
  },
  wrap: {
    height: 240,
    width: '100%',
    display: 'flex',
    position: 'absolute',
    zIndex: 1,
    flexDirection: 'column',
    padding: theme.spacing(7, 10),
  },
  img: {
    height: 240,
    width: '100%',
    opacity: 0.4,
    position: 'absolute',
    zIndex: 1,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  link: {
    color: theme.palette.common.white,
  },
  logo: {
    height: 60,
    width: 60,
    borderRadius: 16,
  },
  icon: {
    height: 20,
    width: 20,
    marginRight: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(3.5),
  },
  ellipse: {
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
  },
  button: {
    width: 112,
    color: theme.palette.common.white,
    marginRight: theme.spacing(3),

    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  marketLink: {
    width: 220,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.common.white,
  },
  shareIcon: {
    height: 16,
    width: 16,
    marginLeft: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    position: 'absolute',
    bottom: 36,
    right: 40,
  },
  detailName: {
    color: theme.palette.grey[700],
  },
}));
