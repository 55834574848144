import { Typography } from '@mui/material';
import { useDescriptionItemStyles } from './useDescriptionItemStyles';

interface IDescriptionItemProps {
  name: string;
  firstText: string;
  secondText: string;
  isHeader?: boolean;
}

export const DescriptionItem = ({
  name,
  firstText,
  secondText,
  isHeader = false,
}: IDescriptionItemProps): JSX.Element => {
  const { classes, cx } = useDescriptionItemStyles();

  return (
    <div className={cx(classes.row, isHeader && classes.pb)}>
      <Typography
        flex={1}
        className={classes.item}
        color="textSecondary"
        variant={isHeader ? 'h3' : 'body2'}
      >
        {name}
      </Typography>
      <Typography
        flex={3}
        className={classes.item}
        variant={isHeader ? 'h3' : 'body2'}
      >
        {firstText}
      </Typography>
      <Typography
        flex={3}
        className={classes.item}
        variant={isHeader ? 'h3' : 'body2'}
      >
        {secondText}
      </Typography>
    </div>
  );
};
