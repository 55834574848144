import {
  Button as MuiButton,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Input,
} from '@mui/material';
import BigNumber from 'bignumber.js';
import { NetworkOrTokenIcon } from 'modules/common/components/NetworkOrTokenIcon';
import { NftMintNetwork } from 'modules/common/types';
import { getNativeToken } from 'modules/common/utils/getNativeToken';
import { t, tHTML } from 'modules/utils/intl';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'uiKit/components/Button';
import { useLaunchDialogStyles } from './useLaunchDialogStyles';

interface ILaunchDialogProps {
  network: NftMintNetwork;
  isLoading: boolean;
  handleLaunchNft: () => void;
}

const MAX_PRICE = 1_000;
const FEE = 2.5;

export const LaunchDialog = ({
  network,
  isLoading,
  handleLaunchNft,
}: ILaunchDialogProps): JSX.Element => {
  const { classes } = useLaunchDialogStyles();

  const regExp = new RegExp(`^(\\d*\\.{0,1}\\d{0,${8}}$)`);

  const [price, setPrice] = useState<string>('');

  return (
    <Box>
      <Typography variant="body1" color="textSecondary" mb={8}>
        {tHTML('nft.nft-info.launch.description')}
      </Typography>

      <FormControl className={classes.inputWrapper}>
        <InputLabel>{t('nft.success-mint.price')}</InputLabel>
        <Input
          fullWidth
          value={price}
          placeholder="0"
          onChange={e => {
            const { value: inputValue } = e.target;
            const validated = inputValue.match(regExp);
            const isMoreThanMaxValue = new BigNumber(inputValue).isGreaterThan(
              MAX_PRICE,
            );
            if (
              (validated || !inputValue) &&
              !isMoreThanMaxValue &&
              inputValue !== '.'
            ) {
              if (!!inputValue) {
                setPrice(inputValue);
              } else {
                setPrice('');
              }
            }
          }}
          endAdornment={
            <Box display="flex" alignItems="center">
              <NetworkOrTokenIcon
                className={classes.tokenIcon}
                name={network}
              />

              <Typography>{getNativeToken(network)}</Typography>
            </Box>
          }
        />
      </FormControl>

      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography variant="body2">{t('nft.success-mint.fee')}</Typography>
        <Typography variant="body2">
          {t('formats.percent', {
            value: FEE,
          })}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={8}>
        <Typography variant="body2">{t('nft.success-mint.receive')}</Typography>
        <Typography variant="body2">
          {t('formats.token-value', {
            value: !!price
              ? new BigNumber(price).multipliedBy(1 - FEE / 100).toFormat(2)
              : '',
            token: getNativeToken(network),
          })}
        </Typography>
      </Box>

      <Box display="flex" gap={3}>
        <NavLink to={''}>
          <MuiButton className={classes.button} variant="outlined" size="large">
            {t('nft.success-mint.skip')}
          </MuiButton>
        </NavLink>
        <Button
          onClick={handleLaunchNft}
          className={classes.button}
          disabled={!price}
          isLoading={isLoading}
          size="large"
        >
          {t('nft.success-mint.launch')}
        </Button>
      </Box>
    </Box>
  );
};
