import { makeStyles } from 'tss-react/mui';

export const useHeaderStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  titles: {
    display: 'flex',
    flexDirection: 'column',
  },

  firstTitle: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
  },

  secondTitle: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    color: theme.palette.grey[900],
  },

  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 64,
    gap: 12,
    padding: theme.spacing(2, 0),
    margin: 'auto 0',
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
  },

  input: {
    border: 'none !important',
    padding: '0 !important',
    height: 50,
    width: 262,
    fontSize: 15,
    fontWeight: 500,
  },

  closeIcon: {
    marginTop: 11,
    color: theme.palette.error.main,
  },
}));
