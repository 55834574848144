import { makeStyles } from 'tss-react/mui';

export const useStepperStyles = makeStyles()(theme => ({
  root: {
    marginBottom: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  secondaryColor: {
    color: theme.palette.text.secondary,
  },
  itemRoot: {
    display: 'flex',
    alignItems: 'start',
    flex: 1,
    flexDirection: 'column',
    marginRight: theme.spacing(5),
    cursor: 'default',
    borderRadius: 8,

    '&:last-child': {
      marginRight: 0,
    },
  },
  clickable: {
    cursor: 'pointer',
  },
  line: {
    width: '100%',
    height: 4,
    border: 'none',
    backgroundColor: theme.palette.grey[500],
    borderRadius: 2,
  },
  chip: {
    width: 24,
    height: 24,
    borderRadius: 8,
    marginRight: theme.spacing(2.5),
    backgroundColor: theme.palette.grey[700],

    '& > span': {
      padding: 0,
      color: theme.palette.common.white,
    },
  },
  done: {
    backgroundColor: theme.palette.success.main,
  },
  active: {
    backgroundColor: theme.palette.violet.main,
  },
  disabledTitle: {
    color: theme.palette.text.disabled,
  },
}));
