import { Typography } from '@mui/material';
import { ReactText } from '../../../../../common/types';
import { t } from '../../../../../utils/intl';
import { useNotFoundStyles } from './useNotFoundStyles';

interface INotFoundProps {
  searchValue: ReactText;
}

export const NotFound = ({ searchValue }: INotFoundProps): JSX.Element => {
  const { classes } = useNotFoundStyles();

  return (
    <Typography className={classes.root}>
      {t('projects.projectList.not-found', {
        value: searchValue,
      })}
    </Typography>
  );
};
