import { useState } from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { ReactComponent as MoreIcon } from 'uiKit/icons/more.svg';
import { useProjectMembersStyles } from './useProjectMembersStyles';
import { t } from '../../../../../utils/intl';

interface IUserActionsProps {
  id: string;
}
enum Actions {
  Edit,
  Revoke,
}

const ITEM_HEIGHT = 48;

export const UserActions = ({ id }: IUserActionsProps): JSX.Element => {
  const { classes } = useProjectMembersStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEdit = () => {
    handleClose();
  };
  const handleRevoke = () => {
    handleClose();
  };

  return (
    <>
      <IconButton
        aria-haspopup
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreIcon className={classes.moreIcon} />
      </IconButton>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '109px',
            fontSize: '13px',
          },
        }}
      >
        <MenuItem key={Actions.Edit} onClick={handleEdit}>
          <Typography variant="body2">
            {t('projects.projectInfo.edit')}
          </Typography>
        </MenuItem>
        <MenuItem key={Actions.Revoke} onClick={handleRevoke}>
          <Typography variant="body2">
            {t('projects.projectInfo.revoke')}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
