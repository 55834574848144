import { generatePath, Route, useParams } from 'react-router-dom';
import { Layout } from '../common/components/Layout';
import { createRouteConfig, useQueryParams } from '../router';
import { AddingProject } from './screens/AddingProject';
import { InviteUser } from './screens/InviteUser';
import { ProjectInfo } from './screens/ProjectInfo';
import { ProjectList } from './screens/ProjectList';

const ROOT_PATH = '/projects/';
const PROJECT_PATH = `${ROOT_PATH}:id/`;
const INVITE_USER_PROJECT_PATH = `${ROOT_PATH}:id/invite/`;
const ADDING_PROJECT_ROOT_PATH = `${ROOT_PATH}add`;

export const RoutesConfig = createRouteConfig(
  {
    projects: {
      path: ROOT_PATH,
      generatePath: (id?: string) => {
        return generatePath(ROOT_PATH, { id: id });
      },
      useParams: () => {
        const params = useParams<{ id: string }>();
        return {
          ...params,
        };
      },
    },

    project: {
      path: PROJECT_PATH,
      generatePath: (id?: string) => {
        return id
          ? generatePath(PROJECT_PATH, { id })
          : generatePath(ROOT_PATH);
      },
      useParams: () => {
        return {
          id: useQueryParams().get('id') ?? undefined,
        };
      },
    },

    inviteUser: {
      path: INVITE_USER_PROJECT_PATH,
      generatePath: (id?: string) => {
        return id
          ? generatePath(INVITE_USER_PROJECT_PATH, { id })
          : generatePath(ROOT_PATH);
      },
      useParams: () => {
        return {
          id: useQueryParams().get('id') ?? undefined,
        };
      },
    },

    addingProject: {
      path: ADDING_PROJECT_ROOT_PATH,
      generatePath: () => generatePath(ADDING_PROJECT_ROOT_PATH),
    },
  },

  ROOT_PATH,
);

export function getRoutes(): JSX.Element {
  return (
    <>
      <Route
        element={
          <Layout>
            <ProjectList />
          </Layout>
        }
        path={RoutesConfig.projects.path}
      />
      <Route
        element={
          <Layout>
            <ProjectInfo />
          </Layout>
        }
        path={RoutesConfig.project.path}
      />
      <Route
        element={
          <Layout>
            <InviteUser />
          </Layout>
        }
        path={RoutesConfig.inviteUser.path}
      />
      <Route
        element={
          <Layout>
            <AddingProject />
          </Layout>
        }
        path={RoutesConfig.addingProject.path}
      />
    </>
  );
}
