import React from 'react';
import { ButtonBase } from '@mui/material';
import { ReactComponent as CloseIcon } from 'uiKit/icons/close-small.svg';
import { useNotificationsStyles } from './useNotificationsStyles';

export const CloseButton = (): JSX.Element => {
  const { classes } = useNotificationsStyles();

  return (
    <ButtonBase className={classes.closeButton}>
      <CloseIcon />
    </ButtonBase>
  );
};
