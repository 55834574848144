import { makeStyles } from 'tss-react/mui';

export const useSidebarStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.grey[300],
    padding: '28px 12px',
  },
  logo: {
    padding: '0 12px',
  },
  content: {},
  menuSegment: {
    '&:nth-of-type(1)': {
      marginTop: 32,
    },
    '&:nth-of-type(2)': {
      marginTop: 48,
    },
  },
  menuTitle: {
    color: theme.palette.grey[900],
    padding: '0 12px',
  },
  menuLinks: {
    marginTop: 8,
    gap: 20,
  },
  menuDropdowns: {
    marginTop: 24,
  },
}));
