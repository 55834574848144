import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { uid } from 'react-uid';
import { TableBodyRow, TableHeadCell } from 'uiKit/components/Table';
import { ReactComponent as PlucIcon } from 'uiKit/icons/plus.svg';
import { t } from 'modules/utils/intl';
import { ICVPublishData } from '../../../../types';
import { StepHeader } from '../StepHeader';
import { usePublishStyles } from './usePublishStyles';
import { NetworkOrTokenIcon } from 'modules/common/components/NetworkOrTokenIcon';
import { Dialog } from 'modules/common/components/Dialog';
import { Transaction } from '../Transaction';
import { useDialog } from 'modules/common/hooks';
import { Network } from 'modules/common/types';

interface IPublishFilesProps {
  cvPublishData: ICVPublishData | null;
  byteCode?: string;
  isPublishLoading: boolean;
  isCVByteCodeLoading: boolean;
  handleGetCVByteCode: () => void;
  handlePublish: (gas: number) => void;
}

export const PublishedFiles = ({
  cvPublishData,
  byteCode,
  isPublishLoading,
  isCVByteCodeLoading,
  handleGetCVByteCode,
  handlePublish,
}: IPublishFilesProps): JSX.Element | null => {
  const { classes } = usePublishStyles();
  const { isOpened, onOpen, onClose } = useDialog();

  if (!cvPublishData) return null;

  const handleOnOpen = () => {
    handleGetCVByteCode();
    onOpen();
  };

  return (
    <>
      <StepHeader
        title={t('smart-contracts.publishing-files-title')}
        description={t('smart-contracts.publishing-description')}
      />
      <TableContainer component={Paper} className={classes.tableWrapper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell title={t('smart-contracts.publish.name')} />
              {/* <TableHeadCell
                title={t('smart-contracts.publish.publishing-options')}
                tooltip={t(
                  'smart-contracts.publish.publishing-options-tooltip',
                )}
              /> */}
              <TableHeadCell title={t('smart-contracts.publish.network')} />
              {/* <TableHeadCell /> */}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableBodyRow key={uid(cvPublishData)}>
              <TableCell style={{ width: 200 }}>
                <Typography>{cvPublishData?.name}</Typography>
              </TableCell>
              {/* <TableCell style={{ width: 200 }}>publish as new</TableCell> */}
              <TableCell style={{ width: 100 }}>
                <Box display="flex">
                  <NetworkOrTokenIcon name={cvPublishData?.network} />
                  <Tooltip arrow title={t('utils.coming-soon')}>
                    <span>
                      <Button disabled className={classes.addNetworkButton}>
                        <PlucIcon />
                      </Button>
                    </span>
                  </Tooltip>
                </Box>
              </TableCell>
              {/* <TableCell align="right" style={{ width: 100 }}>
                  <ActionCell
                    status={row.status}
                    handleCancel={() => console.log('handleCancel')}
                    handleDelete={() => console.log('handleDelete')}
                    handleRetry={() => console.log('handleRetry')}
                  />
                </TableCell> */}
            </TableBodyRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Button size="large" onClick={handleOnOpen}>
        {t('smart-contracts.publish.publish-button')}
      </Button>

      {!!cvPublishData && (
        <Dialog
          title={t('smart-contracts.publish.transaction.title')}
          open={isOpened}
          onClose={onClose}
        >
          <Transaction
            name={cvPublishData.name}
            networks={[Network.Eth]}
            byteCode={byteCode}
            isPublishLoading={isPublishLoading}
            isCVByteCodeLoading={isCVByteCodeLoading}
            handlePublish={handlePublish}
          />
        </Dialog>
      )}
    </>
  );
};
