import { oryLoginAsync } from 'modules/auth';
import {
  COMMON_ERROR,
  configFromEnv,
  GET_NFT_TEMPLATE,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
} from '../../api';
import { RESPONSE_CODES } from '../../common/types';
import { INftTemplate } from '../types';

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

export const { useGetNftTemplatesQuery } = web3Api.injectEndpoints({
  endpoints: build => ({
    getNftTemplates: build.query<INftTemplate[], void>({
      queryFn: queryFnNotifyWrapper<void, never, INftTemplate[]>(async () => {
        const request = await fetch(nftManagerUrl + GET_NFT_TEMPLATE, {
          method: 'GET',
          credentials: 'include',
        });

        if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
          await oryLoginAsync();
          throw UNAUTHORIZED_ERROR;
        }
        if (!request.ok) throw COMMON_ERROR;

        return { data: await request.json() };
      }),
    }),
  }),
});
