import { Box, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { ReactComponent as ClockIcon } from 'modules/../uiKit/icons/clock.svg';
import { NetworkOrTokenIcon } from 'modules/common/components/NetworkOrTokenIcon/NetworkOrTokenIcon';
import { TOKEN_ROUNDING, USD_ROUNDING } from 'modules/common/const';
import { Minutes, Network, Token } from 'modules/common/types';
import { t } from 'modules/utils/intl';
import { useTransactionStyles } from './useTransactionStyles';

export interface IFeeItemProps {
  network: Network;
  token: Token;
  tokenFee: BigNumber;
  usdFee: BigNumber;
  waitingMinutes: Minutes;
}

export const FeeItem = ({
  network,
  token,
  tokenFee,
  usdFee,
  waitingMinutes,
}: IFeeItemProps): JSX.Element => {
  const { classes } = useTransactionStyles();
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" mb={4}>
        <NetworkOrTokenIcon name={network} />

        <Box display="flex" flexDirection="column" ml={3}>
          <Typography variant="body1">
            {t('formats.token-value', {
              value: tokenFee.decimalPlaces(TOKEN_ROUNDING[token]).toFixed(),
              token,
            })}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {t('formats.usd', {
              value: usdFee.isZero()
                ? '-'
                : usdFee.decimalPlaces(USD_ROUNDING).toFixed(),
            })}
          </Typography>
        </Box>
      </Box>

      <Typography color="textSecondary" className={classes.verticalAlign}>
        {t('smart-contracts.publish.transaction.min', {
          value: waitingMinutes,
        })}
        <ClockIcon className={classes.clockIcon} />
      </Typography>
    </Box>
  );
};
