import CopyToClipboard from 'react-copy-to-clipboard';
import { Box, Typography } from '@mui/material';
import { getShortId } from 'modules/common/utils/getShortId';
import { useCopyHook } from 'modules/common/utils/useCopyHook';
import { ReactComponent as CopyIcon } from 'uiKit/icons/copy.svg';
import { ReactComponent as DoneIcon } from 'uiKit/icons/done.svg';
import { useProjectIdStyles } from './useProjectIdStyles';

interface IProjectIdProps {
  id: string;
  className?: string;
}

export const ProjectId = ({ id, className }: IProjectIdProps): JSX.Element => {
  const { classes, cx } = useProjectIdStyles();
  const { isCopied, handleCopy } = useCopyHook();

  return (
    <Box display="flex" alignItems="center" className={className}>
      <Typography variant="body2" mr={2}>
        {getShortId(id)}
      </Typography>
      <div onClick={e => e.preventDefault()}>
        <CopyToClipboard text={id} onCopy={handleCopy}>
          {isCopied ? (
            <DoneIcon className={cx(classes.icon, classes.doneIcon)} />
          ) : (
            <CopyIcon className={classes.icon} />
          )}
        </CopyToClipboard>
      </div>
    </Box>
  );
};
