import BigNumber from 'bignumber.js';
import { Network, Token } from './types';

export type Milliseconds = number;

export const ACTION_CACHE_SEC = 600;

export const SEARCH_DEBOUNCE_TIME: Milliseconds = 300;

export const SUPPORT_EMAIL = 'info@mirage.xyz';

export const DOCS_LINK = 'https://mirage.xyz/docs/';

export const ZERO = new BigNumber(0);

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const DOWNLOAD_METAMASK = 'https://metamask.io/download/';

export const PAGE_SIZE = 10;

export const IMAGE_MAX_SIZE_MB = 1;

export const AVG_TX_TIME: Record<Network, number> = {
  [Network.Eth]: 5,
  [Network.Bsc]: 1,
  [Network.Polygon]: 1,
  [Network.Arbitrum]: 1,
};

export const NETWORK_NATIVE_TOKEN: Record<Network, Token> = {
  [Network.Eth]: Token.ETH,
  [Network.Bsc]: Token.BNB,
  [Network.Polygon]: Token.MATIC,
  [Network.Arbitrum]: Token.ARB,
};

export const USD_ROUNDING = 2;
export const ETH_ROUNDING = 8;
export const BNB_ROUNDING = 8;
export const MATIC_ROUNDING = 8;
export const ARB_ROUNDING = 8;

export const TOKEN_ROUNDING: Record<Token, number> = {
  [Token.ETH]: ETH_ROUNDING,
  [Token.BNB]: BNB_ROUNDING,
  [Token.MATIC]: MATIC_ROUNDING,
  [Token.ARB]: ARB_ROUNDING,
};

export const DEFAULT_DATE_FORMAT = 'dd.MM.yyyy, hh:mm a';
export const SHORT_DATE_FORMAT = 'dd/MM/yyyy';
