import {
  COMMON_ERROR,
  configFromEnv,
  POST_COMPILE_FILES,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
} from 'modules/api';
import { oryLoginAsync } from 'modules/auth';
import { RESPONSE_CODES } from 'modules/common/types';
import { ICompileErrorItem } from '../../../types';

interface ICompileFilesResponse {
  contractVersionState: number;
  id: string;
  name: string;

  // errors data
  ValidationErrors?: ICompileErrorItem[];
  status?: number;
  title?: string;
  type?: string;
}

interface ICompileFilesArgs {
  id: string;
}

const {
  gatewayConfig: { gamingUrl },
} = configFromEnv();

export const { useCompileFilesMutation } = web3Api.injectEndpoints({
  endpoints: build => ({
    compileFiles: build.mutation<
      ICompileFilesResponse | null,
      ICompileFilesArgs
    >({
      queryFn: queryFnNotifyWrapper<
        ICompileFilesArgs,
        never,
        ICompileFilesResponse | null
      >(async (data: ICompileFilesArgs) => {
        const request = await fetch(
          gamingUrl +
            POST_COMPILE_FILES +
            '?' +
            new URLSearchParams({
              id: data.id,
            }),
          {
            method: 'POST',
            credentials: 'include',
          },
        );

        if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
          await oryLoginAsync();
          throw UNAUTHORIZED_ERROR;
        }
        if (
          !request.ok &&
          request.status !== RESPONSE_CODES.PRECONDITION_FAILED
        )
          throw COMMON_ERROR;

        return {
          data: await request.json(),
        };
      }),
    }),
  }),
});
