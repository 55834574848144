import { Box, ButtonBase, Chip, Typography } from '@mui/material';
import { STEP } from '../../../../types';
import { useStepperStyles } from './useStepperStyles';

interface IStepItemProps {
  title: string;
  step: STEP;
  isActive: boolean;
  isDone: boolean;
  goToStep: () => void;
}

export const StepItem = ({
  title,
  step,
  isActive,
  isDone,
  goToStep,
}: IStepItemProps): JSX.Element => {
  const { classes, cx } = useStepperStyles();
  return (
    <ButtonBase
      className={cx(classes.itemRoot, isDone && classes.clickable)}
      disabled={!isDone}
      onClick={() => (isDone ? goToStep() : null)}
    >
      <hr
        className={cx(
          classes.line,
          isActive && classes.active,
          isDone && classes.done,
        )}
      />
      <Box display="flex" alignItems="center">
        <Chip
          className={cx(
            classes.chip,
            isActive && classes.active,
            isDone && classes.done,
          )}
          label={<Typography>{step}</Typography>}
        />
        <Typography
          className={cx(!isActive && !isDone && classes.disabledTitle)}
        >
          {title}
        </Typography>
      </Box>
    </ButtonBase>
  );
};
