import { getUniqueId } from '../utils/getUniqueId';

export enum TimeFrames {
  Today,
  Yesterday,
  '1W',
  '1M',
  'All time',
}

export const analyticsTimeFrames: Record<TimeFrames, string> = {
  [TimeFrames.Today]: 'Today',
  [TimeFrames.Yesterday]: 'Yesterday',
  [TimeFrames['1W']]: '1W',
  [TimeFrames['1M']]: '1M',
  [TimeFrames['All time']]: 'All time',
};

export const COLLECTION_TILE_MAX_WIDTH = 330;
export const NFT_TILE_MAX_WIDTH = 262;

export const NftCacheTags = {
  commonList: getUniqueId(),
  collectionData: getUniqueId(),
  nftData: getUniqueId(),
};
