import { useCreateCollectionMutation } from 'modules/nft-manager/actions/useCreateCollectionMutation';
import { INewCollectionData } from 'modules/nft-manager/types';
import {
  IProjectListItem,
  useLazyGetProjectListQuery,
} from 'modules/projects/actions/useLazyGetProjectListQuery';
import { useCallback, useEffect } from 'react';

interface ICreateCollectionFormData {
  isDataLoading: boolean;
  isLoading: boolean;
  projects: IProjectListItem[];
  onSubmit: (data: INewCollectionData) => void;
}

export const useCreateCollectionForm = (): ICreateCollectionFormData => {
  const [getProjectList, { data: projects, isFetching }] =
    useLazyGetProjectListQuery();
  const [createCollection, { isLoading }] = useCreateCollectionMutation();

  useEffect(() => {
    getProjectList({});
  }, [getProjectList]);

  const handleCreateCollection = useCallback(
    (data: INewCollectionData): void => {
      const { name, description, logo, banner, featured, projectId } = data;
      if (!!logo && !!banner && !!featured) {
        const projectName =
          projects?.items.find(x => x.id === projectId)?.projectName ?? '';
        const projectFileName =
          projects?.items.find(x => x.id === projectId)?.image?.fileName ?? '';
        const projectFileUri =
          projects?.items.find(x => x.id === projectId)?.image?.fileUri ?? '';
        createCollection({
          Name: name,
          Description: description ?? '',
          Url: 'url',
          Logo: logo,
          Banner: banner,
          Featured: featured,
          GameProject: projectId,
          GameProjectName: projectName,
          GameProjectFileName: projectFileName,
          GameProjectFileUri: projectFileUri,
          NftTokenIds: [],
        });
      }
    },
    [createCollection, projects?.items],
  );

  return {
    isDataLoading: isFetching,
    projects: projects?.items ?? [],
    isLoading: isLoading,
    onSubmit: handleCreateCollection,
  };
};
