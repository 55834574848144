import { Typography } from '@mui/material';
import { useHeaderStyles } from './useHeaderStyles';
import { t, tHTML } from '../../../../../utils/intl';

export const Header = (): JSX.Element => {
  const { classes } = useHeaderStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h2">
        {t('smart-contracts.title')}
      </Typography>
      <Typography className={classes.secondaryColor}>
        {tHTML('smart-contracts.description', {
          href: 'href',
        })}
      </Typography>
    </div>
  );
};
