import { queryFnWrapper } from '@ankr.com/utils';
import { uid } from 'react-uid';

import { getErrMsg } from './getErrMsg';
import { notify } from '../../common/components/Notifications';

export const queryFnNotifyWrapper = queryFnWrapper({
  onNotification({ error, onError }) {
    const errMsg =
      typeof onError === 'function' ? onError(error) : getErrMsg(error as any);

    notify({
      message: errMsg,
      type: 'error',
      key: uid(errMsg),
    });
  },
});
