import BigNumber from 'bignumber.js';
import { ACTION_CACHE_SEC, ZERO, ZERO_ADDRESS } from 'modules/common/const';
import { NftMintNetwork, TTokenStandart, Token } from 'modules/common/types';
import { useGetCollectionQuery } from 'modules/nft-manager/actions/useGetCollectionQuery';
import { useGetNftContractsQuery } from 'modules/nft-manager/actions/useGetNftContractsQuery';
import { useGetNftTemplatesQuery } from 'modules/nft-manager/actions/useGetNftTemplatesQuery';
import { TimeFrames } from 'modules/nft-manager/const';
import { ICollectionData } from 'modules/nft-manager/types';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router';

export const useCollectionInfoData = (): ICollectionData => {
  const { id } = useParams();

  const [timeFrame, setTimeFrame] = useState<TimeFrames>(TimeFrames.Today);
  const [cardView, setCardView] = useState(true);

  const { data, isFetching } = useGetCollectionQuery(
    { id },
    {
      skip: !id,
      refetchOnMountOrArgChange: ACTION_CACHE_SEC,
    },
  );
  const { data: nftContractsData, isFetching: isFetchingNftContractData } =
    useGetNftContractsQuery(undefined, {
      refetchOnMountOrArgChange: ACTION_CACHE_SEC,
    });
  const { data: nftTemplates, isFetching: isNftTemplatesLoading } =
    useGetNftTemplatesQuery(undefined, {
      refetchOnMountOrArgChange: ACTION_CACHE_SEC,
    });

  const handleChangeTimeFrame = useCallback(
    (timeFrame: TimeFrames) => {
      setTimeFrame(timeFrame);
    },
    [setTimeFrame],
  );

  const handleChangeView = useCallback(() => {
    setCardView(cardView => !cardView);
  }, [setCardView]);

  // todo: timofei change hardcode
  return {
    id,
    isLoading:
      isFetching ||
      isFetchingNftContractData ||
      isNftTemplatesLoading ||
      isFetchingNftContractData,
    banner: data ? data.bannerImage.fileUri : '',
    logo: data ? data.logoImage.fileUri : '',
    name: data?.name ?? '',
    description: data?.description ?? '',
    created: data?.created ?? '',
    lastModified: data?.lastModified ?? '',
    project: data?.gameProject,
    nftItems:
      data?.nfts.map(nft => {
        const nftContract = nftContractsData?.find(
          x => x.contractAddress === nft.contractAddress,
        );
        const nftTemplate = !!nftContract
          ? nftTemplates?.find(x => x.id === nftContract.nftTemplateId)?.name ??
            ''
          : '';

        let token = Token.ETH;
        if (!!nft.chainCodes && nft.chainCodes[0] === NftMintNetwork.Polygon)
          token = Token.MATIC;

        return {
          ...nft,
          network: nft.chainCodes as NftMintNetwork,
          token,
          tokenPrice: new BigNumber(nft.price),
          usdPrice: new BigNumber(nft.price).multipliedBy(41),
          address: nft.contractAddress ?? ZERO_ADDRESS,
          tokenTemplate: nftTemplate as TTokenStandart,
          instances: 1,
        };
      }) ?? [],
    nftSold: (10 + timeFrame) * 2,
    usdSold: ZERO.plus(13_789).plus(timeFrame * 7_312.12),
    timeFrame,
    editLink: 'editLink',
    addNftLink: 'addNftLink',
    marketplaceLink: 'marketplaceLink',
    isCardView: cardView,
    handleChangeView,
    handleChangeTimeFrame,
  };
};
