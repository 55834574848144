import { makeStyles } from 'tss-react/mui';

export const usePaginationStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(4),

    [theme.breakpoints.up('xs')]: {
      flexDirection: 'row',
    },
  },

  itemRoot: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },

  itemNumberRoot: {
    borderRadius: 12,
    border: `1px solid ${theme.palette.grey[700]}`,
  },

  active: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.violet.main,
    border: 'none',
  },

  rotateNextButton: {
    transform: 'rotate(180deg)',
  },
}));
