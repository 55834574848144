import { useParams } from 'react-router';
import { Roles } from '../../../common/types';
import { useInviteUserMutation } from '../../actions/useInviteUserMutation';

export interface IUserBaseInfo {
  email: string;
  role: Roles | string;
}

export interface INewUser extends IUserBaseInfo {
  gameProjectId: string;
  organizationId: string;
}

interface IUserInviteFormData {
  isLoading: boolean;
  onSubmit: (data: IUserBaseInfo, organizationId: string) => void;
}

export const useInviteUserForm = (): IUserInviteFormData => {
  const { id } = useParams();
  const [inviteUser, { isLoading }] = useInviteUserMutation();

  const onSubmit = (data: IUserBaseInfo, organizationId: string) => {
    if (id) {
      inviteUser({
        ...data,
        organizationId,
        gameProjectId: id,
      });
    }
  };

  return {
    isLoading,
    onSubmit,
  };
};
