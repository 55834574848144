import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import { uid } from 'react-uid';
import { ReactComponent as DocsIcon } from 'uiKit/icons/docs.svg';
import { ReactComponent as HelpSupportIcon } from 'uiKit/icons/help-support.svg';
// import { ReactComponent as DashboardIcon } from 'uiKit/icons/dashboard.svg'
// import { ReactComponent as AnalyticsIcon } from 'uiKit/icons/analytics.svg';
import { ReactComponent as ProjectsIcon } from 'uiKit/icons/projects.svg';
import { useMainMenuStyles } from './useMainMenuStyles';
import { t } from 'modules/utils/intl';
import { isExternalPath } from 'modules/utils/isExternalPath';
import { DOCS_LINK, SUPPORT_EMAIL } from 'modules/common/const';
import { checkParentPathMatch } from '../../utils/checkPathMatch';

interface Props {
  className?: string;
}

interface LinkProps extends NavLinkProps {
  icon: React.FC;
  children: string;
  disabled?: boolean;
  className?: string;
}

interface IMenuItem {
  title: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  link: string;
}

// todo: check why 't' function doesn't work!
const MENU: IMenuItem[] = [
  // {
  //   title: 't("layout.menu.dashboard"),
  //   icon: DashboardIcon,
  //   link: '/'
  // },
  // {
  //   title: 'layout.menu.analytics',
  //   icon: AnalyticsIcon,
  //   link: '/analytics/',
  // },
  {
    // todo: check why it doesn't work!
    // title: t("layout.menu.projects"),
    title: 'layout.menu.projects',
    icon: ProjectsIcon,
    link: '/projects/',
  },
  {
    title: 'layout.menu.docs',
    icon: DocsIcon,
    link: DOCS_LINK,
  },
  {
    title: 'layout.menu.support',
    icon: HelpSupportIcon,
    link: `mailto:${SUPPORT_EMAIL}`,
  },
];

function Link({ children, to, icon: Icon }: LinkProps) {
  const { classes, cx } = useMainMenuStyles();

  const [match, setMatch] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setMatch(checkParentPathMatch(pathname, to.toString()));
  }, [pathname, to]);

  if (isExternalPath(to.toString())) {
    return (
      <MuiLink
        href={to.toString()}
        className={cx(classes.root, match && classes.active)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon />
        <Typography component="span">{children}</Typography>
      </MuiLink>
    );
  }

  return (
    <NavLink to={to} className={cx(classes.root, match && classes.active)}>
      <Icon />
      <Typography component="span">{children}</Typography>
    </NavLink>
  );
}

export function MainMenu({ className }: Props): JSX.Element {
  return (
    <Box className={className}>
      {MENU.map(({ title, icon, link }) => (
        <Link key={uid(link)} icon={icon} to={link}>
          {t(title)}
        </Link>
      ))}
    </Box>
  );
}
