import { Box, Divider, Typography } from '@mui/material';
import { Button } from 'modules/../uiKit/components/Button';
import { ReactComponent as ArrowIcon } from 'modules/../uiKit/icons/arrow-left.svg';
import { LoaderCentered } from 'modules/common/components/Loader';
import { NetworksList } from 'modules/common/components/NetworksList';
import { TooltipIcon } from 'modules/common/components/TooltipIcon';
import { USD_ROUNDING } from 'modules/common/const';
import { Network } from 'modules/common/types';
import { t } from 'modules/utils/intl';
import { uid } from 'react-uid';
import { FeeItem } from './FeeItem';
import { useTransactionData } from './useTransactionData';
import { useTransactionStyles } from './useTransactionStyles';

interface ITransactionProps {
  name: string;
  networks: Network[];
  byteCode?: string;
  isPublishLoading: boolean;
  isCVByteCodeLoading: boolean;
  handlePublish: (gas: number) => void;
}

export const Transaction = ({
  name,
  networks,
  byteCode,
  isPublishLoading,
  isCVByteCodeLoading,
  handlePublish,
}: ITransactionProps): JSX.Element => {
  const { classes, cx } = useTransactionStyles();

  const { feeItems, gas, totalUsdFee, isLoading } = useTransactionData({
    networks,
    byteCode,
  });

  const isMainDataLoading = isCVByteCodeLoading || isLoading;

  return (
    <Box>
      <Typography variant="body1" mb={4}>
        {t('smart-contracts.publish.transaction.smart-contracts')}
      </Typography>

      <div className={cx(classes.scNetworksWrapper, classes.mb8)}>
        <div className={classes.contractNameCell}>
          <Typography variant="body1" marginLeft={5}>
            {name}
          </Typography>
        </div>

        <div className={classes.arrowWrapper}>
          <ArrowIcon className={classes.arrowIcon} />
        </div>

        <div className={classes.networksCell}>
          <NetworksList
            className={classes.networkInternal}
            networks={networks}
          />
        </div>
      </div>

      <Typography variant="body1" mb={4}>
        {t('smart-contracts.publish.transaction.tx-fee')}
        <TooltipIcon
          className={classes.tooltip}
          placement="right"
          title={t('smart-contracts.publish.transaction.tx-fee-tooltip')}
        />
      </Typography>

      {isMainDataLoading && <LoaderCentered />}

      {!isMainDataLoading && (
        <>
          <div>
            {feeItems.map(item => (
              <FeeItem
                key={uid(item)}
                network={item.network}
                token={item.token}
                tokenFee={item.tokenFee}
                usdFee={item.usdFee}
                waitingMinutes={item.waitingMinutes}
              />
            ))}
          </div>

          <Divider className={classes.mb4} />

          <Box display="flex" alignItems="center" mb={8}>
            <Typography variant="body2">
              {t('smart-contracts.publish.transaction.total')}
            </Typography>
            <Typography variant="h3" marginLeft={5.5}>
              {t('formats.usd', {
                value: totalUsdFee.isZero()
                  ? '-'
                  : totalUsdFee.decimalPlaces(USD_ROUNDING).toFixed(),
              })}
            </Typography>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="center">
            <Button
              fullWidth
              isLoading={isPublishLoading}
              type="submit"
              variant="contained"
              size="large"
              className={cx(isPublishLoading && classes.button)}
              onClick={() => (isPublishLoading ? null : handlePublish(gas))}
            >
              {t('smart-contracts.publish.transaction.confirm')}
            </Button>

            {isPublishLoading && (
              <Typography variant="body2" color="textSecondary">
                {t('smart-contracts.publish.transaction.waiting')}
              </Typography>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
