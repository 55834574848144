import { Button, Typography } from '@mui/material';
import MuiLink from '@mui/material/Link';
import { format } from 'date-fns';
import { SHORT_DATE_FORMAT } from 'modules/common/const';
import { RoutesConfig } from 'modules/nft-manager/Routes';
import { ProjectIcons } from 'modules/nft-manager/components/ProjectIcons';
import { ICollectionItem } from 'modules/nft-manager/types';
import { t } from 'modules/utils/intl';
import { NavLink } from 'react-router-dom';
import { ReactComponent as ShareIcon } from 'uiKit/icons/share.svg';
import { useCollectionTileStyles } from './useCollectionTileStyles';

export const CollectionTile = ({
  id,
  name,
  countOfNftInCollection,
  gameProject,
  logoImage,
  created,
  lastModified,
}: ICollectionItem): JSX.Element => {
  const { classes } = useCollectionTileStyles(undefined);

  return (
    <div className={classes.root}>
      <NavLink to={RoutesConfig.collection.generatePath(id)}>
        <img alt="" className={classes.image} src={logoImage.fileUri} />
      </NavLink>

      <div className={classes.info}>
        <div className={classes.header}>
          <Typography className={classes.name} variant="h3">
            {name}
          </Typography>
          {!!gameProject && <ProjectIcons projects={[gameProject]} />}
        </div>
        <div className={classes.stats}>
          <div className={classes.count}>
            <Typography variant="captionAll">
              {t('nft.collections-list.items', {
                value: countOfNftInCollection,
              })}
            </Typography>
          </div>
          <div className={classes.item}>
            <Typography color="textSecondary" variant="captionAll">
              {t('nft.collections-list.created')}
            </Typography>
            <Typography variant="body2">
              {format(new Date(created), SHORT_DATE_FORMAT)}
            </Typography>
          </div>
          <div className={classes.item}>
            <Typography color="textSecondary" variant="captionAll">
              {t('nft.collections-list.last-edited')}
            </Typography>
            <Typography variant="body2">
              {format(new Date(lastModified), SHORT_DATE_FORMAT)}
            </Typography>
          </div>
        </div>
        <div className={classes.buttons}>
          <Button className={classes.button} fullWidth>
            {t('nft.collections-list.edit-collection')}
          </Button>
          <MuiLink
            href={'marketplaceLink'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography className={classes.link} component="span">
              {t('nft.collections-list.view-on-marketplace')}
              <ShareIcon className={classes.icon} />
            </Typography>
          </MuiLink>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};
