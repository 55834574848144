import { makeStyles } from 'tss-react/mui';

export const useEmptyStyles = makeStyles()(theme => ({
  root: {
    position: 'relative',
    marginTop: 40,
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    position: 'absolute',
    left: 40,
    bottom: 40,

    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      color: theme.palette.common.white,
      gap: 4,
    },

    '& > button': {
      width: 220,
    },
  },

  imageWrapper: {
    width: '100%',
    height: '300px',
    overflow: 'hidden',

    '& > img': {
      width: '100%',
      height: '100%',
    },
  },

  image: {},

  button: {
    '& > button': {
      color: theme.palette.common.white,

      '&:hover': {
        color: theme.palette.common.white,
      },
    },
  },

  icon: {
    marginRight: theme.spacing(3),
  },
}));
