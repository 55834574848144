import {
  COMMON_ERROR,
  configFromEnv,
  CONTRACT_VERSION,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
} from 'modules/api';
import { oryLoginAsync } from 'modules/auth';
import { Network, RESPONSE_CODES } from 'modules/common/types';
import { IContractFileItem } from '../../../types';

interface IGetIGetCVDataResponse {
  id: string;
  name: string;
  contractCode: number | null;
  created: string;
  lastModified: string;
  stateEnum: number;
  contractStandart: Network;
  contractFiles: IContractFileItem[];
}

interface IGetCVDataArgs {
  id: string;
}

const {
  gatewayConfig: { gamingUrl },
} = configFromEnv();

export const { useLazyGetCVDataQuery } = web3Api.injectEndpoints({
  endpoints: build => ({
    getCVData: build.query<IGetIGetCVDataResponse, IGetCVDataArgs>({
      queryFn: queryFnNotifyWrapper<
        IGetCVDataArgs,
        never,
        IGetIGetCVDataResponse
      >(async (data: IGetCVDataArgs) => {
        const request = await fetch(
          gamingUrl + CONTRACT_VERSION + '/' + data.id,
          {
            method: 'GET',
            credentials: 'include',
          },
        );

        if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
          await oryLoginAsync();
          throw UNAUTHORIZED_ERROR;
        }
        if (!request.ok) throw COMMON_ERROR;

        return {
          data: await request.json(),
        };
      }),
    }),
  }),
});
