import { Chip, Typography } from '@mui/material';
import { useBadgeStyles } from './useBadgeStyles';

interface IBadgeProps {
  text: string;
  color: 'grey' | 'black' | 'violent' | 'orange';
}

export const Badge = ({ text, color }: IBadgeProps): JSX.Element => {
  const { classes, cx } = useBadgeStyles();

  return (
    <Chip
      className={cx(
        classes.root,
        color === 'grey' && classes.grey,
        color === 'black' && classes.black,
        color === 'violent' && classes.violent,
        color === 'orange' && classes.orange,
      )}
      label={<Typography variant="caption">{text}</Typography>}
    />
  );
};
