import { Tabs as BaseTabs, TabsProps } from '@mui/material';
import { ChangeEvent } from 'react';
import { useTabsStyles } from './useTabsStyles';

type TRestrictedTabsProps = Omit<
  TabsProps,
  'className' | 'indicatorColor' | 'scrollButtons' | 'variant' | 'onChange'
>;

interface ITabsProps extends TRestrictedTabsProps {
  onChangeTab(newTab: string): void;
}

export const Tabs = ({
  onChangeTab,
  ...restProps
}: ITabsProps): JSX.Element => {
  const { classes } = useTabsStyles();

  const handleChange = (_event: ChangeEvent<{}>, tabValue: string) =>
    onChangeTab(tabValue);

  return (
    <BaseTabs
      {...restProps}
      className={classes.tabs}
      indicatorColor="secondary"
      scrollButtons="auto"
      variant="scrollable"
      onChange={handleChange}
      TabIndicatorProps={{
        style: {
          display: 'none',
        },
      }}
    />
  );
};
