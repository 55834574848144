import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useNotificationsStyles = makeStyles()(theme => ({
  root: {
    '&&': {
      width: 'auto',
      maxWidth: 'var(--toastify-toast-width)',
    },
  },

  toast: {
    '&&': {
      color: theme.palette.common.white,
      width: 456,
      backgroundColor: alpha(theme.palette.grey[400], 0.8),
      backdropFilter: 'blur(16px)',
      borderRadius: 12,
      cursor: 'default',
      marginBottom: 0,
      '& + &': {
        marginTop: theme.spacing(2),
      },
    },
  },

  toastBody: {
    wordBreak: 'break-word',
  },

  closeButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
}));
