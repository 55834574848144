import { Components, menuItemClasses, Palette } from '@mui/material';

export function getMuiPaper(palette: Palette): Components['MuiPaper'] {
  return {
    styleOverrides: {
      root: {
        borderRadius: 16,
        border: `1px solid ${palette.grey[300]}`,
        boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.12)',
        marginTop: 4,

        [`& .${menuItemClasses.root}`]: {
          padding: '8px 20px',
        },
      },
    },
  };
}
