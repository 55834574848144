import { Button, Link, Typography } from '@mui/material';
import { Dialog } from '../Dialog';
import { DOWNLOAD_METAMASK } from '../../const';
import { t } from '../../../utils/intl';
import MetamaskDemo from '../../assets/metamask-demo.png';
import { useUnexistsMetamaskStyles } from './useUnexistsMetamaskStyles';

interface IUnexistsMetamaskProps {
  isOpened: boolean;
  onClose: () => void;
}

export const UnexistsMetamask = ({
  isOpened,
  onClose,
}: IUnexistsMetamaskProps): JSX.Element => {
  const { classes } = useUnexistsMetamaskStyles();

  return (
    <Dialog
      className={classes.root}
      closeButtonClassName={classes.closeButton}
      open={isOpened}
      onClose={onClose}
    >
      <div className={classes.header}>
        <img alt="" className={classes.image} src={MetamaskDemo} />
      </div>
      <div className={classes.body}>
        <Typography variant="h2" mb={2} pt={10}>
          {t('unexists-metamask.title')}
        </Typography>

        <Typography variant="body1" color="textSecondary" mb={8}>
          {t('unexists-metamask.description')}
        </Typography>

        <Link
          href={DOWNLOAD_METAMASK}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button fullWidth size="large" variant="contained">
            {t('unexists-metamask.button')}
          </Button>
        </Link>
      </div>
    </Dialog>
  );
};
