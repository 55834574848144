import { useParams } from 'react-router';
import { ACTION_CACHE_SEC } from '../../../common/const';
import { EProjectTypes } from '../../../common/types';
import {
  IMemberItem,
  useGetProjectInfoQuery,
} from '../../actions/useGetProjectInfoQuery';
import { IImageData } from 'modules/projects/types';
import { EPlatform } from 'modules/projects/const';

interface IUseProjectInfoData {
  id?: string;
  name: string;
  description: string;
  platforms?: EPlatform[];
  image?: IImageData;
  banner?: IImageData;
  projectId: string;
  secret?: string;
  projectType: EProjectTypes;
  members: IMemberItem[] | null;
  isLoading: boolean;
}

export const useProjectInfoData = (): IUseProjectInfoData => {
  const { id } = useParams();

  const { data, isFetching } = useGetProjectInfoQuery(
    { id },
    {
      skip: !id,
      refetchOnMountOrArgChange: ACTION_CACHE_SEC,
    },
  );

  return {
    id: data?.clientSecret?.clientId,
    name: data?.projectName ?? '',
    description: data?.description ?? '',
    platforms: data?.platformTypes,
    image: data?.image,
    banner: data?.banner,
    projectId: id ?? '',
    secret: data?.clientSecret?.clientSecret,
    projectType: data?.projectType ?? 0,
    members: data?.members ?? null,
    isLoading: isFetching,
  };
};
