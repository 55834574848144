import { Components, Palette } from '@mui/material';

export const getMuiButton = (palette: Palette): Components['MuiButton'] => ({
  styleOverrides: {
    root: {
      backgroundColor: palette.violet.main,
      borderColor: palette.violet.main,
      color: palette.common.white,
      transition: '0.3s',
      textTransform: 'initial',
      boxShadow: 'none',

      '&:hover': {
        backgroundColor: palette.violet[80],
        borderColor: palette.violet[80],
        boxShadow: 'none',
      },

      '&:disabled': {
        background: palette.grey[700],
        borderColor: palette.grey[700],
        color: palette.common.white,
        opacity: 0.6,
        boxShadow: 'none',
      },
    },

    outlined: {
      backgroundColor: 'transparent',
      color: palette.common.black,
      borderColor: palette.grey[700],
      boxShadow: 'none',

      '&:hover': {
        backgroundColor: 'transparent',
        color: palette.common.black,
        borderColor: palette.grey[500],
        boxShadow: 'none',
      },

      '&:disabled': {
        backgroundColor: 'transparent',
        color: palette.grey[700],
        borderColor: palette.grey[500],
        boxShadow: 'none',
      },
    },

    sizeMedium: {
      fontSize: 15,
      borderRadius: 20,
      height: 40,
      minHeight: 40,
      padding: '10px 24px',
    },

    sizeLarge: {
      fontSize: 15,
      borderRadius: 32,
      height: 64,
      minHeight: 64,
      padding: '22px 60px',
    },
  },
});
