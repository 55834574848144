import { makeStyles } from 'tss-react/mui';

export const useProjectItemStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(0, 4, 0, 5),
    alignItems: 'center',
    borderRadius: 16,
    boxShadow: 'none',
    height: 96,
    border: `1px solid ${theme.palette.grey[500]}`,
    cursor: 'pointer',
    marginBottom: theme.spacing(4),

    '&:last-of-type': {
      marginBottom: theme.spacing(12),
    },
  },
  image: {
    height: 56,
    width: 56,
    borderRadius: 8,
    marginRight: theme.spacing(3),
  },
  radio: {
    marginRight: theme.spacing(4),
  },
  active: {
    border: `1px solid ${theme.palette.violet.main}`,
    backgroundColor: theme.palette.violet[10],
  },
  error: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  id: {
    color: theme.palette.grey[900],
  },
}));
