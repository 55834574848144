import { push } from '@lagunovsky/redux-react-router';
import { notify } from 'modules/common/components/Notifications';
import { tHTML } from 'modules/utils/intl';
import { uid } from 'react-uid';
import {
  COMMON_ERROR,
  configFromEnv,
  GET_POST_COLLECTION,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
} from '../../api';
import { RESPONSE_CODES } from '../../common/types';
import { NftCacheTags } from '../const';
import { RoutesConfig } from '../Routes';
import { oryLoginAsync } from 'modules/auth';

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

interface INewCollection {
  Name: string;
  Description: string;
  GameProject: string;
  GameProjectName: string;
  GameProjectFileUri: string;
  GameProjectFileName: string;
  Url: string;
  Logo: File;
  Banner: File;
  Featured: File;
  NftTokenIds: string[];
}

export const { useCreateCollectionMutation } = web3Api.injectEndpoints({
  endpoints: build => ({
    createCollection: build.mutation<boolean, INewCollection>({
      queryFn: queryFnNotifyWrapper<INewCollection, never, boolean>(
        async (data: INewCollection) => {
          const bodyData = new FormData();
          bodyData.append('Logo', data.Logo);
          bodyData.append('Banner', data.Banner);
          bodyData.append('Featured', data.Featured);
          bodyData.append('Name', data.Name);
          bodyData.append('Description', data.Description);
          bodyData.append('GameProject', data.GameProject);
          bodyData.append('GameProjectName', data.GameProjectName);
          bodyData.append('GameProjectFileUri', data.GameProjectFileUri);
          bodyData.append('GameProjectFileName', data.GameProjectFileName);
          bodyData.append('Url', data.Url);
          bodyData.append('NftTokenIds', JSON.stringify(data.NftTokenIds));

          const request = await fetch(nftManagerUrl + GET_POST_COLLECTION, {
            method: 'POST',
            body: bodyData,
            credentials: 'include',
          });

          if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
            await oryLoginAsync();
            throw UNAUTHORIZED_ERROR;
          }
          if (!request.ok) {
            throw COMMON_ERROR;
          }
          return { data: await request.json() };
        },
      ),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        return queryFulfilled.then(async () => {
          dispatch(push(RoutesConfig.collectionsList.generatePath()));
          notify({
            message: tHTML('notifications.collection-created-successfully'),
            type: 'info',
            key: uid(args),
          });
        });
      },
      invalidatesTags: [NftCacheTags.commonList],
    }),
  }),
});
