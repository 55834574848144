import { ButtonBase } from '@mui/material';
import { getPagesArray } from 'modules/common/utils/getPagesArray';
import { uid } from 'react-uid';
import { ReactComponent as ChevronRightIcon } from 'uiKit/icons/chevronRight.svg';
import { PaginationItem } from './PaginationItem';
import { usePaginationStyles } from './usePaginationStyles';

interface IPaginationProps {
  totalPages: number;
  page: number;
  changePage: (value: number) => void;
}

const ARRAY_SIZE = 5;

export const Pagination = ({
  totalPages,
  page,
  changePage,
}: IPaginationProps) => {
  const pagesArray = getPagesArray(totalPages, page, ARRAY_SIZE);
  const { classes } = usePaginationStyles();

  return (
    <div className={classes.root}>
      {pagesArray[0] > 1 && (
        <ButtonBase
          className={classes.itemRoot}
          onClick={() => changePage(page - 1)}
        >
          <ChevronRightIcon className={classes.rotateNextButton} />
        </ButtonBase>
      )}
      {pagesArray.map(p => (
        <PaginationItem
          page={p}
          isActive={page === p}
          changePage={changePage}
          key={uid(p)}
        />
      ))}
      {pagesArray[pagesArray.length - 1] < totalPages && (
        <ButtonBase
          className={classes.itemRoot}
          onClick={() => changePage(page + 1)}
        >
          <ChevronRightIcon />
        </ButtonBase>
      )}
    </div>
  );
};
