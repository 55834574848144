import { PAGE_SIZE } from 'modules/common/const';
import {
  COMMON_ERROR,
  configFromEnv,
  GET_POST_COLLECTION,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
} from '../../api';
import { oryLoginAsync } from 'modules/auth';
import { RESPONSE_CODES } from '../../common/types';
import { NftCacheTags } from '../const';
import { ICollectionItem } from '../types';

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

interface IReponse {
  items: ICollectionItem[];
  pageNumber: number;
  totalCount: number;
  totalPages: number;
}

interface IGetCollectionsListArgs {
  name?: string;
  pageNumber?: number;
  pageSize?: number;
}

interface IGetCollectionsListParams {
  Name?: string;
  PageNumber?: string;
  PageSize?: string;
}

export const { useLazyGetCollectionsListQuery } = web3Api.injectEndpoints({
  endpoints: build => ({
    getCollectionsList: build.query<IReponse, IGetCollectionsListArgs>({
      queryFn: queryFnNotifyWrapper<IGetCollectionsListArgs, never, IReponse>(
        async ({ pageNumber = '1', pageSize = PAGE_SIZE }) => {
          const params: IGetCollectionsListParams = {};
          if (pageNumber) {
            params.PageNumber = pageNumber.toString();
          }
          if (pageSize) {
            params.PageSize = pageSize.toString();
          }
          const request = await fetch(
            nftManagerUrl +
              GET_POST_COLLECTION +
              'owner' +
              '?' +
              new URLSearchParams({
                ...params,
              }),
            {
              method: 'GET',
              credentials: 'include',
            },
          );

          if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
            await oryLoginAsync();
            throw UNAUTHORIZED_ERROR;
          }
          if (!request.ok) throw COMMON_ERROR;

          return { data: await request.json() };
        },
      ),
      providesTags: [NftCacheTags.commonList],
    }),
  }),
});
