import { useBigImageStyles } from './useBigImageStyles';

interface IBigImageProps {
  imageSrc: string;
}

export const BigImage = ({ imageSrc }: IBigImageProps): JSX.Element => {
  const { classes } = useBigImageStyles();

  return <img alt="" className={classes.root} src={imageSrc} />;
};
