import { makeStyles } from 'tss-react/mui';

export const useListStyles = makeStyles()(theme => ({
  root: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(4),
  },
  tabs: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
  },
  tab: {
    paddingBottom: 14,
    borderBottom: `4px solid ${theme.palette.violet.main}`,
  },
  list: {
    marginTop: 24,
  },
  listHeader: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 0.1fr',
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    color: theme.palette.grey[900],
    padding: theme.spacing(3, 5),
  },
  listItem: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 0.1fr',
    padding: theme.spacing(5, 5),
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    cursor: 'pointer',

    '&:last-of-type': {
      borderBottom: 'unset',
    },

    '&:hover': {
      backgroundColor: theme.palette.grey[300],
      borderRadius: 12,

      '& > :last-child': {
        display: 'flex',
      },
    },
  },
  logoBlock: {
    display: 'flex',
    gap: 12,
  },
  logo: {
    width: 40,
    height: 40,
    backgroundColor: theme.palette.grey[500],
    borderRadius: 12,
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 4,
  },
  platforms: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grey[900],
    gap: 8,
  },
  action: {
    display: 'none',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.violet.main,
    color: theme.palette.common.white,
    borderRadius: 12,
    transform: 'rotate(-90deg)',
    width: 24,
    height: 24,
    '& > svg': {
      height: 16,
    },
  },
}));
