import { Button } from '@mui/material';
import { t } from 'modules/utils/intl';

interface IStopSaleButtonProps {
  onClick?: () => void;
  className?: string;
}

export const StopSaleButton = ({
  onClick,
  className,
}: IStopSaleButtonProps): JSX.Element => {
  return (
    <Button
      fullWidth
      variant="outlined"
      className={className}
      onClick={onClick}
    >
      {t('nft.stop-sale')}
    </Button>
  );
};
