import { IImageData } from 'modules/projects/types';
import { EProjectTypes } from '../../../../../common/types';
import { useUpdateProjectInfoMutation } from '../../../../actions/useUpdateProjectInfoMutation';
import { EPlatform } from 'modules/projects/const';

export interface IUpdatedProjectData {
  id: string;
  projectName: string;
  description: string;
  isPc: boolean;
  isMac: boolean;
  isLinux: boolean;
  isIos: boolean;
  isAndroid: boolean;
  isWeb: boolean;
  image?: IImageData;
  banner?: IImageData;
  projectType: EProjectTypes;
}

interface IEditProjectFormData {
  isLoading: boolean;
  onSubmit: (data: IUpdatedProjectData) => void;
}

export const useEditProjectForm = (): IEditProjectFormData => {
  const [updateProjectInfo, { isLoading }] = useUpdateProjectInfoMutation();

  const onSubmit = (data: IUpdatedProjectData) => {
    if (
      data.id &&
      data.projectName &&
      data.description &&
      (data.isPc ||
        data.isMac ||
        data.isLinux ||
        data.isIos ||
        data.isAndroid ||
        data.isWeb) &&
      data.image &&
      data.banner &&
      typeof data.projectType === 'number'
    ) {
      let platforms = [];
      if (data.isPc) platforms.push(EPlatform.PC);
      if (data.isMac) platforms.push(EPlatform.MAC);
      if (data.isLinux) platforms.push(EPlatform.LINUX);
      if (data.isIos) platforms.push(EPlatform.IOS);
      if (data.isAndroid) platforms.push(EPlatform.ANDROID);
      if (data.isWeb) platforms.push(EPlatform.WEB);

      updateProjectInfo({
        id: data.id,
        projectName: data.projectName.trim(),
        description: data.description.trim(),
        descriptionLong: data.description.trim(),
        platformTypes: platforms,
        image: data.image.fileUri,
        imageName: data.image.fileName,
        banner: data.banner.fileUri,
        bannerName: data.banner.fileName,
        projectType: data.projectType,
      });
    }
  };

  return {
    isLoading,
    onSubmit,
  };
};
