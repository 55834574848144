import { Box, Button, Stack, Typography } from '@mui/material';
import MuiLink from '@mui/material/Link';
import { format } from 'date-fns';
import { SHORT_DATE_FORMAT } from 'modules/common/const';
import { ReactComponent as EditIcon } from 'uiKit/icons/edit.svg';
import { ReactComponent as EllipseIcon } from 'uiKit/icons/ellipse.svg';
import { ReactComponent as ShareIcon } from 'uiKit/icons/share.svg';
import { t } from 'modules/utils/intl';
import { useHeaderStyles } from './useHeaderStyles';
import { NavLink } from 'react-router-dom';
import { ProjectIcons } from 'modules/nft-manager/components/ProjectIcons';
import { Dialog } from 'modules/common/components/Dialog';
import { SelectTokenStandart } from 'modules/nft-manager/components/SelectTokenStandart';
import { useDialog } from 'modules/common/hooks';

interface IHeaderProps {
  banner: string;
  logo: string;
  name: string;
  createdDate: string;
  lastEditedDate: string;
  project?: string;
  editLink: string;
  addNftLink: string;
  marketplaceLink: string;
}

export const Header = ({
  banner,
  logo,
  name,
  createdDate,
  lastEditedDate,
  project: projects,
  editLink,
  addNftLink,
  marketplaceLink,
}: IHeaderProps): JSX.Element => {
  const { classes } = useHeaderStyles();
  const { isOpened, onOpen, onClose } = useDialog();

  return (
    <Box className={classes.root}>
      <Box
        className={classes.img}
        style={{ backgroundImage: `url(${banner})` }}
      />

      <Box className={classes.wrap}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={11}
        >
          <img alt="" className={classes.logo} src={logo} />

          <NavLink to={editLink} className={classes.link}>
            <Box display="flex">
              <EditIcon className={classes.icon} />
              <Typography variant="body1">
                {t('nft.collection-info.edit-collection')}
              </Typography>
            </Box>
          </NavLink>
        </Box>
        <Typography variant="h1" className={classes.title}>
          {name}
        </Typography>
        <Box display="flex" alignItems="center" height={28}>
          <Stack
            gap={2}
            direction="row"
            alignItems="center"
            divider={<EllipseIcon className={classes.ellipse} />}
          >
            <Box display="flex" alignItems="center">
              <Typography
                variant="captionAll"
                className={classes.detailName}
                mr={1}
              >
                {t('nft.collection-info.created')}
              </Typography>
              <Typography variant="captionAll">
                {format(new Date(createdDate), SHORT_DATE_FORMAT)}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center">
              <Typography
                variant="captionAll"
                className={classes.detailName}
                mr={1}
              >
                {t('nft.collection-info.last-edited')}
              </Typography>
              <Typography variant="captionAll">
                {format(new Date(lastEditedDate), SHORT_DATE_FORMAT)}
              </Typography>
            </Box>

            {!!projects && (
              <Box display="flex" alignItems="center">
                <Typography
                  variant="captionAll"
                  className={classes.detailName}
                  mr={2}
                >
                  {t('nft.collection-info.projects')}
                </Typography>
                <ProjectIcons projects={[projects]} maxShowing={4} />
              </Box>
            )}
          </Stack>
        </Box>
        <Box className={classes.buttons}>
          <Button
            variant="outlined"
            className={classes.button}
            onClick={onOpen}
          >
            {t('nft.collection-info.add-nft')}
          </Button>
          <MuiLink
            href={marketplaceLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button className={classes.marketLink}>
              <Typography component="span">
                {t('nft.collections-list.view-on-marketplace')}
              </Typography>
              <ShareIcon className={classes.shareIcon} />
            </Button>
          </MuiLink>
        </Box>

        <Dialog
          isBigSize
          title={t('nft.erc-standart-select.title')}
          open={isOpened}
          onClose={onClose}
        >
          <SelectTokenStandart />
        </Dialog>
      </Box>
    </Box>
  );
};
