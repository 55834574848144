import { Box, ButtonBase, Input, Select, Typography } from '@mui/material';
import { ReactText } from 'modules/common/types';
import { t } from 'modules/utils/intl';
import { ReactComponent as CloseIcon } from 'uiKit/icons/close-small.svg';
import { ReactComponent as SearchIcon } from 'uiKit/icons/search.svg';
import { ReactComponent as CardsVewIcon } from 'uiKit/icons/cards-view.svg';
import { ReactComponent as ListViewIcon } from 'uiKit/icons/list-view.svg';
import { useListHeaderStyles } from './useListHeaderStyles';
import { featuresConfig } from 'modules/common/featuresConfig';

interface IListHeaderProps {
  nftsCount: number;
  filter: string;
  sort: string;
  searchValue: ReactText;
  isCardView: boolean;
  handleChangeView: () => void;
  setSearch: (value: ReactText) => void;
}

export const ListHeader = ({
  nftsCount,
  filter,
  sort,
  searchValue,
  isCardView,
  handleChangeView,
  setSearch,
}: IListHeaderProps): JSX.Element => {
  const { classes, cx } = useListHeaderStyles();

  return (
    <>
      <Typography variant="h3" className={classes.title}>
        {t('nft.collection-info.nfts', {
          value: nftsCount,
        })}
      </Typography>
      {featuresConfig.isAvailableNftFilters && (
        <Box display="flex" justifyContent="space-between" mb={8}>
          <Box display="flex" alignItems="center">
            <Select
              className={cx(classes.select, classes.mr5)}
              value={filter}
            ></Select>
            <Select
              className={cx(classes.select, classes.mr5)}
              value={sort}
            ></Select>
            <ButtonBase
              onClick={handleChangeView}
              disabled={isCardView}
              className={cx(
                classes.viewButton,
                classes.mr2,
                isCardView && classes.activeView,
              )}
            >
              <CardsVewIcon />
            </ButtonBase>
            <ButtonBase
              onClick={handleChangeView}
              disabled={!isCardView}
              className={cx(
                classes.viewButton,
                classes.mr2,
                !isCardView && classes.activeView,
              )}
            >
              <ListViewIcon />
            </ButtonBase>
          </Box>

          <div className={classes.inputWrapper}>
            <SearchIcon />
            <Input
              className={classes.input}
              value={searchValue}
              inputProps={{ maxLength: 30 }}
              onChange={e => setSearch(e.target.value)}
              placeholder={t('nft.collection-info.search-field')}
              endAdornment={
                searchValue && (
                  <ButtonBase onClick={() => setSearch('')}>
                    <CloseIcon className={classes.closeIcon} />
                  </ButtonBase>
                )
              }
            />
          </div>
        </Box>
      )}
    </>
  );
};
