import { push } from '@lagunovsky/redux-react-router';
import {
  COMMON_ERROR,
  configFromEnv,
  GET_PROJECTS_LIST_URL,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
} from '../../api';
import { EProjectTypes, RESPONSE_CODES } from '../../common/types';
import { EPlatform, ProjectCacheTags } from '../const';
import { RoutesConfig } from '../Routes';
import { oryLoginAsync } from 'modules/auth';
import { IImageData } from '../types';
import { IMemberItem } from './useGetProjectInfoQuery';

const {
  gatewayConfig: { gamingUrl },
} = configFromEnv();

interface INewProject {
  projectName: string;
  description: string;
  descriptionLong: string | null;
  image: string;
  imageName: string;
  banner: string;
  bannerName: string;
  organizationId: string;
  platformTypes: EPlatform[];
  gameProjectTypes: EProjectTypes[];
  gameProjectLinks: unknown | null;
}

interface IResponse {
  id: string;
  userId: string;
  projectName: string;
  description: string;
  image?: IImageData;
  banner?: IImageData;
  platformType: EPlatform;
  projectType: EProjectTypes;
  groupId: string | null;
  contracts: unknown[];
  members: IMemberItem[] | null;
  clientSecret: {
    clientId: string;
    clientSecret: string;
  };
}

export const { useAddNewProjectMutation } = web3Api.injectEndpoints({
  endpoints: build => ({
    addNewProject: build.mutation<IResponse, INewProject>({
      queryFn: queryFnNotifyWrapper<INewProject, never, IResponse>(
        async (data: INewProject) => {
          const request = await fetch(gamingUrl + GET_PROJECTS_LIST_URL, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
              'Content-Type': 'application/json;charset=utf-8',
            }),
            credentials: 'include',
          });

          if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
            await oryLoginAsync();
            throw UNAUTHORIZED_ERROR;
          }
          if (!request.ok) {
            throw COMMON_ERROR;
          }

          return { data: await request.json() };
        },
      ),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        return queryFulfilled.then(async ({ data }) => {
          dispatch(push(RoutesConfig.project.generatePath(data.id)));
          // TODO: Simple notification that project was created
        });
      },
      invalidatesTags: [ProjectCacheTags.commonList],
    }),
  }),
});
