import {
  Box,
  ButtonBase,
  Divider,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { NftMintNetwork } from 'modules/common/types';
import { t, tHTML } from 'modules/utils/intl';
import { FileUploader } from 'react-drag-drop-files';
import { ReactComponent as UploadIcon } from 'modules/common/assets/upload.svg';
import { ReactComponent as DoneIcon } from 'uiKit/icons/done-circle.svg';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { ReactComponent as PlusIcon } from 'uiKit/icons/plus.svg';
import { ReactComponent as TrashIcon } from 'uiKit/icons/trash.svg';
import { INewNftData, useMintNftForm } from './useMintNftForm';
import { useMintNftStyles } from './useMintNftStyles';
import { CollectionItem } from './components/CollectionItem';
import { ReactComponent as EmptyImage } from './assets/empty-image.svg';
import { IAttribute, TokenStandart } from 'modules/nft-manager/types';
import { uid } from 'react-uid';
import { NetworkItem } from './components/NetworkItem';
import { notify } from 'modules/common/components/Notifications';
import { NavLink } from 'react-router-dom';
import { RoutesConfig } from 'modules/nft-manager/Routes';
import { NetworkOrTokenIcon } from 'modules/common/components/NetworkOrTokenIcon';
import { LoaderCentered } from 'modules/common/components/Loader';
import { Button } from 'uiKit/components/Button';
import { Dialog } from 'modules/common/components/Dialog';
import { SuccessMint } from './components/SuccessMint';
import { IMAGE_MAX_SIZE_MB, ZERO_ADDRESS } from 'modules/common/const';

const FILE_TYPES = ['JPG', 'JPEG', 'PNG'];

const EMPTY_ATTRIBUTE: IAttribute = {
  name: '',
  value: '',
  id: '',
};

export const MintNft = (): JSX.Element => {
  const { classes, cx } = useMintNftStyles();
  const {
    isLoading,
    isMintLoading,
    token,
    collections,
    nftMintedData,
    isOpenedSuccessModal,
    isPublishOnPlazaLoading,
    onCloseSuccessModal,
    handleClickLaunch,
    onSubmit,
  } = useMintNftForm();

  const {
    control,
    formState: { isValid },
    handleSubmit,
    setValue,
  } = useForm<INewNftData>({
    defaultValues: {
      name: '',
      description: '',
      image: undefined,
      network: undefined,
      isEth: false,
      isBsc: false,
      isPolygon: false,
      isArb: false,
      collectionId: undefined,
      attributes: [
        {
          id: '',
          name: '',
          value: '',
        },
      ],
      royalty: 0,
      instances: 1,
      tokenId: 0,
      isExplicitAndSensitiveContent: false,
    },
  });

  const {
    fields: fieldsAttributes,
    append: appendAttributes,
    remove: removeAttributes,
  } = useFieldArray({ control, name: 'attributes' });

  const handleDeleteImage = (): void => {
    setValue('image', undefined);
  };

  const onPrepareSubmit = (data: INewNftData): void => {
    if (!data.network) {
      notify({
        message: tHTML('nft.mint-nft.choose-one-network'),
        type: 'error',
        key: uid('networks'),
      });
      return;
    }

    if (isValid) {
      onSubmit(data);
    }
  };

  if (isLoading) return <LoaderCentered />;

  return (
    <>
      <form
        onSubmit={handleSubmit(onPrepareSubmit)}
        noValidate
        className={classes.root}
      >
        <Box width={544} minWidth={544}>
          <Typography variant="h2" mb={1}>
            {t('nft.mint-nft.title')}
          </Typography>
          <Typography variant="h3" color="textSecondary" mb={10}>
            {token}
          </Typography>

          <Typography variant="h3" mb={6}>
            {t('nft.mint-nft.details')}
          </Typography>
          <Controller
            name="name"
            control={control}
            rules={{
              required: t('validation.required'),
            }}
            render={({ field, fieldState }) => {
              const errorText = fieldState.error?.message;
              return (
                <FormControl className={classes.fullWidth}>
                  <div className={cx(classes.fullWidth, classes.mb8)}>
                    <InputLabel>{t('nft.mint-nft.name')}</InputLabel>
                    <Input
                      {...field}
                      fullWidth
                      required
                      error={!!fieldState.error}
                      // inputProps={{ maxLength: 64 }}
                    />
                    {!!errorText && (
                      <FormHelperText error>{errorText}</FormHelperText>
                    )}
                  </div>
                </FormControl>
              );
            }}
          />

          {token === TokenStandart.ERC1155 && (
            <Controller
              name="tokenId"
              control={control}
              rules={{
                required: t('validation.required'),
              }}
              render={({ field, fieldState }) => {
                const errorText = fieldState.error?.message;
                return (
                  <FormControl className={classes.fullWidth}>
                    <div className={cx(classes.fullWidth, classes.mb8)}>
                      <InputLabel>{t('nft.mint-nft.token-id')}</InputLabel>
                      <Input
                        {...field}
                        fullWidth
                        required
                        error={!!fieldState.error}
                        inputProps={{ maxLength: 40, min: 0 }}
                      />
                      {!!errorText && (
                        <FormHelperText error>{errorText}</FormHelperText>
                      )}
                    </div>
                  </FormControl>
                );
              }}
            />
          )}

          <Controller
            name="description"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  rows={8}
                  helperText={t('nft.mint-nft.characters-number', {
                    value: field.value?.length ?? 0,
                  })}
                  label={t('nft.mint-nft.description')}
                  error={!!fieldState.error}
                  inputProps={{ maxLength: 1_000 }}
                  className={cx(classes.mb12, classes.textfield)}
                />
              );
            }}
          />

          <Typography variant="h3" mb={1.5}>
            {t('nft.mint-nft.upload-file')}
          </Typography>
          <Typography mb={6} variant="body1" color="textSecondary">
            {tHTML('nft.mint-nft.file-formats')}
          </Typography>
          <Controller
            name="image"
            control={control}
            rules={{
              required: t('validation.required'),
            }}
            render={({ field, fieldState }) => {
              const errorText = fieldState.error?.message;
              if (!!field.value) {
                return (
                  <>
                    <img
                      alt=""
                      src={URL.createObjectURL(field.value)}
                      className={cx(classes.imageSize, classes.mb4)}
                    />

                    <Box display="flex" mb={12} alignItems="center">
                      <Typography variant="captionAll" component="p" mr={2}>
                        {field.value.name}
                      </Typography>

                      <Typography
                        variant="body2"
                        component="p"
                        className={classes.changeImageText}
                        onClick={handleDeleteImage}
                      >
                        {t('nft.mint-nft.change-file')}
                      </Typography>
                    </Box>
                  </>
                );
              }

              return (
                <>
                  <div
                    className={cx(
                      classes.uploaderWrapper,
                      classes.imageSize,
                      !errorText && classes.mb4,
                      !!errorText && classes.errorUploderWrapper,
                    )}
                  >
                    <FileUploader
                      multiple={false}
                      maxSize={IMAGE_MAX_SIZE_MB}
                      name="logo"
                      types={FILE_TYPES}
                      handleChange={field.onChange}
                    >
                      <UploadIcon />
                    </FileUploader>
                  </div>
                  <FormHelperText error>{errorText}</FormHelperText>
                  <Typography
                    variant="captionAll"
                    component="p"
                    className={classes.mb12}
                    color="textSecondary"
                  >
                    {t('nft.mint-nft.not-uploaded')}
                  </Typography>
                </>
              );
            }}
          />

          <Typography variant="h3" mb={6}>
            {t('nft.mint-nft.choose-blockchain')}
          </Typography>

          <Controller
            name="network"
            control={control}
            rules={{
              required: t('validation.required'),
            }}
            render={({ field, fieldState }) => {
              return (
                <RadioGroup onChange={field.onChange}>
                  <NetworkItem
                    isActive={field.value === NftMintNetwork.SepETH}
                    network={NftMintNetwork.SepETH}
                    networkName={t('nft.mint-nft.eth')}
                    className={classes.mb5}
                    isError={!!fieldState.error}
                  />
                  <NetworkItem
                    isActive={field.value === NftMintNetwork.BSC}
                    network={NftMintNetwork.BSC}
                    networkName={t('nft.mint-nft.bsc')}
                    className={classes.mb5}
                    isError={!!fieldState.error}
                  />
                  <NetworkItem
                    isActive={field.value === NftMintNetwork.Polygon}
                    network={NftMintNetwork.Polygon}
                    networkName={t('nft.mint-nft.polygon')}
                    className={classes.mb5}
                    isError={!!fieldState.error}
                  />
                  <NetworkItem
                    isActive={field.value === NftMintNetwork.ARB}
                    network={NftMintNetwork.ARB}
                    networkName={t('nft.mint-nft.arbitrum')}
                    className={classes.mb12}
                    isError={!!fieldState.error}
                  />
                </RadioGroup>
              );
            }}
          />
          {/* <Controller
            name="isEth"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <NetworkItem
                  isActive={field.value}
                  network={NftMintNetwork.SepETH}
                  networkName={t('nft.mint-nft.eth')}
                  className={classes.mb5}
                />
              );
            }}
          />
          <Controller
            name="isBsc"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <NetworkItem
                  isActive={field.value}
                  network={NftMintNetwork.BSC}
                  networkName={t('nft.mint-nft.bsc')}
                  className={classes.mb5}
                />
              );
            }}
          />
          <Controller
            name="isPolygon"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <NetworkItem
                  isActive={field.value}
                  network={NftMintNetwork.TestnetPolygon}
                  networkName={t('nft.mint-nft.polygon')}
                  className={classes.mb12}
                />
              );
            }}
          /> */}

          <Typography variant="h3" mb={6}>
            {t('nft.mint-nft.choose-collection')}
          </Typography>
          <Controller
            name="collectionId"
            control={control}
            rules={{
              required: t('validation.required'),
            }}
            render={({ field, fieldState }) => {
              return (
                <RadioGroup onChange={field.onChange}>
                  {collections.map(collection => (
                    <CollectionItem
                      key={uid(collection)}
                      isActive={collection.id === field.value}
                      collection={collection}
                      isError={!!fieldState.error}
                    />
                  ))}
                </RadioGroup>
              );
            }}
          />
          <NavLink to={RoutesConfig.createCollection.generatePath()}>
            <Button variant="outlined" className={classes.mb12}>
              {t('nft.mint-nft.create-new-collection')}
            </Button>
          </NavLink>

          <Typography variant="h3" mb={1.5}>
            {t('nft.mint-nft.attributes')}
          </Typography>
          <Typography
            variant="body1"
            className={classes.mb6}
            color="textSecondary"
          >
            {tHTML('nft.mint-nft.attributes-desc')}
          </Typography>

          <Box mb={7}>
            {fieldsAttributes.map((attribute, index) => (
              <div key={uid(attribute)} className={classes.attributeRoot}>
                <Controller
                  control={control}
                  name={`attributes.${index}.name`}
                  render={({ field }) => {
                    return (
                      <FormControl>
                        <InputLabel>
                          {t('nft.mint-nft.enter-attribute')}
                        </InputLabel>
                        <Input
                          {...field}
                          className={classes.input}
                          inputProps={{ maxLength: 50 }}
                        />
                      </FormControl>
                    );
                  }}
                />
                <Controller
                  control={control}
                  name={`attributes.${index}.value`}
                  render={({ field }) => {
                    return (
                      <FormControl>
                        <InputLabel>
                          {t('nft.mint-nft.attribute-placeholder')}
                        </InputLabel>
                        <Input
                          {...field}
                          className={classes.input}
                          inputProps={{ maxLength: 50 }}
                        />
                      </FormControl>
                    );
                  }}
                />
                {fieldsAttributes.length > 1 && (
                  <ButtonBase
                    className={classes.removeAttributeButton}
                    onClick={() => removeAttributes(index)}
                  >
                    <TrashIcon />
                  </ButtonBase>
                )}
              </div>
            ))}
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="h3" mb={1.5}>
              {t('nft.mint-nft.royalty')}
            </Typography>
            <Typography
              variant="body1"
              className={classes.mb6}
              color="textSecondary"
            >
              {t('nft.mint-nft.royalty-desc')}
            </Typography>
            <Controller
              name="royalty"
              control={control}
              rules={{
                required: t('validation.required'),
              }}
              render={({ field, fieldState }) => {
                const errorText = fieldState.error?.message;
                return (
                  <FormControl>
                    <div className={classes.mb8}>
                      <InputLabel>
                        {t('nft.mint-nft.royalty-placeholder')}
                      </InputLabel>
                      <Input
                        {...field}
                        fullWidth
                        required
                        type="number"
                        error={!!fieldState.error}
                        inputProps={{ maxLength: 5, min: 0, max: 50 }}
                        onChange={field.onChange}
                      />
                      {!!errorText && (
                        <FormHelperText error>{errorText}</FormHelperText>
                      )}
                    </div>
                  </FormControl>
                );
              }}
            />
          </Box>
          <Button
            variant="outlined"
            className={classes.mb12}
            onClick={() => appendAttributes(EMPTY_ATTRIBUTE)}
          >
            <PlusIcon className={classes.plusIcon} />
            {t('nft.mint-nft.add-more')}
          </Button>

          {token === TokenStandart.ERC1155 && (
            <>
              <Typography variant="h3" mb={1.5}>
                {t('nft.mint-nft.instances')}
              </Typography>
              <Typography
                variant="body1"
                className={classes.mb6}
                color="textSecondary"
              >
                {t('nft.mint-nft.instances-desc')}
              </Typography>
              <Controller
                name="instances"
                control={control}
                rules={{
                  required: t('validation.required'),
                }}
                render={({ field, fieldState }) => {
                  const errorText = fieldState.error?.message;
                  return (
                    <FormControl className={classes.fullWidth}>
                      <div className={classes.mb8}>
                        <InputLabel>{t('nft.mint-nft.amount')}</InputLabel>
                        <Input
                          {...field}
                          fullWidth
                          required
                          type="number"
                          error={!!fieldState.error}
                          inputProps={{ maxLength: 5, min: 1 }}
                        />
                        {!!errorText && (
                          <FormHelperText error>{errorText}</FormHelperText>
                        )}
                      </div>
                    </FormControl>
                  );
                }}
              />
            </>
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={4}
          >
            <Typography variant="h3" mb={1.5}>
              {t('nft.mint-nft.explicit-content')}
            </Typography>

            <Controller
              name="isExplicitAndSensitiveContent"
              control={control}
              render={({ field }) => {
                return <Switch value={field.value} />;
              }}
            />
          </Box>
          <Divider className={classes.mb3} />
          <Typography
            variant="body1"
            className={classes.mb4}
            color="textSecondary"
          >
            {tHTML('nft.mint-nft.explicit-content-desc')}
          </Typography>
        </Box>
        <Box mt={16}>
          <Typography variant="h3" mb={6}>
            {t('nft.mint-nft.preview')}
          </Typography>
          <Box className={classes.previewRoot} mb={12}>
            <Controller
              name="image"
              control={control}
              render={({ field }) => {
                if (!!field.value) {
                  return (
                    <img
                      alt=""
                      src={URL.createObjectURL(field.value)}
                      className={classes.previewImage}
                    />
                  );
                }
                return (
                  <div className={classes.previewImage}>
                    <EmptyImage />
                  </div>
                );
              }}
            />
            <Box
              p={5}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Controller
                name="name"
                control={control}
                render={({ field }) => {
                  return <Typography variant="h3">{field.value}</Typography>;
                }}
              />

              <Box display="flex">
                <Controller
                  name="isPolygon"
                  control={control}
                  render={({ field }) => {
                    if (field.value) {
                      return (
                        <NetworkOrTokenIcon
                          className={classes.smallNetworkIcon}
                          name={NftMintNetwork.Polygon}
                        />
                      );
                    }
                    return <></>;
                  }}
                />
                {/* <Controller
                name="isBsc"
                control={control}
                render={({ field }) => {
                  if (field.value) {
                    return (
                      <NetworkOrTokenIcon
                        className={classes.smallNetworkIcon}
                        name={Network.Bsc}
                      />
                    );
                  }
                  return <></>;
                }}
              /> */}
                <Controller
                  name="isEth"
                  control={control}
                  render={({ field }) => {
                    if (field.value) {
                      return (
                        <NetworkOrTokenIcon
                          className={classes.smallNetworkIcon}
                          name={NftMintNetwork.SepETH}
                        />
                      );
                    }
                    return <></>;
                  }}
                />
                <Controller
                  name="isArb"
                  control={control}
                  render={({ field }) => {
                    if (field.value) {
                      return (
                        <NetworkOrTokenIcon
                          className={classes.smallNetworkIcon}
                          name={NftMintNetwork.ARB}
                        />
                      );
                    }
                    return <></>;
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Button
            size="large"
            isLoading={isMintLoading}
            onClick={handleSubmit(onPrepareSubmit)}
          >
            {t('nft.mint-nft.mint')}
          </Button>
        </Box>
      </form>

      <Dialog
        isDisabledBackdropClick
        title={t('nft.success-mint.title')}
        titleIcon={<DoneIcon className={classes.doneIcon} />}
        open={isOpenedSuccessModal}
        onClose={onCloseSuccessModal}
      >
        <SuccessMint
          isLoading={isPublishOnPlazaLoading}
          collectionId={nftMintedData?.collectionId ?? ''}
          address={nftMintedData?.address ?? ZERO_ADDRESS}
          name={nftMintedData?.name ?? 'default'}
          quantity={nftMintedData?.quantity ?? null}
          fee={nftMintedData?.fee ?? 2}
          imageSrc={nftMintedData?.imageSrc ?? ''}
          tokenId={nftMintedData?.tokenId ?? 'default'}
          network={nftMintedData?.network ?? NftMintNetwork.SepETH}
          handleClickLaunch={handleClickLaunch}
        />
      </Dialog>
    </>
  );
};
