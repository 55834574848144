import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { t } from 'modules/utils/intl';
import BGIcon from './assets/bg.png';
import { useEmptyStyles } from './useEmptyStyles';
import { RoutesConfig } from 'modules/smart-contracts/Routes';
import { ReactComponent as PlusIcon } from 'uiKit/icons/plus.svg';

export const Empty = (): JSX.Element => {
  const { classes } = useEmptyStyles();

  return (
    <div className={classes.root}>
      <div className={classes.imageWrapper}>
        <img alt="" className={classes.image} src={BGIcon} />
      </div>
      <div className={classes.content}>
        <div>
          <Typography variant="h1">
            {t('smart-contracts.manager.empty.start-with')}
          </Typography>
          <Typography>
            {t('smart-contracts.manager.empty.first-contract')}
          </Typography>
        </div>
        <NavLink
          className={classes.button}
          to={RoutesConfig.upload.generatePath()}
        >
          <Button size="large" variant="outlined">
            <PlusIcon className={classes.icon} />
            <Typography variant="body1">
              {t('smart-contracts.manager.empty.publish')}
            </Typography>
          </Button>
        </NavLink>
      </div>
    </div>
  );
};
