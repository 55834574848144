import { Box, ButtonBase, Input, Select, Typography } from '@mui/material';
import { featuresConfig } from 'modules/common/featuresConfig';
import { ReactText } from 'modules/common/types';
import { t } from 'modules/utils/intl';
import { ReactComponent as CloseIcon } from 'uiKit/icons/close-small.svg';
import { ReactComponent as SearchIcon } from 'uiKit/icons/search.svg';
import { useListHeaderStyles } from './useListHeaderStyles';

interface IListHeaderProps {
  collectionsCount: number;
  filter: string;
  sort: string;
  searchValue: ReactText;
  setSearch: (value: ReactText) => void;
}

export const ListHeader = ({
  collectionsCount,
  filter,
  sort,
  searchValue,
  setSearch,
}: IListHeaderProps): JSX.Element => {
  const { classes } = useListHeaderStyles();

  return (
    <>
      <Typography variant="h3" className={classes.title}>
        {t('nft.collections-list.collections', {
          value: collectionsCount,
        })}
      </Typography>
      {featuresConfig.isAvailableNftFilters && (
        <Box display="flex" justifyContent="space-between" mb={8}>
          <Box display="flex">
            <Select className={classes.select} value={filter}></Select>
            <Select className={classes.select} value={sort}></Select>
          </Box>

          <div className={classes.inputWrapper}>
            <SearchIcon />
            <Input
              className={classes.input}
              value={searchValue}
              inputProps={{ maxLength: 30 }}
              onChange={e => setSearch(e.target.value)}
              placeholder={t('nft.collections-list.search-field')}
              endAdornment={
                searchValue && (
                  <ButtonBase onClick={() => setSearch('')}>
                    <CloseIcon className={classes.closeIcon} />
                  </ButtonBase>
                )
              }
            />
          </div>
        </Box>
      )}
    </>
  );
};
