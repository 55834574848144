import { makeStyles } from 'tss-react/mui';

export const useUnexistsMetamaskStyles = makeStyles()(theme => ({
  root: {
    padding: 0,
    backgroundColor: theme.palette.violet[10],
  },

  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
    height: 220,
  },

  image: {
    height: 207,
  },

  closeButton: {
    top: theme.spacing(6),
    right: theme.spacing(6),
  },

  body: {
    padding: theme.spacing(0, 8, 0, 8),
    height: 276,
    backgroundColor: theme.palette.background.paper,
  },
}));
