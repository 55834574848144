import {
  FormControl,
  Input,
  InputLabel,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { uid } from 'react-uid';
import { Controller, useForm } from 'react-hook-form';
import { t, tHTML } from 'modules/utils/intl';
import { ReactComponent as UploadIcon } from 'modules/common/assets/upload.svg';
import { Button } from 'uiKit/components/Button/index';
import {
  INewProjectForm,
  useAddingProjectForm,
} from './hooks/useAddingProjectForm';
import { useAddingProjectStyles } from './useAddingProjectStyles';
import { projectTypesNaming } from 'modules/common/types';
import { SelectItem } from 'uiKit/components/SelectItem';
import { isEmptyOrSpaces } from 'modules/common/utils/isEmptyOrSpaces';
import { FileUploader } from 'react-drag-drop-files';
import { PlatformItem } from './components/PlatformItem';
import { EPlatform } from 'modules/projects/const';
import { IMAGE_MAX_SIZE_MB } from 'modules/common/const';

const FILE_TYPES = ['JPG', 'JPEG', 'PNG'];

export const AddingProject = (): JSX.Element => {
  const { classes, cx } = useAddingProjectStyles();
  const { isLoading, onSubmit } = useAddingProjectForm();

  const {
    control,
    formState: { isValid },
    handleSubmit,
    getValues,
  } = useForm<INewProjectForm>({
    defaultValues: {
      name: '',
      description: '',
      isPc: false,
      isMac: false,
      isLinux: false,
      isIos: false,
      isAndroid: false,
      isWeb: false,
      type: '',
      image: undefined,
      banner: undefined,
      isTestMode: false,
      isIosConnected: undefined,
      isAndroidConnected: undefined,
      isWebglConnected: undefined,
    },
  });

  const onPrepareSubmit = (data: INewProjectForm): void => {
    if (isValid) {
      onSubmit(data);
    }
  };

  return (
    <form
      className={classes.root}
      onSubmit={handleSubmit(onPrepareSubmit)}
      noValidate
    >
      <Typography variant="h2" className={classes.header}>
        {t('projects.adding.title')}
      </Typography>

      <Typography variant="h3" className={classes.mb6}>
        {t('projects.adding.general-info')}
      </Typography>

      <Controller
        name="name"
        control={control}
        rules={{
          required: t('validation.required'),
          validate: v => {
            return !isEmptyOrSpaces(v) || t('validation.required');
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl>
              <InputLabel>{t('projects.adding.project-name')}</InputLabel>
              <Input
                {...field}
                fullWidth
                error={!!fieldState.error}
                inputProps={{ maxLength: 50 }}
                className={classes.mb2}
                onChange={field.onChange}
              />
            </FormControl>
          );
        }}
      />

      <Controller
        name="type"
        control={control}
        rules={{
          required: t('validation.required'),
          validate: v => {
            return v !== '' || t('validation.required');
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl fullWidth>
              <InputLabel>{t('projects.adding.type-of-app')}</InputLabel>
              <Select
                fullWidth
                error={!!fieldState.error}
                value={field.value}
                className={classes.mb6}
                onChange={field.onChange}
              >
                {Object.entries(projectTypesNaming).map(item => (
                  <SelectItem
                    itemValue={+item[0]}
                    value={+item[0]}
                    key={uid(item)}
                    itemText={item[1]}
                    selectValue={field.value}
                  >
                    {item[1]}
                  </SelectItem>
                ))}
              </Select>
            </FormControl>
          );
        }}
      />

      <Controller
        name="description"
        control={control}
        rules={{
          required: t('validation.required'),
          validate: v => {
            return !isEmptyOrSpaces(v) || t('validation.required');
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <TextField
              {...field}
              fullWidth
              multiline
              rows={8}
              helperText={t('projects.adding.characters-number', {
                value: field.value?.length ?? 0,
              })}
              label={t('projects.adding.description')}
              error={!!fieldState.error}
              inputProps={{ maxLength: 1_000 }}
              className={cx(classes.mb6, classes.textfield)}
              onChange={field.onChange}
            />
          );
        }}
      />

      <>
        <Typography variant="h3" className={classes.mb6}>
          {t('projects.adding.connected-platform')}
        </Typography>

        <Controller
          name="isPc"
          control={control}
          rules={{
            validate: () => {
              const values = getValues();
              return (
                !!values.isPc ||
                !!values.isMac ||
                !!values.isLinux ||
                !!values.isIos ||
                !!values.isAndroid ||
                !!values.isWeb ||
                t('validation.required')
              );
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <PlatformItem
                value={EPlatform.PC}
                isActive={field.value}
                isError={!!fieldState.error}
                onChange={field.onChange}
              />
            );
          }}
        />
        <Controller
          name="isMac"
          control={control}
          rules={{
            validate: () => {
              const values = getValues();
              return (
                !!values.isPc ||
                !!values.isMac ||
                !!values.isLinux ||
                !!values.isIos ||
                !!values.isAndroid ||
                !!values.isWeb ||
                t('validation.required')
              );
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <PlatformItem
                value={EPlatform.MAC}
                isActive={field.value}
                isError={!!fieldState.error}
                onChange={field.onChange}
              />
            );
          }}
        />
        <Controller
          name="isLinux"
          control={control}
          rules={{
            validate: () => {
              const values = getValues();
              return (
                !!values.isPc ||
                !!values.isMac ||
                !!values.isLinux ||
                !!values.isIos ||
                !!values.isAndroid ||
                !!values.isWeb ||
                t('validation.required')
              );
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <PlatformItem
                value={EPlatform.LINUX}
                isActive={field.value}
                isError={!!fieldState.error}
                onChange={field.onChange}
              />
            );
          }}
        />
        <Controller
          name="isIos"
          control={control}
          rules={{
            validate: () => {
              const values = getValues();
              return (
                !!values.isPc ||
                !!values.isMac ||
                !!values.isLinux ||
                !!values.isIos ||
                !!values.isAndroid ||
                !!values.isWeb ||
                t('validation.required')
              );
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <PlatformItem
                value={EPlatform.IOS}
                isActive={field.value}
                isError={!!fieldState.error}
                onChange={field.onChange}
              />
            );
          }}
        />
        <Controller
          name="isAndroid"
          control={control}
          rules={{
            validate: () => {
              const values = getValues();
              return (
                !!values.isPc ||
                !!values.isMac ||
                !!values.isLinux ||
                !!values.isIos ||
                !!values.isAndroid ||
                !!values.isWeb ||
                t('validation.required')
              );
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <PlatformItem
                value={EPlatform.ANDROID}
                isActive={field.value}
                isError={!!fieldState.error}
                onChange={field.onChange}
              />
            );
          }}
        />
        <Controller
          name="isWeb"
          control={control}
          rules={{
            validate: () => {
              const values = getValues();
              return (
                !!values.isPc ||
                !!values.isMac ||
                !!values.isLinux ||
                !!values.isIos ||
                !!values.isAndroid ||
                !!values.isWeb ||
                t('validation.required')
              );
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <PlatformItem
                value={EPlatform.WEB}
                isActive={field.value}
                isError={!!fieldState.error}
                onChange={field.onChange}
              />
            );
          }}
        />
      </>

      <Typography variant="h3" className={classes.mb05}>
        {t('projects.adding.logo-title')}
      </Typography>
      <Typography className={classes.mb2}>
        {t('projects.adding.logo-size')}
      </Typography>
      <Typography variant="body1" className={classes.mb5} color="textSecondary">
        {t('projects.adding.logo-description')}
      </Typography>
      <Controller
        name="image"
        control={control}
        rules={{
          required: t('validation.required'),
        }}
        render={({ field, fieldState }) => {
          const errorText = fieldState.error?.message;
          if (!!field.value) {
            return (
              <img
                alt=""
                src={URL.createObjectURL(field.value)}
                className={cx(classes.logoWrapper, classes.mb16)}
              />
            );
          }

          return (
            <>
              <div
                className={cx(
                  classes.uploaderWrapper,
                  classes.logoWrapper,
                  classes.mb4,
                  !!errorText && classes.errorUploderWrapper,
                )}
              >
                <FileUploader
                  multiple={false}
                  maxSize={IMAGE_MAX_SIZE_MB}
                  name="logo"
                  types={FILE_TYPES}
                  handleChange={field.onChange}
                >
                  <UploadIcon />
                </FileUploader>
              </div>
              <Typography
                variant="captionAll"
                className={classes.mb16}
                color="textSecondary"
              >
                {t('nft.create-collection.not-uploaded')}
              </Typography>
            </>
          );
        }}
      />

      <Typography variant="h3" className={classes.mb05}>
        {t('projects.adding.banner-title')}
      </Typography>
      <Typography className={classes.mb2}>
        {t('projects.adding.banner-size')}
      </Typography>
      <Typography variant="body1" className={classes.mb5} color="textSecondary">
        {tHTML('projects.adding.banner-description')}
      </Typography>
      <Controller
        name="banner"
        control={control}
        rules={{
          required: t('validation.required'),
        }}
        render={({ field, fieldState }) => {
          const errorText = fieldState.error?.message;
          if (!!field.value) {
            return (
              <img
                alt=""
                src={URL.createObjectURL(field.value)}
                className={cx(classes.bannerWrapper, classes.mb16)}
              />
            );
          }

          return (
            <>
              <div
                className={cx(
                  classes.uploaderWrapper,
                  classes.bannerWrapper,
                  classes.mb4,
                  !!errorText && classes.errorUploderWrapper,
                )}
              >
                <FileUploader
                  multiple={false}
                  maxSize={IMAGE_MAX_SIZE_MB}
                  name="logo"
                  types={FILE_TYPES}
                  handleChange={field.onChange}
                >
                  <UploadIcon />
                </FileUploader>
              </div>
              <Typography
                variant="captionAll"
                className={classes.mb16}
                color="textSecondary"
              >
                {t('nft.create-collection.not-uploaded')}
              </Typography>
            </>
          );
        }}
      />

      <Button
        isLoading={isLoading}
        type="submit"
        variant="contained"
        size="large"
        className={classes.button}
      >
        {t('projects.adding.save')}
      </Button>
    </form>
  );
};
