import {
  queryFnNotifyWrapper,
  web3Api,
  configFromEnv,
  GET_DRAFT_CV,
  COMMON_ERROR,
  UNAUTHORIZED_ERROR,
} from 'modules/api';
import { oryLoginAsync } from 'modules/auth';
import { Network, RESPONSE_CODES } from 'modules/common/types';
import { CV_STATE, IContractFileItem } from '../../../types';

const {
  gatewayConfig: { gamingUrl },
} = configFromEnv();

interface IDraftCV {
  id: string;
  name: string;
  contractCode: string;
  stateEnum: CV_STATE;
  contractStandart: Network;
  contractFiles: IContractFileItem[];
}

export const { useLazyGetDraftCVQuery } = web3Api.injectEndpoints({
  endpoints: build => ({
    getDraftCV: build.query<IDraftCV | null, void>({
      queryFn: queryFnNotifyWrapper<void, never, IDraftCV | null>(async () => {
        const request = await fetch(gamingUrl + GET_DRAFT_CV, {
          method: 'GET',
          credentials: 'include',
        });

        if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
          await oryLoginAsync();
          throw UNAUTHORIZED_ERROR;
        }
        if (!request.ok) throw COMMON_ERROR;

        return {
          data:
            request.status === RESPONSE_CODES.NO_CONTENT
              ? null
              : await request.json(),
        };
      }),
    }),
  }),
});
