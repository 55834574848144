import { Box, Button, Typography } from '@mui/material';
import { Dialog } from 'modules/common/components/Dialog';
import { useDialog } from 'modules/common/hooks';
import { SelectTokenStandart } from 'modules/nft-manager/components/SelectTokenStandart';
import { RoutesConfig } from 'modules/nft-manager/Routes';
import { t } from 'modules/utils/intl';
import { NavLink } from 'react-router-dom';
import { useHeaderStyles } from './useHeaderStyles';

interface IHeaderProps {
  isEmptyList: boolean;
}

export const Header = ({ isEmptyList }: IHeaderProps): JSX.Element => {
  const { classes, cx } = useHeaderStyles();
  const { isOpened, onOpen, onClose } = useDialog();

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h1">
        {t('nft.collections-list.title')}
      </Typography>
      <Typography className={classes.secondaryColor}>
        {t('nft.collections-list.description')}
      </Typography>

      <Box mt={6}>
        <Button
          variant="contained"
          className={cx(classes.button, classes.mr3)}
          onClick={onOpen}
        >
          {t('nft.collections-list.mint')}
        </Button>
        {!isEmptyList && (
          <NavLink to={RoutesConfig.createCollection.generatePath()}>
            <Button className={classes.button} variant="outlined">
              {t('nft.collections-list.create-collection')}
            </Button>
          </NavLink>
        )}
      </Box>

      <Dialog
        isBigSize
        title={t('nft.erc-standart-select.title')}
        open={isOpened}
        onClose={onClose}
      >
        <SelectTokenStandart />
      </Dialog>
    </div>
  );
};
