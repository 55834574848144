import { makeStyles } from 'tss-react/mui';

export const useAddingProjectStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 544,
    marginBottom: theme.spacing(5),
  },
  verticalAlign: {
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    marginBottom: theme.spacing(10),
  },
  mb05: {
    marginBottom: theme.spacing(0.5),
  },
  mb1: {
    marginBottom: theme.spacing(1),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  mb4: {
    marginBottom: theme.spacing(4),
  },
  mb5: {
    marginBottom: theme.spacing(5),
  },
  mb6: {
    marginBottom: theme.spacing(6),
  },
  mb8: {
    marginBottom: theme.spacing(8),
  },
  mb12: {
    marginBottom: theme.spacing(12.5),
  },
  mb16: {
    marginBottom: theme.spacing(16),
  },
  mb20: {
    marginBottom: theme.spacing(20.5),
  },
  mr2: {
    marginRight: theme.spacing(2),
  },
  ml2: {
    marginLeft: theme.spacing(2),
  },
  icon: {
    height: 40,
    width: 40,
  },
  logoWrapper: {
    width: 100,
    height: 100,
    borderRadius: 16,
  },
  bannerWrapper: {
    width: 544,
    height: 110,
    borderRadius: 16,
  },
  uploaderWrapper: {
    border: '1px dashed #000000',
    borderRadius: 16,

    '& > label': {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  errorUploderWrapper: {
    border: `1px dashed ${theme.palette.error.main}`,
  },
  button: {
    width: 200,
  },
  textfield: {
    '& .MuiInputBase-root': {
      height: 'unset',

      '& .MuiFilledInput-input': {
        padding: theme.spacing(3, 2, 3),
      },
    },
    '& .MuiFormHelperText-root': {
      textAlign: 'right',
      position: 'absolute',
      right: theme.spacing(3),
      bottom: theme.spacing(3),
    },
  },
}));
