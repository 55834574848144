import * as React from 'react';
import { Session } from '@ory/client';
import { oryApi } from 'modules/auth';
import { OrySessionContext } from './context';

export const OryLifecycleProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [session, setSession] = React.useState<Session | undefined>();

  // sorry for the callback hell, useEffect doesn't play well with promises
  React.useEffect(() => {
    oryApi
      .toSession()
      .then(({ data: sessionData }) => {
        console.log('Ory session: ', sessionData.id);
        // User has a session!
        setSession(sessionData);
        // We could initialize a global logout URL here, but it's simpler to create a new flow anytime we need to log out
      })
      .catch(err => {
        console.error('Ory login error: ', err);
        // Redirect to login page
        oryApi
          .createBrowserLoginFlow({ returnTo: window.location.href })
          .then(({ data: loginData }) => {
            window.location.replace(loginData.request_url);
          });
      });
  }, []);

  return (
    <OrySessionContext.Provider value={session}>
      {children}
    </OrySessionContext.Provider>
  );
};
