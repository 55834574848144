import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ProjectCacheTags } from '../projects/const';
import { ContractsManagerCacheTags } from '../smart-contracts/const';

export const web3Api = createApi({
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: '',
  }),
  tagTypes: [
    ...Object.values(ProjectCacheTags),
    ...Object.values(ContractsManagerCacheTags),
  ],
  endpoints: () => ({}),
});
