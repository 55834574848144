import { makeStyles } from 'tss-react/mui';

export const usePublishStyles = makeStyles()(theme => ({
  wrapper: {
    height: '100%',
    width: '100%',
    marginBottom: theme.spacing(4),

    '& > label': {
      height: '100%',
      width: '100%',
    },
  },
  secondColor: {
    color: theme.palette.text.secondary,
  },
  tableWrapper: {
    marginBottom: theme.spacing(10),
  },
  addNetworkButton: {
    width: 40,
    height: 40,
    minWidth: 0,
    padding: 0,
    borderRadius: '50%',
    marginLeft: theme.spacing(1),
  },
}));
