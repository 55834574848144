import {
  COMMON_ERROR,
  configFromEnv,
  GET_PROJECTS_LIST_URL,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
} from '../../api';
import { oryLoginAsync } from 'modules/auth';
import { EProjectTypes, RESPONSE_CODES } from '../../common/types';
import { EPlatform, ProjectCacheTags } from '../const';
import { IImageData } from '../types';

const {
  gatewayConfig: { gamingUrl },
} = configFromEnv();

interface IGetProjectInfoArgs {
  id?: string;
}

export interface IMemberItem {
  id: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  role: string;
}

interface IClientSecret {
  clientId: string;
  clientSecret: string;
}

interface IReponse {
  id: string;
  userId: string;
  projectName: string;
  description: string;
  image?: IImageData;
  banner?: IImageData;
  platformTypes: EPlatform[];
  projectType: EProjectTypes;
  contracts: unknown[];
  members: IMemberItem[] | null;
  clientSecret: IClientSecret | null;
}

export const { useGetProjectInfoQuery } = web3Api.injectEndpoints({
  endpoints: build => ({
    getProjectInfo: build.query<IReponse, IGetProjectInfoArgs>({
      queryFn: queryFnNotifyWrapper<IGetProjectInfoArgs, never, IReponse>(
        async ({ id }: IGetProjectInfoArgs) => {
          const request = await fetch(gamingUrl + GET_PROJECTS_LIST_URL + id, {
            method: 'GET',
            credentials: 'include',
          });

          if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
            await oryLoginAsync();
            throw UNAUTHORIZED_ERROR;
          }
          if (!request.ok) throw COMMON_ERROR;

          return { data: await request.json() };
        },
      ),
      providesTags: (res, err, args) => [
        ProjectCacheTags.projectData + args.id,
      ],
    }),
  }),
});
