import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "./assets/style.css";
import { LoaderCentered } from "./modules/common/components/Loader";
import { AppBase } from "./modules/layout/AppBase";
import { Routes } from "./Routes";
import { persistor, store } from "./store";
import { mainTheme } from "./uiKit/mainTheme";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<LoaderCentered />} persistor={persistor}>
        <ThemeProvider theme={mainTheme}>
          <AppBase>
            <Routes />
          </AppBase>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
