import { makeStyles } from 'tss-react/mui';

export const useCompileErrorsStyles = makeStyles()(theme => ({
  itemRoot: {
    backgroundColor: theme.palette.grey[300],
    wordWrap: 'break-word',
  },
  errorTitle: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: theme.spacing(2),
    },
  },
  errotText: {
    fontFamily: 'Roboto Mono',
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '16px',
    letterSpacing: '-3%',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 652,
    overflowY: 'auto',
  },
  expandIcon: {
    color: theme.palette.text.primary,
  },
}));
