import { makeStyles } from 'tss-react/mui';

export const useSelectItemStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    margin: theme.spacing(2),
    borderRadius: 12,

    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  mr2: {
    marginRight: theme.spacing(2),
  },
  icon: {
    color: theme.palette.success.main,
  },
  ml7: {
    marginLeft: theme.spacing(7),
  },
  ml0: {
    marginLeft: 0,
  },
  pl0: {
    paddingLeft: '0 !important',
  },
}));
