import { makeStyles } from 'tss-react/mui';

export const useCollectionItemStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 4, 0, 5),
    alignItems: 'center',
    borderRadius: 16,
    boxShadow: 'none',
    height: 64,
    border: `1px solid ${theme.palette.grey[500]}`,
    cursor: 'pointer',
    marginBottom: theme.spacing(5),

    '&:last-of-type': {
      marginBottom: theme.spacing(7),
    },
  },
  image: {
    borderRadius: 12,
    height: 40,
    width: 40,
  },
  radio: {
    marginRight: theme.spacing(2),
  },
  active: {
    border: `1px solid ${theme.palette.violet.main}`,
    backgroundColor: theme.palette.violet[10],
  },
  error: {
    border: `1px solid ${theme.palette.error.main}`,
  },
}));
