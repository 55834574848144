import { Analytics } from 'modules/nft-manager/components/Analytics';
import { Description } from './components/Description';
import { Header } from './components/Header';
import { NftList } from '../../components/NftList';
import { ListHeader } from './components/ListHeader';
import { useCollectionInfoData } from './useCollectionInfoData';
import { RootWrapper } from 'modules/nft-manager/components/RootWrapper';
import { LoaderCentered } from 'modules/common/components/Loader';
import { Empty } from './components/Empty';

export const CollectionInfo = (): JSX.Element => {
  const {
    isLoading,
    name,
    description,
    banner,
    logo,
    created,
    lastModified,
    project,
    nftSold,
    usdSold,
    timeFrame,
    editLink,
    addNftLink,
    marketplaceLink,
    nftItems,
    isCardView,
    handleChangeView,
    handleChangeTimeFrame,
  } = useCollectionInfoData();

  if (isLoading) return <LoaderCentered />;

  const isEmptyList = !nftItems || nftItems.length === 0;

  return (
    <RootWrapper>
      <Header
        banner={banner}
        logo={logo}
        name={name}
        editLink={editLink}
        createdDate={created}
        lastEditedDate={lastModified}
        project={project}
        addNftLink={addNftLink}
        marketplaceLink={marketplaceLink}
      />

      <Description text={description} />

      <Analytics
        nftSold={nftSold}
        usdSold={usdSold}
        timeFrame={timeFrame}
        handleChangeTimeFrame={handleChangeTimeFrame}
      />

      <ListHeader
        nftsCount={nftItems?.length ?? 0}
        filter="test"
        sort="test"
        searchValue=""
        isCardView={isCardView}
        handleChangeView={handleChangeView}
        setSearch={() => console}
      />

      {isEmptyList && <Empty />}

      {!isEmptyList && <NftList data={nftItems} />}
    </RootWrapper>
  );
};
