import { filledInputClasses } from '@mui/material';
import { inputClasses } from '@mui/material/Input';
import { inputBaseClasses } from '@mui/material/InputBase';
import { Components, Palette } from '@mui/material/styles';

export function getMuiInputBase(palette: Palette): Components['MuiInputBase'] {
  return {
    styleOverrides: {
      root: {
        height: 64,
        border: `1px solid ${palette.grey[500]}`,
        borderRadius: 16,
        transition: 'all 0.3s',

        [`&.${inputBaseClasses.formControl}`]: {
          borderRadius: 16,
          backgroundColor: palette.background.paper,
        },

        '&:after, &:before': {
          display: 'none',
        },

        [`&:not(.${inputBaseClasses.disabled}):hover`]: {
          border: `1px solid ${palette.common.black}`,
          backgroundColor: `${palette.background.paper} !important`,
        },

        [`&&.${inputBaseClasses.focused}`]: {
          border: `1px solid ${palette.common.black}`,
          backgroundColor: palette.background.paper,
        },

        [`&&.${inputBaseClasses.disabled}`]: {
          backgroundColor: palette.background.paper,
        },

        [`&&.${inputBaseClasses.error}`]: {
          borderColor: palette.error.main,
        },
      },
      input: {
        height: 24,
        color: palette.common.black,
        transform: 'translate(0px, 5px)',

        [`&.${filledInputClasses.input}`]: {
          padding: '30px 20px 12px',
        },

        [`&.${inputClasses.disabled}`]: {
          color: palette.grey[900],
          WebkitTextFillColor: palette.grey[900],
          opacity: 1,
        },
      },
    },
  };
}
