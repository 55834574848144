import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { t } from 'modules/utils/intl';
import BGIcon from './assets/bg.jpeg';
import { useEmptyStyles } from './useEmptyStyles';
import { RoutesConfig } from '../../../../Routes';

export const Empty = (): JSX.Element => {
  const { classes } = useEmptyStyles();

  return (
    <div className={classes.root}>
      <div className={classes.imageWrapper}>
        <img alt="" className={classes.image} src={BGIcon} />
      </div>
      <div className={classes.content}>
        <div>
          <Typography variant="h1">{t('projects.empty.start-with')}</Typography>
          <Typography>{t('projects.empty.first-project')}</Typography>
        </div>
        <NavLink
          className={classes.button}
          to={RoutesConfig.addingProject.generatePath()}
        >
          <Button size="large">{t('projects.empty.add')}</Button>
        </NavLink>
      </div>
    </div>
  );
};
