import { NoSsr } from '@mui/material';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import 'react-toastify/dist/ReactToastify.css';
import { useNotificationsStyles } from './useNotificationsStyles';

export const Notifications = (): JSX.Element => {
  const { classes } = useNotificationsStyles();

  useEffect(() => {
    injectStyle();
  }, []);

  return (
    <NoSsr>
      <ToastContainer
        pauseOnFocusLoss
        pauseOnHover
        hideProgressBar
        className={classes.root}
        toastClassName={classes.toast}
        bodyClassName={classes.toastBody}
        icon={false}
      />
    </NoSsr>
  );
};
