import { Palette } from '@mui/material';

export function getGlobalStyles(palette: Palette): string {
  return `
    html {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      min-height: -webkit-fill-available;
      scroll-behavior: smooth;
      font-family: "Suisse Intl Medium";
    }

    body {
      background-color: #fff;
    }

    a {
      color: ${palette.violet.main};
      font-size: inherit;
      text-decoration: none;
    }

    input {
      font-family: "Suisse Intl Medium";
    }
  `;
}
