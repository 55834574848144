import { Box, Divider, Select, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { analyticsTimeFrames, TimeFrames } from 'modules/nft-manager/const';
import { t } from 'modules/utils/intl';
import { uid } from 'react-uid';
import { SelectItem } from 'uiKit/components/SelectItem';
import { useAnalyticsStyles } from './useAnalyticsStyles';

interface IUseAnalyticsProps {
  nftSold: number;
  usdSold: BigNumber;
  timeFrame: TimeFrames;
  handleChangeTimeFrame: (timeFrame: TimeFrames) => void;
}

export const Analytics = ({
  nftSold,
  usdSold,
  timeFrame,
  handleChangeTimeFrame,
}: IUseAnalyticsProps): JSX.Element => {
  const { classes } = useAnalyticsStyles();

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h3">{t('nft.analytics.analytics')}</Typography>
        <Select
          size="small"
          value={timeFrame}
          className={classes.select}
          onChange={e => handleChangeTimeFrame(e.target.value as TimeFrames)}
        >
          {Object.entries(analyticsTimeFrames).map(item => (
            <SelectItem
              itemValue={+item[0]}
              value={+item[0]}
              key={uid(item)}
              itemText={item[1]}
              selectValue={timeFrame}
              isRightIcon={false}
            >
              {item[1]}
            </SelectItem>
          ))}
        </Select>
      </Box>

      <Divider className={classes.divider} />

      <Box display="flex">
        <Box mr={8}>
          <Typography color="textSecondary" variant="body2">
            {t('nft.analytics.nft-sold')}
          </Typography>
          <Typography variant="h2">{nftSold}</Typography>
        </Box>

        <Box>
          <Typography color="textSecondary" variant="body2">
            {t('nft.analytics.value-nft-sold')}
          </Typography>
          <Typography variant="h2">{usdSold.toFormat(2)}</Typography>
        </Box>
      </Box>
    </div>
  );
};
