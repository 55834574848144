import { useCallback, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { ReactComponent as ExpandIcon } from 'uiKit/icons/chevron-small-down.svg';
import { ReactComponent as EmptyIcon } from 'uiKit/icons/empty.svg';
import { NetworkOrTokenIcon } from 'modules/common/components/NetworkOrTokenIcon';
import { ETH_ROUNDING } from 'modules/common/const';
import { StatusIcon } from 'modules/nft-manager/components/StatusIcon';
import { NftStatus } from 'modules/nft-manager/types';
import { t, tHTML } from 'modules/utils/intl';
import { MiragePlazaLink } from 'modules/nft-manager/components/MiragePlazaLink';
import { StopSaleButton } from 'modules/nft-manager/components/StopSaleButton';
import { LaunchButton } from 'modules/nft-manager/components/LaunchButton';
import { useInfoStyles } from './useInfoStyles';
import { CopyWrappedText } from 'modules/nft-manager/components/CopyWrappedText';
import { getShortId } from 'modules/common/utils/getShortId';
import { useCopyHook } from 'modules/common/utils/useCopyHook';
import { uid } from 'react-uid';
import { IExtendedNftInfoData } from '../../useNftInfoData';
import { RoutesConfig } from 'modules/nft-manager/Routes';
import { Dialog } from 'modules/common/components/Dialog';
import { LaunchDialog } from '../LaunchDialog';

export const Info = ({
  name,
  tokenId,
  status,
  collection,
  tokenPrice,
  token,
  usdPrice,
  plazaLink,
  nftAsset: content,
  description,
  quantity,
  contractAddress,
  tokenTemplate,
  network,
  royalty,
  nftProperties,
  isOpenLaunch,
  isLaunchLoading,
  stopSale,
  onClose,
  handleOpenLaunch,
  handleLaunchNft,
}: Omit<
  IExtendedNftInfoData,
  | 'moreNfts'
  | 'collectionLink'
  | 'nftContractId'
  | 'nftCollectionId'
  | 'chainCode'
>): JSX.Element => {
  const { classes } = useInfoStyles();

  const [expandedDescription, setExpandedDescription] = useState(true);
  const [expandedDetails, setExpandedDetails] = useState(true);
  const [expandedAttribute, setExpandedAttribute] = useState(true);

  const { isCopied: isAddressCopied, handleCopy: handleAddressCopy } =
    useCopyHook();
  const { isCopied: isIdCopied, handleCopy: handleIdCopy } = useCopyHook();

  const handleExpandDescription = useCallback(() => {
    setExpandedDescription(expanded => !expanded);
  }, [setExpandedDescription]);

  const handleExpandDetails = useCallback(() => {
    setExpandedDetails(expanded => !expanded);
  }, [setExpandedDetails]);

  const handleExpandAttribute = useCallback(() => {
    setExpandedAttribute(expanded => !expanded);
  }, [setExpandedAttribute]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Box display="flex">
          <Typography variant="h2" mr={2}>
            {name}
          </Typography>
          <Typography variant="h2">
            {t('utils.hashtag', {
              value: tokenId,
            })}
          </Typography>
        </Box>
        <Box display="flex">
          <StatusIcon className={classes.statusIcon} status={status} />

          <Typography variant="body2" color="textSecondary">
            {status === NftStatus.NotListed && t('nft.not-listed')}
            {status === NftStatus.LISTED && t('nft.listed')}
            {status === NftStatus.SOLD && t('nft.sold')}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" mb={4}>
        <Typography variant="body2" color="textSecondary">
          {tHTML('nft.nft-info.collection', {
            href: RoutesConfig.collection.generatePath(
              collection?.collectionId,
            ),
            value: collection?.name,
          })}
        </Typography>
      </Box>

      {status !== NftStatus.NotListed && (
        <Box mb={10}>
          <Typography variant="body2" mb={2}>
            {t('nft.nft-info.price-title')}
          </Typography>
          <Box display="flex">
            <NetworkOrTokenIcon
              className={classes.networkIcon}
              name={network}
            />
            <Box>
              <Typography variant="h3">
                {t('formats.token-value', {
                  value: tokenPrice.decimalPlaces(ETH_ROUNDING).toFixed(),
                  token,
                })}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {t('formats.usd', {
                  value: usdPrice.decimalPlaces(2).toFixed(),
                })}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      <Box className={classes.buttons}>
        {status === NftStatus.LISTED && (
          <StopSaleButton className={classes.mb4} onClick={stopSale} />
        )}
        {status !== NftStatus.NotListed && <MiragePlazaLink link={plazaLink} />}
        {status === NftStatus.NotListed && (
          <LaunchButton onClick={handleOpenLaunch} />
        )}
      </Box>

      <Box mb={6}>
        <Accordion
          expanded={expandedDescription}
          onChange={handleExpandDescription}
          className={classes.itemRoot}
        >
          <AccordionSummary
            className={classes.accordionTitle}
            expandIcon={<ExpandIcon className={classes.expandIcon} />}
          >
            <Typography variant="h3">
              {t('nft.nft-info.description')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="textSecondary" variant="body1">
              {description}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>

      <Box mb={6}>
        <Accordion
          expanded={expandedDetails}
          onChange={handleExpandDetails}
          className={classes.itemRoot}
        >
          <AccordionSummary
            className={classes.accordionTitle}
            expandIcon={<ExpandIcon className={classes.expandIcon} />}
          >
            <Typography variant="h3">{t('nft.nft-info.details')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography display="flex" alignItems="center" variant="body1">
                <EmptyIcon className={classes.notifyIcon} />
                {t('nft.nft-info.contract-address')}
              </Typography>
              <CopyWrappedText
                text={getShortId(contractAddress)}
                textForCopy={contractAddress}
                isCopied={isAddressCopied}
                handleCopy={handleAddressCopy}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography display="flex" alignItems="center" variant="body1">
                <EmptyIcon className={classes.notifyIcon} />
                {t('nft.nft-info.token-id')}
              </Typography>
              <CopyWrappedText
                text={tokenId}
                textForCopy={tokenId}
                isCopied={isIdCopied}
                handleCopy={handleIdCopy}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography display="flex" alignItems="center" variant="body1">
                <EmptyIcon className={classes.notifyIcon} />
                {t('nft.nft-info.token-template')}
              </Typography>
              {tokenTemplate}
            </Box>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography display="flex" alignItems="center" variant="body1">
                <EmptyIcon className={classes.notifyIcon} />
                {t('nft.nft-info.blockchain')}
              </Typography>
              <NetworkOrTokenIcon
                className={classes.smallNetworkIcon}
                name={network}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography display="flex" alignItems="center" variant="body1">
                <EmptyIcon className={classes.notifyIcon} />
                {t('nft.nft-info.royalty')}
              </Typography>
              <Typography variant="body1">
                {t('formats.percent', {
                  value: royalty,
                })}
              </Typography>
            </Box>
            {/* <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography display="flex" alignItems="center" variant="body1">
                <EmptyIcon className={classes.notifyIcon} />
                {t('nft.nft-info.instances')}
              </Typography>
              {instances}
            </Box> */}
          </AccordionDetails>
        </Accordion>
      </Box>

      {!!nftProperties &&
        !!nftProperties?.length &&
        nftProperties.length > 0 && (
          <Box mb={6}>
            <Accordion
              expanded={expandedAttribute}
              onChange={handleExpandAttribute}
              className={classes.itemRoot}
            >
              <AccordionSummary
                className={classes.accordionTitle}
                expandIcon={<ExpandIcon className={classes.expandIcon} />}
              >
                <Typography variant="h3">
                  {t('nft.nft-info.attributes')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  className={classes.attributeRow}
                  height={40}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Typography variant="captionAll" color="textSecondary">
                    {t('nft.nft-info.property')}
                  </Typography>
                  <Typography variant="captionAll" color="textSecondary">
                    {t('nft.nft-info.value')}
                  </Typography>
                </Box>
                {nftProperties.map(property => (
                  <Box
                    key={uid(property)}
                    className={classes.attributeRow}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Typography variant="body1">{property.name}</Typography>
                    <Typography variant="body1">{property.value}</Typography>
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          </Box>
        )}

      <Dialog
        open={isOpenLaunch}
        onClose={onClose}
        title={t('nft.nft-info.launch.title')}
      >
        <LaunchDialog
          network={network}
          isLoading={isLaunchLoading}
          handleLaunchNft={handleLaunchNft}
        />
      </Dialog>
    </>
  );
};
