import { makeStyles } from 'tss-react/mui';

export const useSelectTokenStandartStyles = makeStyles()(theme => ({
  root: {},
  selectWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(10),
  },
  radioWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(5),
    flex: 6,
  },
}));
