import { createTheme } from '@mui/material/styles';
import { Palette } from '@mui/material/styles/createPalette';
import {
  getMuiAccordion,
  getMuiButton,
  getMuiCheckbox,
  getMuiInput,
  getMuiInputBase,
  getMuiInputLabel,
  getMuiLink,
  getMuiMenuItem,
  getMuiPaper,
  getMuiRadio,
  getMuiSelect,
  getMuiSwitch,
  getMuiTab,
  getMuiTableContainer,
  getMuiTextField,
  getMuiTooltip,
} from './mui';
import { BREAKPOINTS, SPACING } from './const';
import { getGlobalStyles } from './getGlobalStyles';
import { mainPalette } from './mainPalette';

export const mainTheme = createTheme({
  spacing: SPACING,
  palette: mainPalette as Palette,

  breakpoints: {
    values: BREAKPOINTS,
  },
  typography: {
    fontFamily: 'Suisse Intl Medium',
    fontSize: 15,

    // H1: https://www.figma.com/file/tUzT8oXVXegbIKvCbA6pPH/Mosaic_Design?node-id=5%3A179&t=Pqfk17jyjguMnYFh-4
    h1: {
      fontWeight: 500,
      letterSpacing: '-0.01em',
      fontSize: 44,
      lineHeight: '48px',
    },

    // H2: https://www.figma.com/file/tUzT8oXVXegbIKvCbA6pPH/Mosaic_Design?node-id=5%3A180&t=Pqfk17jyjguMnYFh-4
    h2: {
      fontWeight: 500,
      letterSpacing: '-0.01em',
      fontSize: 28,
      lineHeight: '32px',
    },

    // H2 numbs: https://www.figma.com/file/tUzT8oXVXegbIKvCbA6pPH/Mosaic_Design?node-id=5%3A184&t=Pqfk17jyjguMnYFh-4
    h2Monospace: {
      fontWeight: 500,
      letterSpacing: '-0.01em',
      fontSize: 28,
      lineHeight: '32px',
      fontFeatureSettings: "'tnum' on, 'lnum' on",
    },

    h3: {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: '24px',
    },

    h4: {
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '24px',
      letterSpacing: '-0.01em',
    },

    // Text: https://www.figma.com/file/tUzT8oXVXegbIKvCbA6pPH/Mosaic_Design?node-id=5%3A187&t=Pqfk17jyjguMnYFh-4
    body1: {
      fontWeight: 500,
      fontSize: 15,
      lineHeight: '20px',
      letterSpacing: '-0.01em',
    },

    // Text small: https://www.figma.com/file/tUzT8oXVXegbIKvCbA6pPH/Mosaic_Design?node-id=5%3A190&t=Pqfk17jyjguMnYFh-4
    body2: {
      fontWeight: 500,
      fontSize: 13,
      lineHeight: '16px',
    },

    // Caption: https://www.figma.com/file/tUzT8oXVXegbIKvCbA6pPH/Mosaic_Design?node-id=6%3A193&t=Pqfk17jyjguMnYFh-4
    caption: {
      fontWeight: 500,
      fontSize: 11,
      lineHeight: '16px',
    },

    // Caption (all caps): https://www.figma.com/file/tUzT8oXVXegbIKvCbA6pPH/Mosaic_Design?node-id=7%3A196&t=Pqfk17jyjguMnYFh-4
    captionAll: {
      fontWeight: 500,
      fontSize: 11,
      lineHeight: '16px',
      letterSpacing: '0.03em',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: getGlobalStyles(mainPalette),
    },
    MuiButton: getMuiButton(mainPalette),
    MuiRadio: getMuiRadio(mainPalette),
    MuiCheckbox: getMuiCheckbox(mainPalette),
    MuiSwitch: getMuiSwitch(mainPalette),
    MuiTextField: getMuiTextField(),
    MuiInput: getMuiInput(),
    MuiInputBase: getMuiInputBase(mainPalette),
    MuiInputLabel: getMuiInputLabel(mainPalette),
    MuiSelect: getMuiSelect(mainPalette),
    MuiPaper: getMuiPaper(mainPalette),
    MuiLink: getMuiLink(mainPalette),
    MuiTableContainer: getMuiTableContainer(mainPalette),
    MuiTooltip: getMuiTooltip(mainPalette),
    MuiTab: getMuiTab(),
    MuiAccordion: getMuiAccordion(),
    MuiMenuItem: getMuiMenuItem(),
  },
});
