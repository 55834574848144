/* eslint-disable jsx-a11y/anchor-is-valid */
import Typography from '@mui/material/Typography';
import { featuresConfig } from 'modules/common/featuresConfig';
import { t } from 'modules/utils/intl';
import { ReactComponent as LogoIcon } from './assets/logo.svg';
import { useFooterStyles } from './useFooterStyles';

interface Props {
  className?: string;
}

export function Footer({ className }: Props): JSX.Element {
  const { classes, cx } = useFooterStyles();

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.logo}>
        <Typography variant="captionAll" textTransform="uppercase">
          {t('layout.footer.powered-by')}
        </Typography>
        <LogoIcon />
      </div>
      {featuresConfig.isActiveFooterLinks && (
        <div className={classes.links}>
          <a href="#" className={classes.link}>
            <Typography variant="captionAll">
              {t('layout.footer.links.legal')}
            </Typography>
          </a>
          <a href="#" className={classes.link}>
            <Typography variant="captionAll">
              {t('layout.footer.links.privacy-policy')}
            </Typography>
          </a>
          <a href="#" className={classes.link}>
            <Typography variant="captionAll">
              {t('layout.footer.links.cookies')}
            </Typography>
          </a>
        </div>
      )}
    </div>
  );
}
