import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import { t } from '../../../utils/intl';
import { ReactComponent as LogoIcon } from './assets/plazaworks-logo.svg';
import { NestedMenu } from './components/NestedMenu';
import { MainMenu } from './components/MainMenu';
import { useSidebarStyles } from './useSidebarStyles';
import { RoutesConfig } from '../../../projects/Routes';

interface Props {
  className?: string;
}

export function Sidebar({ className }: Props): JSX.Element {
  const { classes, cx } = useSidebarStyles();

  return (
    <div className={cx(classes.root, className)}>
      <NavLink to={RoutesConfig.projects.generatePath()}>
        <LogoIcon className={classes.logo} />
      </NavLink>
      <div className={classes.content}>
        <div className={classes.menuSegment}>
          <div className={classes.menuTitle}>
            <Typography variant="captionAll" textTransform="uppercase">
              {t('layout.menu.menu')}
            </Typography>
          </div>
          <MainMenu className={classes.menuLinks} />
        </div>
        <div className={classes.menuSegment}>
          <div className={classes.menuTitle}>
            <Typography variant="captionAll" textTransform="uppercase">
              {t('layout.menu.suites')}
            </Typography>
          </div>
          <NestedMenu className={classes.menuDropdowns} />
        </div>
      </div>
    </div>
  );
}
