import { uid } from 'react-uid';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import { ICompileErrorItem } from 'modules/smart-contracts/types';
import { ReactComponent as ErrorIcon } from 'uiKit/icons/fault-ring.svg';
import { ReactComponent as ExpandIcon } from 'uiKit/icons/chevron-down.svg';
import { useCompileErrorsStyles } from './useCompileErrorsStyles';
import { useCallback, useState } from 'react';

interface ICompileErrorsProps {
  errors: ICompileErrorItem[];
}

const ErrorItem = ({
  errorMessage,
  index,
}: {
  errorMessage: string;
  index: number;
}): JSX.Element => {
  const { classes, cx } = useCompileErrorsStyles();
  const [expanded, setExpanded] = useState(false);

  const handleExpand = useCallback(() => {
    setExpanded(expanded => !expanded);
  }, [setExpanded]);

  const errorMessageItemsArr = errorMessage.split('"');
  const errotTitle = `${errorMessageItemsArr[0]} (${index + 1})`;
  errorMessageItemsArr.shift();

  return (
    <Accordion
      expanded={expanded}
      onChange={handleExpand}
      className={classes.itemRoot}
    >
      <AccordionSummary
        expandIcon={<ExpandIcon className={classes.expandIcon} />}
      >
        <Typography className={cx(classes.errotText, classes.errorTitle)}>
          <ErrorIcon />
          {errotTitle}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {errorMessageItemsArr.map(x => (
          <Typography key={uid(x)} className={classes.errotText}>
            {x.trim()}
          </Typography>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export const CompileErrors = ({ errors }: ICompileErrorsProps): JSX.Element => {
  const { classes } = useCompileErrorsStyles();

  return (
    <div className={classes.root}>
      {errors.map((error, index) => (
        <ErrorItem
          key={uid(error)}
          index={index}
          errorMessage={error.errorMessage}
        />
      ))}
    </div>
  );
};
