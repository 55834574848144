import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { uid } from 'react-uid';
import { TableBodyRow, TableHeadCell } from 'uiKit/components/Table';
import { ReactComponent as PlusIcon } from 'uiKit/icons/plus.svg';
import { t } from 'modules/utils/intl';
import { featuresConfig } from 'modules/common/featuresConfig';
import { FILE_STATUS, IContractFileFrontItem } from '../../../../types';
import { ActionCell } from '../ActionCell';
import { StatusItem } from '../StatusItem';
import { StepHeader } from '../StepHeader';
import { useUploadStyles } from './useUploadStyles';
import { Button } from 'uiKit/components/Button';

interface IUploadedFilesProps {
  file: IContractFileFrontItem;
  uploadStatus: FILE_STATUS;
  isLoading: boolean;
  isDeleteLoading: boolean;
  isRetryUploadLoading: boolean;
  handleDeleteFile: () => void;
  handleRetryUploadFiles: () => void;
  handleNextStep: () => void;
}

export const UploadedFiles = ({
  file,
  uploadStatus,
  isLoading,
  isDeleteLoading,
  isRetryUploadLoading,
  handleDeleteFile,
  handleRetryUploadFiles,
  handleNextStep,
}: IUploadedFilesProps): JSX.Element => {
  const { classes } = useUploadStyles();

  const isEverythingLoaded = uploadStatus === FILE_STATUS.UPLOADED;

  return (
    <>
      <StepHeader
        title={t('smart-contracts.upload-files-title')}
        description={t('smart-contracts.upload-description')}
      />
      <TableContainer component={Paper} className={classes.tableWrapper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell title={t('smart-contracts.upload.name')} />
              <TableHeadCell title={t('smart-contracts.upload.status')} />
              <TableHeadCell title={t('smart-contracts.upload.size')} />
              {/* <TableHeadCell title={t('smart-contracts.upload.source-link')} /> */}
              <TableHeadCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableBodyRow key={uid(file)}>
              <TableCell style={{ width: 200 }}>
                <Typography>{file.name}</Typography>
              </TableCell>
              <TableCell style={{ width: 200 }}>
                <StatusItem status={uploadStatus} />
              </TableCell>
              <TableCell style={{ width: 100 }}>
                <Typography className={classes.secondColor} variant="body2">
                  {t('smart-contracts.upload.file-size', {
                    value: (file.size / 1024).toFixed(2),
                  })}
                </Typography>
              </TableCell>
              {/* <TableCell style={{ width: 200 }}>
                  <Link
                    href={row.sourceLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {row.sourceLink}
                  </Link>
                </TableCell> */}
              <TableCell align="right" style={{ width: 100 }}>
                {/* {!!row.id && ( */}
                <ActionCell
                  status={uploadStatus}
                  isDeleteLoading={isDeleteLoading}
                  isRetryUploadLoading={isRetryUploadLoading}
                  // handleCancel={() => console.log('handleCancel')}
                  handleDelete={() => handleDeleteFile()}
                  handleRetry={() => handleRetryUploadFiles()}
                />
                {/* )} */}
              </TableCell>
            </TableBodyRow>
          </TableBody>
        </Table>
      </TableContainer>
      {featuresConfig.isAvailableMultipleSCFiles && (
        <Button
          className={classes.addMoreButton}
          size="large"
          variant="outlined"
        >
          <PlusIcon className={classes.plusIcon} />
          <Typography>{t('smart-contracts.upload.add-more')}</Typography>
        </Button>
      )}
      <Button
        isLoading={isLoading}
        disabled={!isEverythingLoaded}
        size="large"
        onClick={handleNextStep}
      >
        {t('smart-contracts.next-step')}
      </Button>
    </>
  );
};
