import { makeStyles } from 'tss-react/mui';

export const useStatusIconStyles = makeStyles()(theme => ({
  green: {
    color: theme.palette.success.main,
  },
  grey: {
    color: theme.palette.grey[900],
  },
  mr2: {
    marginRight: theme.spacing(2),
  },
}));
