import {
  queryFnNotifyWrapper,
  web3Api,
  configFromEnv,
  GET_CV_BYTECODE,
  COMMON_ERROR,
  UNAUTHORIZED_ERROR,
} from 'modules/api';
import { oryLoginAsync } from 'modules/auth';
import { RESPONSE_CODES } from 'modules/common/types';

const {
  gatewayConfig: { gamingUrl },
} = configFromEnv();

interface ICVByteCode {
  data: string;
}

interface IGetCVByteCodeArgs {
  id: string;
}

export const { useLazyGetCVByteCodeQuery } = web3Api.injectEndpoints({
  endpoints: build => ({
    getCVByteCode: build.query<ICVByteCode | null, IGetCVByteCodeArgs>({
      queryFn: queryFnNotifyWrapper<
        IGetCVByteCodeArgs,
        never,
        ICVByteCode | null
      >(async ({ id }: IGetCVByteCodeArgs) => {
        const request = await fetch(
          gamingUrl +
            GET_CV_BYTECODE +
            '?' +
            new URLSearchParams({
              id,
            }),
          {
            method: 'GET',
            credentials: 'include',
          },
        );

        if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
          await oryLoginAsync();
          throw UNAUTHORIZED_ERROR;
        }
        if (!request.ok) throw COMMON_ERROR;

        return {
          data:
            request.status === RESPONSE_CODES.NO_CONTENT
              ? null
              : await request.json(),
        };
      }),
    }),
  }),
});
