import { ReactNode } from 'react';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { Sidebar } from '../Sidebar/Sidebar';
import { useLayoutStyles } from './useLayoutStyles';

interface Props {
  children: ReactNode;
}

export function Layout({ children }: Props): JSX.Element {
  const { classes } = useLayoutStyles();

  return (
    <div className={classes.root}>
      <Sidebar className={classes.sidebar} />

      <Header className={classes.header} />

      <div className={classes.content}>
        {children}
        <Footer />
      </div>
    </div>
  );
}
