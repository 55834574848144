import { makeStyles } from 'tss-react/mui';

export const useMiragePlazaLinkStyles = makeStyles()(theme => ({
  root: {
    color: theme.palette.text.primary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    marginLeft: theme.spacing(1.5),
    width: 16,
    height: 16,
  },
}));
