import BigNumber from 'bignumber.js';
import {
  GET_COINGECKO_ETH_DATA,
  queryFnNotifyWrapper,
  web3Api,
} from 'modules/api';
import { ZERO } from 'modules/common/const';

export const { useGetEthPriceQuery } = web3Api.injectEndpoints({
  endpoints: build => ({
    getEthPrice: build.query<BigNumber, void>({
      queryFn: queryFnNotifyWrapper<void, never, BigNumber>(async () => {
        const requestt = await fetch(GET_COINGECKO_ETH_DATA, {
          method: 'GET',
        }).then(res => res.json());

        return {
          data: new BigNumber(requestt.market_data.current_price.usd) ?? ZERO,
        };
      }),
    }),
  }),
});
