import { NFT_TILE_MAX_WIDTH } from 'modules/nft-manager/const';
import { makeStyles } from 'tss-react/mui';

const transitionsConfig = {
  duration: 350,
  easing: 'ease',
};

export const useNftTileStyles = makeStyles<undefined, 'root'>()(
  (theme, args, classes) => ({
    root: {
      width: NFT_TILE_MAX_WIDTH,
      height: 338,
      margin: theme.spacing(2.5),
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16,
      overflow: 'hidden',
      position: 'relative',
      transition: theme.transitions.create(
        ['box-shadow', 'transform'],
        transitionsConfig,
      ),

      '&:hover': {
        border: `1px solid ${theme.palette.grey[900]}`,
      },
    },

    navlink: {
      color: theme.palette.text.primary,
    },

    mrIcon: {
      marginRight: theme.spacing(2),
    },

    mainContent: {
      height: '100%',
      width: '100%',
      transition: theme.transitions.create(['opacity'], transitionsConfig),

      [`.${classes.root}:hover &`]: {
        opacity: 0,
      },
    },

    detailsContent: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: 0,
      height: '100%',
      width: '100%',
      padding: theme.spacing(4),
      opacity: 0,
      cursor: 'pointer',

      transform: `translateY(20px)`,
      transition: theme.transitions.create(
        ['opacity', 'transform'],
        transitionsConfig,
      ),

      [`.${classes.root}:hover &`]: {
        opacity: 1,
        transform: 'translateY(0)',
      },
    },

    image: {
      height: '75%',
      width: '100%',
    },

    info: {
      padding: theme.spacing(3, 5, 5),
    },

    spaceBetween: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    mb1: {
      marginBottom: theme.spacing(1.5),
    },

    mb2: {
      marginBottom: theme.spacing(2),
    },

    mb4: {
      marginBottom: theme.spacing(4),
    },

    mb5: {
      marginBottom: theme.spacing(5),
    },

    violet: {
      color: theme.palette.violet.main,
    },

    tokenIcon: {
      height: 32,
      width: 32,

      '& + &': {
        marginLeft: theme.spacing(-2),
        zIndex: -1,
      },

      '& + & + &': {
        marginLeft: theme.spacing(-2),
        zIndex: -2,
      },
    },

    name: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 120,
      maxHeight: 24,
      marginRight: theme.spacing(1),
    },

    count: {
      marginBottom: theme.spacing(8),
    },

    footer: {},

    item: {
      display: 'flex',
      justifyContent: 'space-between',
    },

    stats: {
      display: 'flex',
      alignItems: 'center',
      transition: theme.transitions.create(['opacity'], transitionsConfig),

      [`.${classes.root}:hover &`]: {
        opacity: 0,
      },
    },

    buttons: {
      position: 'absolute',
      right: theme.spacing(4),
      left: theme.spacing(4),
      bottom: theme.spacing(13),

      height: 40,

      opacity: 0,
      transform: `translateY(20px)`,
      transition: theme.transitions.create(
        ['opacity', 'transform'],
        transitionsConfig,
      ),

      [`.${classes.root}:hover &`]: {
        opacity: 1,
        transform: 'translateY(0)',
      },
    },

    button: {
      marginTop: 'auto',
    },

    icon: {
      marginLeft: theme.spacing(1.5),
      width: 16,
      height: 16,
    },
  }),
);
