import { makeStyles } from 'tss-react/mui';

export const useCopyWrappedTextStyles = makeStyles()(theme => ({
  violet: {
    color: theme.palette.violet.main,
  },

  icon: {
    marginLeft: theme.spacing(1.5),
    width: 16,
    height: 16,
  },
}));
