import { push } from '@lagunovsky/redux-react-router';
import {
  COMMON_ERROR,
  configFromEnv,
  POST_CV_PUBLISH,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
} from 'modules/api';
import { oryLoginAsync } from 'modules/auth';
import { notify } from 'modules/common/components/Notifications';
import { RESPONSE_CODES } from 'modules/common/types';
import { tHTML } from 'modules/utils/intl';
import { uid } from 'react-uid';
import { ContractsManagerCacheTags } from '../../../const';
import { RoutesConfig } from '../../../Routes';
import { CV_STATE } from '../../../types';

interface IPublishCVResponse {
  id: string;
  name: string;
  contractVersionState: CV_STATE;
}

interface IPublishCVArgs {
  id: string;
  transactionHash: string;
  contractId: string | null;
  chainId: string;
}

const {
  gatewayConfig: { gamingUrl },
} = configFromEnv();

export const { usePublishCVTxHashMutation } = web3Api.injectEndpoints({
  endpoints: build => ({
    publishCVTxHash: build.mutation<IPublishCVResponse | null, IPublishCVArgs>({
      queryFn: queryFnNotifyWrapper<
        IPublishCVArgs,
        never,
        IPublishCVResponse | null
      >(async (data: IPublishCVArgs) => {
        const request = await fetch(gamingUrl + POST_CV_PUBLISH, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          credentials: 'include',
          body: JSON.stringify({
            id: data.id,
            transactionHash: data.transactionHash,
            contractId: data.contractId,
            chainId: data.chainId,
          }),
        });

        if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
          await oryLoginAsync();
          throw UNAUTHORIZED_ERROR;
        }
        if (!request.ok) {
          throw COMMON_ERROR;
        }

        return {
          data: await request.json(),
        };
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        return queryFulfilled.then(() => {
          dispatch(push(RoutesConfig.manager.generatePath()));
          notify({
            message: tHTML('notifications.published-successfully'),
            type: 'info',
            key: uid(args),
          });
        });
      },
      invalidatesTags: [ContractsManagerCacheTags.commonList],
    }),
  }),
});
