/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from 'react';
import { useDialog } from 'modules/common/hooks';
import { useCreateContractVersionMutation } from './actions/useCreateContractVersionMutation';
import { useUploadFilesMutation } from './actions/useUploadFilesMutation';
import { useCompileFilesMutation } from './actions/useCompileFilesMutation';
import { useDeleteUploadedFileMutation } from './actions/useDeleteUploadedFileMutation';
import { useDeleteCVMutation } from './actions/useDeleteCVMutation';
import { useLazyGetCVDataQuery } from './actions/useLazyGetCVDataQuery';
import { useLazyGetDraftCVQuery } from './actions/useLazyGetDraftCVQuery';
import {
  FILE_STATUS,
  IContractFileFrontItem,
  STEP,
  ICVPublishData,
  ICompiledFilesData,
} from '../../types';
import { getIsMetaMaskInjected } from '@ankr.com/provider';
import { useLazyGetCVByteCodeQuery } from './actions/useLazyGetCVByteCodeQuery';
import { usePublishCVTxHashMutation } from './actions/usePublishCVTxHashMutation';
import { usePublishContractMutation } from './actions/usePublishContractMutation';
import { Network } from 'modules/common/types';
import { notify } from 'modules/common/components/Notifications';
import { t } from 'modules/utils/intl';

interface IUseMainUploadedData {
  step: STEP;
  uploadedFile: IContractFileFrontItem | null;
  compiledData: ICompiledFilesData | null;
  cvPublishData: ICVPublishData | null;
  byteCode?: string;
  isOpenedMetamaskWarning: boolean;
  isCompileFilesLoading: boolean;
  isUploadLoading: boolean;
  isPublishLoading: boolean;
  isCVByteCodeLoading: boolean;
  isDeleteLoading: boolean;
  isRetryUploadLoading: boolean;
  isCompiled: boolean;
  isUploadingDisabled: boolean;
  uploadStatus: FILE_STATUS;
  compileStatus: FILE_STATUS;
  handleChangeStep: (step: STEP) => void;
  handleDeleteUploadedFile: () => void;
  handleChangeUploadedFile: (file: IContractFileFrontItem | null) => void;
  handleRetryUploadFile: () => void;
  handleContractCompile: () => void;
  handleGetCVByteCode: () => void;
  handlePublish: (gas: number) => void;
  onCloseMetamaskWarning: () => void;
  goToUploadStep: () => void;
  goToCompileStep: () => void;
  goToPublishStep: () => void;
}

export const useMainUploadedData = (): IUseMainUploadedData => {
  const isMetamaskExists = getIsMetaMaskInjected();

  const [step, setStep] = useState(STEP.UPLOAD);
  const [uploadedFile, setUploadedFile] =
    useState<IContractFileFrontItem | null>(null);
  const [compiledData, setCompiledData] = useState<ICompiledFilesData | null>(
    null,
  );
  const [cvPublishData, setCVPublishData] = useState<ICVPublishData | null>(
    null,
  );

  const [getDraftCV] = useLazyGetDraftCVQuery();
  const [
    uploadFiles,
    { data: uploadedData, isLoading: isUploadLoading, error: uploadingError },
  ] = useUploadFilesMutation();
  const [
    createContractVersion,
    {
      data: contractVersion,
      isLoading: isCreateCVLoading,
      error: createCVError,
      reset: resetCreateCV,
    },
  ] = useCreateContractVersionMutation();
  const [deleteUploadedFile, { isLoading: isDeleteUploadedFileLoading }] =
    useDeleteUploadedFileMutation();
  const [deleteCV] = useDeleteCVMutation();
  const [getCVData] = useLazyGetCVDataQuery();
  const [
    compileFiles,
    {
      data: compileData,
      isLoading: isCompileFilesLoading,
      error: compileError,
      reset: resetCompileData,
    },
  ] = useCompileFilesMutation();
  const [
    getCVByteCode,
    { data: byteCodeData, isLoading: isCVByteCodeLoading },
  ] = useLazyGetCVByteCodeQuery();
  const [publishCVTxHash, { isLoading: isPublishingCVTxHashLoading }] =
    usePublishCVTxHashMutation();
  const [publishContract, { isLoading: isPublishingContractLoading }] =
    usePublishContractMutation();

  let uploadStatus = FILE_STATUS.UPLOADING;

  if (isUploadLoading || isCreateCVLoading) {
    uploadStatus = FILE_STATUS.UPLOADING;
  }
  if (!!uploadingError || !!createCVError) {
    uploadStatus = FILE_STATUS.UPLOAD_ERROR;
  }
  if (!!contractVersion && !!uploadedData) {
    uploadStatus = FILE_STATUS.UPLOADED;
  }

  let compileStatus = FILE_STATUS.COMPILE_WAIT;

  if (isCompileFilesLoading) compileStatus = FILE_STATUS.COMPILING;
  if (!!compileError || compileData?.ValidationErrors)
    compileStatus = FILE_STATUS.COMPILE_ERROR;
  if (!!compileData && !compileData.ValidationErrors)
    compileStatus = FILE_STATUS.COMPILED;

  const contractVersionId = contractVersion?.id;
  const contractVersionName = contractVersion?.name;

  const { isOpened, onOpen, onClose } = useDialog();

  const handleChangeStep = useCallback(
    (step: STEP) => {
      setStep(step);
    },
    [setStep],
  );

  const handleContractCompile = useCallback(() => {
    if (contractVersionId) {
      compileFiles({ id: contractVersionId })
        .unwrap()
        .then(compileData => {
          getCVData({ id: contractVersionId })
            .unwrap()
            .then(data => {
              setCompiledData({
                name: data.name,
                lastModified: data.lastModified,
                files: data.contractFiles,
                errors: compileData?.ValidationErrors ?? [],
              });
              setCVPublishData({
                id: data.id,
                name: data.name,
                network: Network.Eth, // by default
                created: data.created,
                lastModified: data.lastModified,
              });
            });
        });
    }
  }, [compileFiles, contractVersionId, getCVData]);

  const handleChangeUploadedFile = useCallback(
    (file: IContractFileFrontItem | null) => {
      if (file && isMetamaskExists) {
        setUploadedFile(file);
      } else {
        setUploadedFile(null);
      }
      if (!isMetamaskExists) {
        notify({
          message: t('unexists-metamask.title'),
          type: 'error',
          key: 'metamask',
        });
      }
    },
    [isMetamaskExists],
  );

  const handleGetCVByteCode = () => {
    if (contractVersionId) {
      getCVByteCode({
        id: contractVersionId,
      });
    }
  };

  const handlePublish = (gas: number) => {
    if (!!byteCodeData?.data && contractVersionId) {
      publishContract({
        byteCode: byteCodeData.data,
        gas: gas,
      })
        .unwrap()
        .then(txData => {
          if (!!txData) {
            publishCVTxHash({
              id: contractVersionId,
              transactionHash: txData?.txHash,
              contractId: null, // by default for publish option 'publish as new'
              chainId: txData.chainId.toString(),
            });
          }
        });
    }
  };

  const handleDeleteCV = useCallback(
    (versionId: string) => {
      return deleteCV({ id: versionId });
    },
    [deleteCV],
  );

  const handleDeleteUploadedFile = useCallback(() => {
    if (!!uploadedFile?.id) {
      deleteUploadedFile({ fileId: uploadedFile.id })
        .unwrap()
        .then(data => {
          if (data === true) {
            resetCreateCV();
            setUploadedFile(null);
            if (contractVersionId) {
              handleDeleteCV(contractVersionId);
            }
          }
        });
    } else {
      setUploadedFile(null);
      if (contractVersionId) {
        handleDeleteCV(contractVersionId);
      }
    }
  }, [
    contractVersionId,
    deleteUploadedFile,
    handleDeleteCV,
    resetCreateCV,
    uploadedFile?.id,
  ]);

  const createCV = useCallback(() => {
    return createContractVersion({
      name: uploadedFile?.name.replace('.sol', '') ?? 'default',
      contractStandart: 0,
    });
  }, [createContractVersion, uploadedFile?.name]);

  const uploadFileToCV = useCallback(
    (cvId: string, cvName: string) => {
      if (!!uploadedFile) {
        uploadFiles({
          contractVersionId: cvId,
          formFile: uploadedFile,
        })
          .unwrap()
          .then(data => {
            if (data) {
              const now = new Date().toString();
              setCompiledData({
                name: cvName,
                lastModified: new Date().toString(),
                files: [],
                errors: [],
              });
              setCVPublishData({
                id: data.id,
                name: data.name,
                network: Network.Eth, // by default
                created: now,
                lastModified: now,
              });
              handleChangeUploadedFile({
                id: data.id,
                name: uploadedFile.name,
                status: FILE_STATUS.UPLOADED,
                size: uploadedFile.size,
              });
            }
          });
      }
    },
    [uploadedFile, handleChangeUploadedFile, uploadFiles],
  );

  const handleRetryUploadFile = useCallback(() => {
    if (contractVersionId && contractVersionName) {
      uploadFileToCV(contractVersionId, contractVersionName);
    } else {
      createCV()
        .unwrap()
        .then(data => {
          uploadFileToCV(data.id, data.name);
        });
    }
  }, [contractVersionId, contractVersionName, uploadFileToCV, createCV]);

  const goToUploadStep = useCallback(() => {
    setStep(STEP.UPLOAD);
    resetCompileData();
  }, [setStep, resetCompileData]);

  const goToCompileStep = useCallback(() => {
    setStep(STEP.COMPILE);
  }, [setStep]);

  const goToPublishStep = useCallback(() => {
    setStep(STEP.PUBLISH);
  }, [setStep]);

  // it removes draft cv if it exists after mounting the page
  useEffect(() => {
    getDraftCV()
      .unwrap()
      .then(data => {
        if (data) {
          handleDeleteCV(data.id)
            .unwrap()
            .then(() => {
              handleChangeUploadedFile(null);
            });
        }
      });
  }, [getDraftCV, handleChangeUploadedFile, handleDeleteCV]);

  useEffect(() => {
    if (!!uploadedFile && !contractVersion) {
      createCV()
        .unwrap()
        .then(data => {
          uploadFileToCV(data.id, data.name);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFile]);

  useEffect(() => {
    if (!isMetamaskExists) {
      onOpen();
    }
  }, [isMetamaskExists, onOpen, step]);

  const isUploadingLoading = isUploadLoading || isCreateCVLoading;

  return {
    step,
    uploadedFile,
    compiledData,
    cvPublishData,
    byteCode: byteCodeData?.data,
    isOpenedMetamaskWarning: isOpened,
    isCompileFilesLoading,
    isUploadLoading: isUploadingLoading,
    isPublishLoading:
      isPublishingContractLoading || isPublishingCVTxHashLoading,
    isCVByteCodeLoading: isCVByteCodeLoading,
    isDeleteLoading: isDeleteUploadedFileLoading,
    isRetryUploadLoading: isUploadingLoading,
    isCompiled: !!compileData,
    isUploadingDisabled: isUploadLoading || isDeleteUploadedFileLoading,
    uploadStatus,
    compileStatus,
    handleChangeStep,
    handleChangeUploadedFile,
    handleDeleteUploadedFile,
    handleRetryUploadFile,
    handleContractCompile,
    handleGetCVByteCode,
    handlePublish,
    onCloseMetamaskWarning: onClose,
    goToUploadStep,
    goToCompileStep,
    goToPublishStep,
  };
};
