import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { uid } from 'react-uid';
import { t } from '../../../../../utils/intl';
import { IMemberItem } from '../../../../actions/useGetProjectInfoQuery';
import {
  TableBodyRow,
  TableHeadCell,
} from '../../../../../../uiKit/components/Table';
import { prepareRoleName } from '../../utils';
import { useProjectMembersStyles } from './useProjectMembersStyles';
import { UserItem } from './UserItem';
import { UserActions } from './UserActions';
import { featuresConfig } from '../../../../../common/featuresConfig';

interface IProjectMembersArgs {
  members: IMemberItem[] | null;
}

export const ProjectMembers = ({
  members,
}: IProjectMembersArgs): JSX.Element | null => {
  const { classes } = useProjectMembersStyles();

  if (!members || !members.length) return null;

  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.mb6}>
        {t('projects.projectInfo.members')}
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell title={t('projects.projectInfo.user')} />
              <TableHeadCell title={t('projects.projectInfo.role')} />
              {featuresConfig.isActiveMemberActions && <TableHeadCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {members.map(row => {
              let userName = '';
              if (!!row.firstName) userName = userName.concat(row.firstName);
              if (!!row.lastName) {
                if (!!userName) userName = userName.concat(' ');
                userName = userName.concat(row.lastName);
              }
              if (!userName) userName = row.id;

              return (
                <TableBodyRow key={uid(row)}>
                  <TableCell style={{ width: 500 }}>
                    <UserItem name={userName} email={row.email} />
                  </TableCell>
                  <TableCell style={{ width: 300 }}>
                    <Chip
                      label={
                        <Typography variant="caption" className={classes.chip}>
                          {prepareRoleName(row.role)}
                        </Typography>
                      }
                    />
                  </TableCell>
                  {featuresConfig.isActiveMemberActions && (
                    <TableCell align="right">
                      <UserActions id={row.id} />
                    </TableCell>
                  )}
                </TableBodyRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
