import { Network, NftMintNetwork, Token } from '../../types';
import { ReactComponent as BscIcon } from 'uiKit/icons/bsc.svg';
import { ReactComponent as BnbIcon } from 'uiKit/icons/bnb.svg';
import { ReactComponent as EthIcon } from 'uiKit/icons/eth.svg';
import { ReactComponent as PolygonIcon } from 'uiKit/icons/polygon.svg';
import { ReactComponent as ArbitrumNovaIcon } from 'uiKit/icons/arbitrum-nova.svg';

interface INetworkIconProps {
  name: Network | Token | NftMintNetwork;
  className?: string;
}

export const NetworkOrTokenIcon = ({
  className,
  name,
}: INetworkIconProps): JSX.Element | null => {
  switch (name) {
    case Network.Eth:
    case Token.ETH:
    case NftMintNetwork.Goerli:
    case NftMintNetwork.SepETH:
      return <EthIcon className={className} />;
    case Network.Bsc:
      return <BscIcon className={className} />;
    case Network.Polygon:
    case Token.MATIC:
    case NftMintNetwork.Polygon:
      return <PolygonIcon className={className} />;
    case Token.BNB:
    case NftMintNetwork.BSC:
      return <BnbIcon className={className} />;
    case Network.Arbitrum:
    case Token.ARB:
    case NftMintNetwork.ARB:
      return <ArbitrumNovaIcon className={className} />;
    default:
      return null;
  }
};
