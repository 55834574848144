import DefaultIconBlue from 'uiKit/icons/default-icon-blue.svg';
import DefaultIconGreen from 'uiKit/icons/default-icon-green.svg';
import DefaultIconPink from 'uiKit/icons/default-icon-pink.svg';
import DefaultIconPurple from 'uiKit/icons/default-icon-purple.svg';

interface IDefaultProjectIconProps {
  index: number;
  className?: string;
}

const DEFAULT_ICONS_LIST = [
  DefaultIconBlue,
  DefaultIconGreen,
  DefaultIconPink,
  DefaultIconPurple,
];
const DEFAULT_ICONS_VARIANTS = DEFAULT_ICONS_LIST.length;

export const DefaultProjectIcon = ({
  index,
  className,
}: IDefaultProjectIconProps): JSX.Element => {
  const iconNumber = index % DEFAULT_ICONS_VARIANTS;

  return (
    <img alt="" className={className} src={DEFAULT_ICONS_LIST[iconNumber]} />
  );
};
