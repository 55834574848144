import {
  COMMON_ERROR,
  configFromEnv,
  POST_CONTRACT_FILE_UPLOAD,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
} from 'modules/api';
import { oryLoginAsync } from 'modules/auth';
import { RESPONSE_CODES } from 'modules/common/types';

interface IUploadFilesResponse {
  id: string;
  name: string;
  description: string;
  contractVersionId: string;
}

interface IUploadFilesArgs {
  contractVersionId: string;
  formFile: any; // sol file
}

const {
  gatewayConfig: { gamingUrl },
} = configFromEnv();

export const { useUploadFilesMutation } = web3Api.injectEndpoints({
  endpoints: build => ({
    uploadFiles: build.mutation<IUploadFilesResponse | null, IUploadFilesArgs>({
      queryFn: queryFnNotifyWrapper<
        IUploadFilesArgs,
        never,
        IUploadFilesResponse | null
      >(async (data: IUploadFilesArgs) => {
        const bodyData = new FormData();
        bodyData.append('FormFile', data.formFile);
        bodyData.append('ContractVersionId', data.contractVersionId);

        const request = await fetch(gamingUrl + POST_CONTRACT_FILE_UPLOAD, {
          method: 'POST',
          body: bodyData,
          credentials: 'include',
        });

        if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
          await oryLoginAsync();
          throw UNAUTHORIZED_ERROR;
        }
        if (!request.ok) {
          throw COMMON_ERROR;
        }

        return {
          data: await request.json(),
        };
      }),
    }),
  }),
});
