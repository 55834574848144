import { makeStyles } from 'tss-react/mui';

export const useContractIdStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
  },
  id: {
    marginRight: theme.spacing(2.5),
  },
  icon: {
    height: 20,
    width: 20,
  },
  doneIcon: {
    color: theme.palette.text.primary,
  },
}));
