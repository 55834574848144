import { useEffect } from 'react';
import { EProjectTypes } from 'modules/common/types';
import { useAddNewProjectMutation } from '../../../actions/useAddNewProjectMutation';
import { useLazyGetOrganizationsQuery } from '../../../actions/useLazyGetOrganizationsQuery';
import { EPlatform } from 'modules/projects/const';
import { getBase64 } from 'modules/common/utils/getBase64';

export interface INewProjectForm {
  name: string | null;
  description: string | null;
  isPc: boolean;
  isMac: boolean;
  isLinux: boolean;
  isIos: boolean;
  isAndroid: boolean;
  isWeb: boolean;
  type: EProjectTypes | string;
  image?: File;
  banner?: File;
  isTestMode?: boolean;
  isIosConnected?: boolean;
  isAndroidConnected?: boolean;
  isWebglConnected?: boolean;
}

interface IAddingProjectFormData {
  isLoading: boolean;
  onSubmit: (data: INewProjectForm) => void;
}

export const useAddingProjectForm = (): IAddingProjectFormData => {
  const [addNewProject, { isLoading }] = useAddNewProjectMutation();
  const [getOrganizations, { data: organizations }] =
    useLazyGetOrganizationsQuery();

  useEffect(() => {
    getOrganizations();
  }, [getOrganizations]);

  const onSubmit = async (data: INewProjectForm) => {
    if (
      data.name &&
      data.description &&
      (data.isPc ||
        data.isMac ||
        data.isLinux ||
        data.isIos ||
        data.isAndroid ||
        data.isWeb) &&
      typeof data.type === 'number' &&
      organizations &&
      !!data.image &&
      !!data.banner
    ) {
      let platforms = [];
      if (data.isPc) platforms.push(EPlatform.PC);
      if (data.isMac) platforms.push(EPlatform.MAC);
      if (data.isLinux) platforms.push(EPlatform.LINUX);
      if (data.isIos) platforms.push(EPlatform.IOS);
      if (data.isAndroid) platforms.push(EPlatform.ANDROID);
      if (data.isWeb) platforms.push(EPlatform.WEB);

      const base64Image = await getBase64(data.image);
      const base64Banner = await getBase64(data.banner);

      addNewProject({
        projectName: data.name.trim(),
        description: data.description.trim(),
        platformTypes: platforms,
        gameProjectTypes: [data.type],
        organizationId: organizations[0].id,
        imageName: data.image.name,
        image: base64Image.split(',')[1] as string,
        bannerName: data.banner.name,
        banner: base64Banner.split(',')[1] as string,
        descriptionLong: data.description.trim(),
        gameProjectLinks: null,
      });
    }
  };

  return {
    isLoading,
    onSubmit,
  };
};
