import { makeStyles } from 'tss-react/mui';

export const useStepHeaderStyles = makeStyles()(theme => ({
  root: {
    marginBottom: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  description: {
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr',
    gap: theme.spacing(2),
    maxWidth: theme.spacing(90),
    color: theme.palette.grey[900],
  },
}));
