import { oryLoginAsync } from 'modules/auth';
import {
  COMMON_ERROR,
  configFromEnv,
  GET_POST_NFT_TOKEN,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
} from '../../api';
import { RESPONSE_CODES } from '../../common/types';

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

interface IDeleteNftArgs {
  id: string;
}

export const { useDeleteNftTokenMutation } = web3Api.injectEndpoints({
  endpoints: build => ({
    deleteNftToken: build.mutation<void, IDeleteNftArgs>({
      queryFn: queryFnNotifyWrapper<IDeleteNftArgs, never, void>(
        async (data: IDeleteNftArgs) => {
          const request = await fetch(
            nftManagerUrl + GET_POST_NFT_TOKEN + data.id,
            {
              method: 'DELETE',
              credentials: 'include',
            },
          );

          if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
            await oryLoginAsync();
            throw UNAUTHORIZED_ERROR;
          }
          if (!request.ok) {
            throw COMMON_ERROR;
          }
          return { data: await request.json() };
        },
      ),
    }),
  }),
});
