import {
  queryFnNotifyWrapper,
  web3Api,
  configFromEnv,
  GET_ORGANIZATIONS_URL,
  COMMON_ERROR,
  UNAUTHORIZED_ERROR,
} from '../../api';
import { oryLoginAsync } from 'modules/auth';
import { RESPONSE_CODES } from '../../common/types';

const {
  gatewayConfig: { mirageIdUrl },
} = configFromEnv();

interface IOrganization {
  id: string;
  name: string;
}

export const { useLazyGetOrganizationsQuery } = web3Api.injectEndpoints({
  endpoints: build => ({
    getOrganizations: build.query<IOrganization[], void>({
      queryFn: queryFnNotifyWrapper<void, never, IOrganization[]>(async () => {
        const request = await fetch(mirageIdUrl + GET_ORGANIZATIONS_URL, {
          method: 'GET',
          credentials: 'include',
        });

        if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
          await oryLoginAsync();
          throw UNAUTHORIZED_ERROR;
        }
        if (!request.ok) {
          throw COMMON_ERROR;
        }

        return { data: await request.json() };
      }),
    }),
  }),
});
