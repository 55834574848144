import {
  COMMON_ERROR,
  configFromEnv,
  GET_POST_NFT_CONTRACT,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
} from '../../api';
import { oryLoginAsync } from 'modules/auth';
import { RESPONSE_CODES } from '../../common/types';
import { INftContract } from '../types';

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

export const { useGetNftContractsQuery } = web3Api.injectEndpoints({
  endpoints: build => ({
    getNftContracts: build.query<INftContract[], void>({
      queryFn: queryFnNotifyWrapper<void, never, INftContract[]>(async () => {
        const request = await fetch(nftManagerUrl + GET_POST_NFT_CONTRACT, {
          method: 'GET',
          credentials: 'include',
        });

        if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
          await oryLoginAsync();
          throw UNAUTHORIZED_ERROR;
        }
        if (!request.ok) throw COMMON_ERROR;

        return { data: (await request.json()) ?? [] };
      }),
    }),
  }),
});
