import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { uid } from 'react-uid';
import { format } from 'date-fns';
import { TableBodyRow, TableHeadCell } from 'uiKit/components/Table';
import { t } from 'modules/utils/intl';
import {
  FILE_STATUS,
  ICompiledFilesData,
  IContractFileItem,
} from '../../../../types';
// import { ActionCell } from '../ActionCell';
import { StatusItem } from '../StatusItem';
import { useCompileStyles } from './useCompileStyles';
import { StepHeader } from '../StepHeader';
import { Button } from 'uiKit/components/Button';
import { DEFAULT_DATE_FORMAT } from 'modules/common/const';
import { CompileErrors } from '../CompileErrors';
import { useDialog } from 'modules/common/hooks';
import { Dialog } from 'modules/common/components/Dialog';

interface ICompiledFilesProps {
  data: ICompiledFilesData | null;
  isCompileFilesLoading: boolean;
  compileStatus: FILE_STATUS;
  handleContractCompile: () => void;
  handleNextStep: () => void;
}

interface IExpandedTable {
  expandedFiles: IContractFileItem[];
}

export const CompiledFiles = ({
  data,
  isCompileFilesLoading,
  compileStatus,
  handleContractCompile,
  handleNextStep,
}: ICompiledFilesProps): JSX.Element | null => {
  const { classes } = useCompileStyles();
  const { isOpened, onOpen, onClose } = useDialog();

  if (!data) return null;

  const ExpandedTable = ({ expandedFiles }: IExpandedTable): JSX.Element => {
    return (
      <Table>
        <TableBody>
          {expandedFiles.map(file => (
            <TableRow key={uid(file)} className={classes.expandTable}>
              <TableCell style={{ width: 200 }}>
                <Typography>{file.name}</Typography>
              </TableCell>
              <TableCell style={{ width: 150 }}></TableCell>
              <TableCell style={{ width: 200 }}>
                <Typography variant="captionAll" color="textSecondary">
                  {file.description}
                </Typography>
              </TableCell>
              <TableCell style={{ width: 100 }}>
                <Typography className={classes.secondColor} variant="body2">
                  {t('smart-contracts.upload.file-size', {
                    value: (file.sizeBytes / 1024).toFixed(2),
                  })}
                </Typography>
              </TableCell>
              <TableCell style={{ width: 250 }}>
                <Typography className={classes.secondColor} variant="body2">
                  {format(new Date(file.lastModified), DEFAULT_DATE_FORMAT)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <>
      <div className={classes.wrapper}>
        <StepHeader
          title={t('smart-contracts.compiling-files-title')}
          description={t('smart-contracts.compiling-description')}
        />
        <TableContainer component={Paper} className={classes.tableWrapper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadCell title={t('smart-contracts.compile.name')} />
                <TableHeadCell title={t('smart-contracts.compile.status')} />
                <TableHeadCell
                  title={t('smart-contracts.compile.description')}
                />
                <TableHeadCell title={t('smart-contracts.compile.size')} />
                <TableHeadCell title={t('smart-contracts.compile.updates')} />
                {/* <TableHeadCell /> */}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableBodyRow
                expandable
                expandSlot={
                  !!data.files.length && (
                    <ExpandedTable expandedFiles={data.files} />
                  )
                }
                key={uid(data)}
              >
                <TableCell style={{ width: 200 }}>
                  <Typography>{data.name}</Typography>
                </TableCell>
                <TableCell style={{ width: 180 }}>
                  <StatusItem status={compileStatus} handleViewMore={onOpen} />
                </TableCell>
                <TableCell style={{ width: 200 }}></TableCell>
                <TableCell style={{ width: 100 }}></TableCell>
                <TableCell style={{ width: 220 }}>
                  <Typography className={classes.secondColor} variant="body2">
                    {format(new Date(data.lastModified), DEFAULT_DATE_FORMAT)}
                  </Typography>
                </TableCell>
                {/* <TableCell align="right" style={{ width: 100 }}>
                  <ActionCell
                    status={row.status}
                    // handleCancel={() => console.log('handleCancel')}
                    handleDelete={() => console.log('handleDelete')}
                    // handleRetry={() => console.log('handleRetry')}
                  />
                </TableCell> */}
              </TableBodyRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          className={classes.compileButton}
          size="large"
          variant="outlined"
          isLoading={isCompileFilesLoading}
          onClick={handleContractCompile}
        >
          <Typography>{t('smart-contracts.compile.compile-button')}</Typography>
        </Button>
        <Button
          disabled={compileStatus !== FILE_STATUS.COMPILED}
          size="large"
          onClick={handleNextStep}
        >
          {t('smart-contracts.next-step')}
        </Button>
      </div>

      <Dialog
        title={t('smart-contracts.compile.errors-title')}
        open={isOpened}
        onClose={onClose}
      >
        <CompileErrors errors={data.errors} />
      </Dialog>
    </>
  );
};
