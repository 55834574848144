import { makeStyles } from 'tss-react/mui';

export const useHeaderStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    position: 'relative',
    gap: 24,
  },

  divider: {
    height: 16,
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 2,
  },

  icon: {
    cursor: 'pointer',
  },

  button: {
    color: theme.palette.common.white,
  },
}));
