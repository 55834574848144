import { makeStyles } from 'tss-react/mui';

export const useNestedMenuStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // gap: 20,
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 14px',
    color: theme.palette.text.primary,
  },
  icon: {
    width: 20,
    height: 20,
    color: theme.palette.grey[700],
  },
  transformed: {
    transform: 'rotate(180deg)',
    '--main': theme.palette.common.black,
  },
  item: {
    height: 36,
    padding: 0,
    '& > a': {
      color: theme.palette.text.primary,
    },
  },
  active: {
    backgroundColor: theme.palette.violet[40],
    borderRadius: 12,
    color: theme.palette.common.white,
    '& > a': {
      color: theme.palette.common.white,
    },
    '&:hover': {
      backgroundColor: theme.palette.violet[40],
      borderRadius: 12,
    },
  },
  expandMenuItem: {
    height: '100%',
    width: '100%',
    paddingLeft: theme.spacing(7),
    paddingTop: theme.spacing(2),
  },
  ordinaryMenuItem: {
    display: 'flex',
    alignItems: 'center',
    height: 34,
    paddingLeft: theme.spacing(4),
    color: theme.palette.text.primary,
  },
}));
