import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from 'modules/common/const';
import { t } from 'modules/utils/intl';
import { ReactComponent as VersionsIcon } from 'uiKit/icons/versions.svg';
import { useUpdatesItemStyles } from './useUpdatesItemStyles';

interface IUpdatesItemProps {
  lastUpdate: string;
  versionsNumber?: number;
}

export const UpdatesItem = ({
  lastUpdate,
  versionsNumber,
}: IUpdatesItemProps): JSX.Element => {
  const { classes, cx } = useUpdatesItemStyles();

  const isVersionsNumberExists = !!versionsNumber;

  return (
    <div className={classes.root}>
      <Typography
        className={cx(isVersionsNumberExists && classes.topRoot)}
        variant="body2"
      >
        {format(new Date(lastUpdate), DEFAULT_DATE_FORMAT)}
      </Typography>
      {isVersionsNumberExists && (
        <Typography className={classes.bottomRoot} variant="body2">
          <VersionsIcon className={classes.icon} />
          {versionsNumber > 1
            ? t('smart-contracts.manager.multiple-versions', {
                value: versionsNumber,
              })
            : t('smart-contracts.manager.1-version')}
        </Typography>
      )}
    </div>
  );
};
