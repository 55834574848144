import { Typography } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import { t } from '../../../../../utils/intl';
import { useUploadStyles } from './useUploadStyles';
import { UploadedFiles } from './UploadedFiles';
import { ReactComponent as UploadIcon } from 'modules/common/assets/upload.svg';
import { FILE_STATUS, IContractFileFrontItem } from '../../../../types';
import { IMAGE_MAX_SIZE_MB } from 'modules/common/const';

interface IUploadFiles {
  fileTypes: string[];
  file: IContractFileFrontItem | null;
  uploadStatus: FILE_STATUS;
  isLoading: boolean;
  isDeleteLoading: boolean;
  isRetryUploadLoading: boolean;
  isUploadingDisabled: boolean;
  handleNextStep: () => void;
  handleChangeFile: (file: IContractFileFrontItem | null) => void;
  handleDeleteUploadedFile: () => void;
  handleRetryUploadFile: () => void;
}

export const Upload = ({
  fileTypes,
  file,
  isLoading,
  isDeleteLoading,
  isRetryUploadLoading,
  uploadStatus,
  isUploadingDisabled,
  handleDeleteUploadedFile,
  handleChangeFile,
  handleRetryUploadFile,
  handleNextStep,
}: IUploadFiles): JSX.Element => {
  const { classes } = useUploadStyles();

  return (
    <div className={classes.wrapper}>
      {!!file ? (
        <UploadedFiles
          file={file}
          uploadStatus={uploadStatus}
          isLoading={isLoading}
          isDeleteLoading={isDeleteLoading}
          isRetryUploadLoading={isRetryUploadLoading}
          handleRetryUploadFiles={handleRetryUploadFile}
          handleDeleteFile={handleDeleteUploadedFile}
          handleNextStep={handleNextStep}
        />
      ) : (
        <FileUploader
          disabled={isUploadingDisabled}
          maxSize={IMAGE_MAX_SIZE_MB}
          multiple={false}
          handleChange={handleChangeFile}
          name="file"
          types={fileTypes}
        >
          <div className={classes.root}>
            <UploadIcon className={classes.icon} />
            <Typography variant="h3">
              {t('smart-contracts.upload.drag-files')}
            </Typography>
            <Typography className={classes.secondColor} variant="body1">
              {t('smart-contracts.upload.requirementes')}
            </Typography>
          </div>
        </FileUploader>
      )}
    </div>
  );
};
