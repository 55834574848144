import { makeStyles } from 'tss-react/mui';

export const useMainMenuStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    padding: '8px 14px',
    color: theme.palette.text.primary,
  },
  active: {
    backgroundColor: theme.palette.violet[40],
    color: theme.palette.common.white,
    borderRadius: 12,
  },
}));
