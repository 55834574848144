import {
  GET_PROJECTS_LIST_URL,
  queryFnNotifyWrapper,
  web3Api,
  configFromEnv,
  COMMON_ERROR,
  UNAUTHORIZED_ERROR,
} from '../../api';
import { oryLoginAsync } from 'modules/auth';
import { RESPONSE_CODES } from '../../common/types';
import { EPlatform, ProjectCacheTags } from '../const';
import { PAGE_SIZE } from 'modules/common/const';
import { IImageData } from '../types';

const {
  gatewayConfig: { gamingUrl },
} = configFromEnv();

export interface IProjectListItem {
  id: string;
  projectName: string;
  description: string;
  descriptionLong: string | null;
  platformTypes: EPlatform[] | null;
  image?: IImageData | null;
  userId: string;
  contracts: unknown[];
}

interface IReponse {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  items: IProjectListItem[];
  pageNumber: number;
  totalCount: number;
  totalPages: number;
}

interface IGetProjectListArgs {
  name?: string;
  pageNumber?: number;
  pageSize?: number;
}

interface IGetProjectListParams {
  Name?: string;
  PageNumber?: string;
  PageSize?: string;
}

export const { useLazyGetProjectListQuery } = web3Api.injectEndpoints({
  endpoints: build => ({
    getProjectList: build.query<IReponse, IGetProjectListArgs>({
      queryFn: queryFnNotifyWrapper<IGetProjectListArgs, never, IReponse>(
        async ({ name, pageNumber = '1', pageSize = PAGE_SIZE }) => {
          const params: IGetProjectListParams = {};
          if (name) {
            params.Name = name;
          }
          if (pageNumber) {
            params.PageNumber = pageNumber.toString();
          }
          if (pageSize) {
            params.PageSize = pageSize.toString();
          }

          const request = await fetch(
            gamingUrl +
              GET_PROJECTS_LIST_URL +
              '?' +
              new URLSearchParams({
                ...params,
              }),
            {
              method: 'GET',
              credentials: 'include',
            },
          );

          if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
            await oryLoginAsync();
            throw UNAUTHORIZED_ERROR;
          }
          if (!request.ok) throw COMMON_ERROR;

          return { data: await request.json() };
        },
      ),
      providesTags: [ProjectCacheTags.commonList],
    }),
  }),
});
