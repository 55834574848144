import { makeStyles } from 'tss-react/mui';

export const useTableStyles = makeStyles()(theme => ({
  headerCell: {
    color: theme.palette.grey[900],
  },
  tooltip: {
    marginLeft: theme.spacing(2),
  },
  invertedIcon: {
    width: 20,
    height: 20,
    transform: 'rotate(180deg)',
  },
  iconWrap: {
    width: 20,
    height: 20,
    padding: 0,
    color: theme.palette.text.primary,
    borderRadius: 12,
  },

  expandCell: {
    padding: 0,
    borderColor: theme.palette.grey[500],
    borderTop: `1px solid ${theme.palette.grey[500]}`,
  },

  row: {
    '& td': {
      borderColor: theme.palette.grey[500],
    },

    '& th': {
      borderColor: theme.palette.grey[500],
    },
  },

  expandedRow: {
    '& td': {
      borderBottom: 'none',
    },
  },

  expandedSlot: {
    '& td': {
      borderColor: theme.palette.grey[500],
      borderTop: 'none',
    },
  },
}));
