import { Components } from '@mui/material';

export function getMuiMenuItem(): Components['MuiMenuItem'] {
  return {
    styleOverrides: {
      root: {
        borderRadius: 8,
        padding: '2px !important',
        margin: '4px 14px',
        '&.Mui-selected': {
          backgroundColor: 'unset',
          '&:hover': {
            backgroundColor: 'unset',
          },
        },
      },
    },
  };
}
