import { Components, Palette, switchClasses } from '@mui/material';

export function getMuiSwitch(palette: Palette): Components['MuiSwitch'] {
  return {
    styleOverrides: {
      root: ({ ownerState: { disabled } }) => ({
        padding: 0,
        borderRadius: 12,

        [`& > .${switchClasses.switchBase} + .${switchClasses.track}`]: {
          background: palette.grey[700],
        },

        [`& > .${switchClasses.switchBase}.${switchClasses.checked} + .${switchClasses.track}`]:
          {
            background: palette.success.main,
          },

        [`&:hover > .${switchClasses.switchBase} + .${switchClasses.track}`]: {
          background: disabled ? undefined : palette.grey[500],
        },

        [`&:hover > .${switchClasses.switchBase}.${switchClasses.checked} + .${switchClasses.track}`]:
          {
            background: disabled ? undefined : palette.success.light,
          },
      }),

      sizeSmall: {
        width: 32,
        height: 20,
      },

      sizeMedium: {
        width: 40,
        height: 24,
      },

      switchBase: ({ ownerState }) => ({
        '&&&': {
          padding: 2,

          [`&.${switchClasses.checked}`]: {
            transform: `translateX(${ownerState.size === 'small' ? 12 : 16}px)`,
          },
        },

        '&:active': {
          transform: 'unset',
        },

        '&, &:hover': {
          boxShadow: 'none',
        },
      }),

      thumb: ({ ownerState: { disabled } }) => ({
        background: palette.background.paper,
        opacity: disabled ? 0.6 : 1,
      }),

      track: {
        '&&&&': {
          opacity: 1,
        },
      },
    },
  };
}
