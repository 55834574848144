import createPalette, {
  Palette,
  PaletteOptions,
} from '@mui/material/styles/createPalette';

export const mainPaletteOptions: PaletteOptions = {
  error: {
    light: '#E76B65',
    main: '#E1463F',
  },
  success: {
    light: '#4ED454',
    main: '#02C20A',
  },
  background: {
    default: '#BEBEBE',
    paper: '#ffffff',
  },
  grey: {
    300: '#EFEEF0',
    500: '#D4D6DB',
    700: '#BABDC4',
    900: '#85888D',
  },
  text: {
    primary: '#000000',
    secondary: '#85888D',
    disabled: '#BABDC4',
  },
  violet: {
    main: '#5A40FC',
    80: '#7B66FD',
    40: '#9289FF',
    20: '#CBCAFE',
    10: '#F5F4FF',
  },
  orange: {
    main: '#FFA726',
  },
  link: {
    main: '#356df3',
  },
};

export const mainPalette: Palette = createPalette(mainPaletteOptions);
