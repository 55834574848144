import { Typography } from '@mui/material';
import { useHeaderStyles } from './useHeaderStyles';
import { t } from '../../../../../utils/intl';

export const Header = (): JSX.Element => {
  const { classes } = useHeaderStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h2">
        {t('smart-contracts.manager.title')}
      </Typography>
      <Typography className={classes.secondaryColor}>
        {t('smart-contracts.manager.description')}
      </Typography>
    </div>
  );
};
