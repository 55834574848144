import { COLLECTION_TILE_MAX_WIDTH } from 'modules/nft-manager/const';
import { makeStyles } from 'tss-react/mui';

const transitionsConfig = {
  duration: 350,
  easing: 'ease',
};

export const useCollectionTileStyles = makeStyles<undefined, 'root'>()(
  (theme, args, classes) => ({
    root: {
      width: COLLECTION_TILE_MAX_WIDTH,
      height: 356,
      margin: theme.spacing(2.5),
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16,
      overflow: 'hidden',
      position: 'relative',
      transition: theme.transitions.create(
        ['box-shadow', 'transform'],
        transitionsConfig,
      ),

      '&:hover': {
        border: `1px solid ${theme.palette.grey[900]}`,
      },
    },

    image: {
      height: '55%',
      width: '100%',
    },

    info: {
      padding: theme.spacing(3.5, 5, 5),
    },

    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    name: {
      marginBottom: theme.spacing(1.5),
    },

    count: {
      marginBottom: theme.spacing(8),
    },

    footer: {},

    item: {
      display: 'flex',
      justifyContent: 'space-between',
    },

    stats: {
      transition: theme.transitions.create(['opacity'], transitionsConfig),

      [`.${classes.root}:hover &`]: {
        opacity: 0,
      },
    },

    buttons: {
      position: 'absolute',
      right: theme.spacing(4),
      left: theme.spacing(4),
      bottom: theme.spacing(13),

      height: 40,

      opacity: 0,
      transform: `translateY(20px)`,
      transition: theme.transitions.create(
        ['opacity', 'transform'],
        transitionsConfig,
      ),

      [`.${classes.root}:hover &`]: {
        opacity: 1,
        transform: 'translateY(0)',
      },
    },

    button: {
      color: theme.palette.common.white,
      marginBottom: theme.spacing(4),
    },

    link: {
      color: theme.palette.text.primary,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    icon: {
      marginLeft: theme.spacing(1.5),
      width: 16,
      height: 16,
    },
  }),
);
