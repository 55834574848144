import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  RadioGroup,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '../../../../uiKit/components/Button';
import { Roles } from '../../../common/types';
import { validateEmail } from '../../../common/utils/validateEmail';
import { t, tHTML } from '../../../utils/intl';
import { RoleCard } from './RoleCard';
import { IUserBaseInfo, useInviteUserForm } from './useInviteUserForm';
import { useInviteUserStyles } from './useInviteUserStyles';
import { useProjectInfoForInviteData } from '../ProjectInfo/useProjectInfoForInviteData';

export const InviteUser = (): JSX.Element => {
  const { classes, cx } = useInviteUserStyles();
  const { isLoading, onSubmit } = useInviteUserForm();
  const {
    name,
    organizationId,
    isLoading: gameProjectLoading,
  } = useProjectInfoForInviteData();

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<IUserBaseInfo>({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      email: '',
    },
  });

  const onPrepareSubmit = (data: IUserBaseInfo): void => {
    if (isValid) {
      onSubmit(data, organizationId);
    }
  };

  return (
    <form
      className={classes.root}
      onSubmit={handleSubmit(onPrepareSubmit)}
      noValidate
    >
      <Typography variant="h2">{t('projects.invite.title')}</Typography>
      <Typography
        className={cx(classes.secondaryColor, classes.mb10)}
        variant="h2"
      >
        {t('projects.invite.title-to', {
          projectName: gameProjectLoading ? '...' : name,
        })}
      </Typography>

      <Controller
        name="email"
        control={control}
        rules={{
          required: t('validation.required'),
          validate: v => {
            return !!validateEmail(v) || t('validation.invalid-email');
          },
        }}
        render={({ field, fieldState }) => {
          const errorText = fieldState.error?.message;
          return (
            <FormControl className={classes.mb10}>
              <InputLabel>{t('projects.invite.email')}</InputLabel>
              <Input
                {...field}
                fullWidth
                error={!!fieldState.error}
                inputProps={{ maxLength: 50 }}
                onChange={field.onChange}
              />
              {!!errorText && (
                <FormHelperText error>{errorText}</FormHelperText>
              )}
            </FormControl>
          );
        }}
      />

      <Typography className={classes.mb2} variant="h3">
        {t('projects.invite.assign-role')}
      </Typography>

      <Typography
        className={cx(classes.secondaryColor, classes.mb6)}
        variant="body1"
      >
        {tHTML('projects.invite.roles-description', {
          href: ' ',
        })}
      </Typography>

      <Controller
        name="role"
        control={control}
        rules={{
          required: t('validation.required'),
          validate: v => {
            return !!v || v === 0 || t('validation.required');
          },
        }}
        render={({ field, fieldState }) => {
          const errorText = fieldState.error?.message;
          return (
            <FormControl className={classes.mb10}>
              <RadioGroup value={field.value} onChange={field.onChange}>
                <RoleCard
                  roleName={tHTML('projects.invite.admin')}
                  roleDescription={tHTML('projects.invite.admin-description')}
                  value={Roles.Admin}
                  isActive={field.value === Roles.Admin}
                  isError={!!fieldState.error}
                />
                <RoleCard
                  roleName={tHTML('projects.invite.user')}
                  roleDescription={tHTML('projects.invite.user-description')}
                  value={Roles.User}
                  isActive={field.value === Roles.User}
                  isError={!!fieldState.error}
                />
              </RadioGroup>
              {!!errorText && (
                <FormHelperText error>{errorText}</FormHelperText>
              )}
            </FormControl>
          );
        }}
      />

      <Button
        isLoading={isLoading}
        type="submit"
        variant="contained"
        size="large"
        className={classes.button}
      >
        {t('projects.invite.invite')}
      </Button>
    </form>
  );
};
