import {
  COMMON_ERROR,
  configFromEnv,
  GET_POST_NFT_LISTING,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
} from '../../api';
import { oryLoginAsync } from 'modules/auth';
import { RESPONSE_CODES } from '../../common/types';

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

interface INftListingData {
  name: string;
  nftTokenId: string;
  nftChainTokenId: string;
  tokenId: string;
  listingStatus: number;
  price: string;
  chainCode: string;
}

interface IReponse extends INftListingData {
  id: string;
}

export const { usePublishOnPlazaMutation } = web3Api.injectEndpoints({
  endpoints: build => ({
    publishOnPlaza: build.mutation<IReponse, INftListingData>({
      queryFn: queryFnNotifyWrapper<INftListingData, never, IReponse>(
        async (data: INftListingData) => {
          const request = await fetch(nftManagerUrl + GET_POST_NFT_LISTING, {
            method: 'POST',
            body: JSON.stringify([
              {
                name: data.name,
                nftTokenId: data.nftTokenId,
                nftChainTokenId: data.nftChainTokenId,
                tokenId: data.tokenId,
                listingStatus: data.listingStatus,
                price: data.price,
                chainCode: data.chainCode,
              },
            ]),
            headers: new Headers({
              'Content-Type': 'application/json;charset=utf-8',
            }),
            credentials: 'include',
          });

          if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
            await oryLoginAsync();
            throw UNAUTHORIZED_ERROR;
          }
          if (!request.ok) {
            throw COMMON_ERROR;
          }
          return { data: await request.json() };
        },
      ),
      async onQueryStarted(args, { queryFulfilled }) {
        return queryFulfilled.then(response => {
          // TODO: change it after demo
          window.open(
            `https://stage.plaza.mirage.xyz/plaza/collectibles`,
            '_blank',
            'noreferrer',
          );
          // window.open(
          //   `https://stage.plaza.mirage.xyz/plaza/collectibles/info?id=${args.nftTokenId}`,
          //   '_blank',
          //   'noreferrer',
          // );
        });
      },
    }),
  }),
});
