import { makeStyles } from 'tss-react/mui';

export const useTabsStyles = makeStyles()(theme => ({
  tabs: {
    display: 'flex',
    flexDirection: 'row',
  },

  tabSelected: {
    '&.Mui-selected': {
      borderBottom: `4px solid ${theme.palette.violet.main}`,
    },
  },

  tabArea: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: theme.spacing(4.5),
    padding: 0,
    minWidth: 0,

    '&:first-of-type': {
      marginLeft: 0,
    },
  },

  itemWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  tabText: {
    cursor: 'pointer',
  },

  tabActive: {
    cursor: 'default',
  },
}));
