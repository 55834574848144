import { makeStyles } from 'tss-react/mui';

export const useDescriptionItemStyles = makeStyles()(theme => ({
  row: {
    display: 'flex',
    padding: theme.spacing(3, 5),
    borderBottom: `1px solid ${theme.palette.grey[500]}`,

    '&:last-of-type': {
      borderBottom: 'unset',
      marginBottom: theme.spacing(13),
    },
  },
  pb: {
    paddingBottom: theme.spacing(5),
  },
  item: {
    paddingRight: theme.spacing(12),
  },
}));
