import { makeStyles } from 'tss-react/mui';

export const useFooterStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },

  logo: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    color: theme.palette.grey[900],
  },

  links: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },

  link: {
    color: theme.palette.grey[900],
  },
}));
