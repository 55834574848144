import { makeStyles } from 'tss-react/mui';

export const useInviteUserStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 544,
    marginBottom: theme.spacing(5),
  },
  secondaryColor: {
    color: theme.palette.text.secondary,
  },
  mb1: {
    marginBottom: theme.spacing(1),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  mr2: {
    marginRight: theme.spacing(2),
  },
  mb4: {
    marginBottom: theme.spacing(4),
  },
  mb6: {
    marginBottom: theme.spacing(6),
  },
  mb10: {
    marginBottom: theme.spacing(10),
  },
  verticalAlign: {
    display: 'flex',
    alignItems: 'center',
  },
  roleCardRoot: {
    borderRadius: 16,
    border: `1px solid ${theme.palette.grey[500]}`,
    padding: theme.spacing(5),
    marginBottom: theme.spacing(4),
    display: 'flex',
    boxShadow: 'none',
    cursor: 'pointer',
  },
  error: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  active: {
    border: `1px solid ${theme.palette.violet.main}`,
  },
  radioWrapper: {
    marginRight: theme.spacing(2),
  },
  button: {
    width: 200,
  },
}));
