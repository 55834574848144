import { oryLoginAsync } from 'modules/auth';
import {
  COMMON_ERROR,
  configFromEnv,
  GET_POST_NFT_TOKEN,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
} from '../../api';
import { RESPONSE_CODES } from '../../common/types';
import { NftCacheTags } from '../const';
import { INftInfoData } from '../types';

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

interface IGetNftArgs {
  id: string;
}

export const { useGetNftQuery } = web3Api.injectEndpoints({
  endpoints: build => ({
    getNft: build.query<INftInfoData, IGetNftArgs>({
      queryFn: queryFnNotifyWrapper<IGetNftArgs, never, INftInfoData>(
        async ({ id }: IGetNftArgs) => {
          const request = await fetch(nftManagerUrl + GET_POST_NFT_TOKEN + id, {
            method: 'GET',
            credentials: 'include',
          });

          if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
            await oryLoginAsync();
            throw UNAUTHORIZED_ERROR;
          }
          if (!request.ok) throw COMMON_ERROR;

          return { data: await request.json() };
        },
      ),
      providesTags: (res, err, args) => [NftCacheTags.collectionData + args.id],
    }),
  }),
});

export const { useLazyGetNftQuery } = web3Api.injectEndpoints({
  endpoints: build => ({
    getNft: build.query<INftInfoData, IGetNftArgs>({
      queryFn: queryFnNotifyWrapper<IGetNftArgs, never, INftInfoData>(
        async ({ id }: IGetNftArgs) => {
          const request = await fetch(nftManagerUrl + GET_POST_NFT_TOKEN + id, {
            method: 'GET',
            credentials: 'include',
          });

          if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
            await oryLoginAsync();
            throw UNAUTHORIZED_ERROR;
          }
          if (!request.ok) throw COMMON_ERROR;

          return { data: await request.json() };
        },
      ),
      providesTags: (res, err, args) => [NftCacheTags.collectionData + args.id],
    }),
  }),
});
