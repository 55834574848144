import { Tab as MuiTab, TabProps, Typography } from '@mui/material';

import { useTabsStyles } from './useTabsStyles';

interface ITabProps extends Omit<TabProps, 'className' | 'classes' | 'label'> {
  title: string;
  value: string;
  activeTab: string;
  amount?: number;
}

export const Tab = ({
  title,
  value,
  activeTab,
  amount,
  ...restProps
}: ITabProps): JSX.Element => {
  const { classes, cx } = useTabsStyles();

  return (
    <MuiTab
      {...restProps}
      classes={{ root: classes.tabArea, selected: classes.tabSelected }}
      className={classes.tabArea}
      label={
        <div className={classes.itemWrapper}>
          <Typography
            className={cx(
              classes.tabText,
              activeTab === value && classes.tabActive,
            )}
            color={activeTab === value ? 'textPrimary' : 'textSecondary'}
            variant="h3"
          >
            {title}
          </Typography>
        </div>
      }
      value={value}
    />
  );
};
