import {
  IPublishedContractItem,
  useLazyGetPublishedContractsQuery,
} from '../../actions/useLazyGetPublishedContractsQuery';
import { useEffect, useState } from 'react';

interface IUsePublishedContractsData {
  data?: IPublishedContractItem[];
  totalContractsCount: number;
  isLoading: boolean;
  page: number;
  totalPages: number;
  changePage: (value: number) => void;
}

export const usePublishedContractsData = (): IUsePublishedContractsData => {
  const [page, setPage] = useState<number>(1);

  const [getPublishedContracts, { data, isFetching }] =
    useLazyGetPublishedContractsQuery();

  useEffect(() => {
    getPublishedContracts({
      pageNumber: page,
    });
  }, [getPublishedContracts, page]);

  const changePage = (page: number) => {
    setPage(page);
  };

  return {
    data: data?.items ?? [],
    totalContractsCount: data?.totalCount ?? 0,
    totalPages: data?.totalPages ?? 1,
    page,
    isLoading: isFetching,
    changePage,
  };
};
