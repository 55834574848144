import { Components } from '@mui/material';

export const getMuiAccordion = (): Components['MuiAccordion'] => {
  return {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        borderRadius: 16,
        padding: 8,
        marginTop: 12,

        '&:before': {
          display: 'none',
        },

        '&.Mui-expanded': {
          marginBottom: 0,
          marginTop: 12,
        },

        '&:first-of-type': {
          borderRadius: 16,
        },
      },
    },
  };
};
