import { Navigate, Outlet, Route, Routes as Switch } from 'react-router-dom';
import { LoaderCentered } from './modules/common/components/Loader';
// import { getRoutes as getDashboardRoutes } from './modules/pages/Dashboard/Routes';
// import {
//   getRoutes as getLoginRoutes,
//   RoutesConfig as LoginRoutesConfig,
// } from './modules/pages/Login/Routes';
import {
  getRoutes as getProjectsRoutes,
  RoutesConfig as ProjectsRoutesConfig,
} from './modules/projects/Routes';
import { getRoutes as getSmartContractRoutes } from './modules/smart-contracts/Routes';
import { getRoutes as getNftManagerRoutes } from './modules/nft-manager/Routes';
import { featuresConfig } from 'modules/common/featuresConfig';
import { useOrySession } from 'modules/common/components/OryLifecycleProvider';

export function Routes(): JSX.Element {
  var orySession = useOrySession();
  return orySession?.active ? (
    <Switch>
      <Route path="/" element={<Outlet />}>
        {getProjectsRoutes()}

        {getSmartContractRoutes()}

        {featuresConfig.isActiveNFTManager && getNftManagerRoutes()}

        {/* {getDashboardRoutes()} */}

        <Route
          path="/"
          element={
            <>
              <Navigate to={ProjectsRoutesConfig.projects.path} replace />
            </>
          }
        />
        <Route
          path="*"
          element={
            <>
              <Navigate to={ProjectsRoutesConfig.projects.path} replace />
            </>
          }
        />
      </Route>
    </Switch>
  ) : (
    <Switch>
      <Route path="/projects" element={<LoaderCentered fullHeight />}>
        {/* <Route
          element={
            <>
              <Navigate to={LoginRoutesConfig.signin.generatePath()} replace />
            </>
          }
          path="*"
        /> */}
      </Route>
    </Switch>
  );
}
