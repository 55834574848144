import { LoaderCentered } from 'modules/common/components/Loader';
import { Pagination } from 'modules/common/components/Pagination';
import { RootWrapper } from 'modules/nft-manager/components/RootWrapper';
import { Analytics } from '../../components/Analytics';
import { Empty } from './components/Empty';
import { Header } from './components/Header';
import { List } from './components/List';
import { ListHeader } from './components/ListHeader';
import { useCollectionsListData } from './useCollectionsListData';

export const CollectionsList = (): JSX.Element => {
  const {
    data,
    totalPages,
    page,
    isLoading,
    searchValue,
    nftSold,
    usdSold,
    timeFrame,
    handleChangeTimeFrame,
    setSearch,
    changePage,
  } = useCollectionsListData();

  if (isLoading) return <LoaderCentered />;

  const isEmptyList = !data || data.length === 0;

  return (
    <RootWrapper>
      <Header isEmptyList={isEmptyList} />

      {isEmptyList && (
        <>
          <ListHeader
            collectionsCount={0}
            filter="test"
            sort="test"
            searchValue={searchValue}
            setSearch={setSearch}
          />
          <Empty />
        </>
      )}

      {!isEmptyList && (
        <>
          <Analytics
            nftSold={nftSold}
            usdSold={usdSold}
            timeFrame={timeFrame}
            handleChangeTimeFrame={handleChangeTimeFrame}
          />

          <ListHeader
            collectionsCount={data.length}
            filter="test"
            sort="test"
            searchValue={searchValue}
            setSearch={setSearch}
          />

          <List data={data} />

          {totalPages > 1 && (
            <Pagination
              page={page}
              totalPages={totalPages}
              changePage={changePage}
            />
          )}
        </>
      )}
    </RootWrapper>
  );
};
