import { ACTION_CACHE_SEC } from 'modules/common/const';
import { NftMintNetwork } from 'modules/common/types';
import { useGetNftContractsQuery } from 'modules/nft-manager/actions/useGetNftContractsQuery';
import { useLazyGetCollectionsListQuery } from 'modules/nft-manager/actions/useLazyGetCollectionsListQuery';
import { useLazyGetNftContractMintDataQuery } from 'modules/nft-manager/actions/useLazyGetNftContractMintDataQuery';
import { useGetNftTemplatesQuery } from 'modules/nft-manager/actions/useGetNftTemplatesQuery';
import { useMintNftMutation } from 'modules/nft-manager/actions/useMintNftMutation';
import { usePostNftMutation } from 'modules/nft-manager/actions/usePostNftMutation';
import { usePutNftMutation } from 'modules/nft-manager/actions/usePutNftMutation';
import {
  IAttribute,
  ICollectionItem,
  INftMintedData,
  NftStatus,
  TokenStandart,
} from 'modules/nft-manager/types';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDialog } from 'modules/common/hooks';
import { useLazyGetNftQuery } from 'modules/nft-manager/actions/useGetNftQuery';
import { push } from '@lagunovsky/redux-react-router';
import { RoutesConfig } from 'modules/nft-manager/Routes';
import { useDispatch } from 'react-redux';
import { notify } from 'modules/common/components/Notifications';
import { t, tHTML } from 'modules/utils/intl';
import { uid } from 'react-uid';
import { getIsMetaMaskInjected } from '@ankr.com/provider';
import { useLazyVerifyWalletQuery } from 'modules/nft-manager/actions/useLazyVerifyWalletQuery';
import { usePublishOnPlazaMutation } from 'modules/nft-manager/actions/usePublishOnPlazaMutation';
import { useLazyGetIsWalletConnectedQuery } from 'modules/nft-manager/actions/useLazyGetIsWalletConnectedQuery';
import { useDeleteNftTokenMutation } from 'modules/nft-manager/actions/useDeleteNftTokenMutation';

export interface INewNftData {
  name: string;
  description?: string;
  image?: File;
  network?: NftMintNetwork;
  isEth: boolean;
  isBsc: boolean;
  isPolygon: boolean;
  isArb: boolean;
  collectionId: string;
  attributes: IAttribute[];
  tokenId: number;
  royalty: number;
  instances: number;
  isExplicitAndSensitiveContent: boolean;
}

interface IMintNftFormData {
  isLoading: boolean;
  isMintLoading: boolean;
  collections: ICollectionItem[];
  token?: string;
  nftMintedData?: INftMintedData;
  isOpenedSuccessModal: boolean;
  isOpenedMetamaskWarning: boolean;
  isPublishOnPlazaLoading: boolean;
  onCloseMetamaskWarning: () => void;
  onCloseSuccessModal: () => void;
  handleClickLaunch: (price: string) => void;
  onSubmit: (data: INewNftData) => Promise<void>;
}

export const useMintNftForm = (): IMintNftFormData => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const isMetamaskExists = getIsMetaMaskInjected();

  const {
    isOpened: isSuccessOpened,
    onOpen: onSuccessOpen,
    onClose: onSuccessClose,
  } = useDialog();
  const {
    isOpened: isMetamaskOpened,
    onOpen: onMetamaskOpen,
    onClose: onMetamaskClose,
  } = useDialog();

  const { data: nftContractsData } = useGetNftContractsQuery(undefined, {
    refetchOnMountOrArgChange: ACTION_CACHE_SEC,
  });
  const [getCollectionsList, { data, isFetching: isCollectionsListLoading }] =
    useLazyGetCollectionsListQuery();
  const { data: nftTemplates, isFetching: isNftTemplatesLoading } =
    useGetNftTemplatesQuery(undefined, {
      refetchOnMountOrArgChange: ACTION_CACHE_SEC,
    });
  const [getNftData, { data: mintedNftData }] = useLazyGetNftQuery();
  const [verifyWallet] = useLazyVerifyWalletQuery();
  const [getIsWalletConnected] = useLazyGetIsWalletConnectedQuery();

  const [getNftMintData, { isLoading: isGetNftMintDataLoading }] =
    useLazyGetNftContractMintDataQuery();

  const [putNewNftData, { isLoading: isPutNftLoading }] = usePutNftMutation();
  const [postNft, { isLoading: isPostNftLoading }] = usePostNftMutation();
  const [deleteNft] = useDeleteNftTokenMutation();
  const [mintNft, { isLoading: isMintNftLoading }] = useMintNftMutation();
  const [publishOnPlaza, { isLoading: isPublishOnPlazaLoading }] =
    usePublishOnPlazaMutation();

  const handleCloseModal = useCallback(() => {
    onSuccessClose();
    if (!!mintedNftData) {
      dispatch(
        push(
          RoutesConfig.collection.generatePath(mintedNftData.nftCollectionId),
        ),
      );
      notify({
        message: tHTML('notifications.nft-minted-successfully'),
        type: 'info',
        key: uid('minted'),
      });
    }
  }, [dispatch, mintedNftData, onSuccessClose]);

  useEffect(() => {
    getCollectionsList({});
  }, [getCollectionsList]);

  useEffect(() => {
    if (!isMetamaskExists) {
      onMetamaskOpen();
    }
  }, [isMetamaskExists, onMetamaskOpen]);

  const handleMintNft = useCallback(
    async (data: INewNftData) => {
      if (!isMetamaskExists) {
        notify({
          message: t('unexists-metamask.title'),
          type: 'error',
          key: 'metamask',
        });
        return;
      }
      const chainCodes: NftMintNetwork[] = data.network
        ? [data.network]
        : [NftMintNetwork.SepETH];
      // if (data.isEth) chainCodes.push(NftMintNetwork.SepETH);
      // if (data.isPolygon) chainCodes.push(NftMintNetwork.TestnetPolygon);
      // if (data.isBsc) chainCodes.push(NftMintNetwork.BSC);

      const isVerifiedWalletAddress = await getIsWalletConnected().unwrap();

      const verificationData = isVerifiedWalletAddress
        ? null
        : await verifyWallet();

      chainCodes.forEach(async chain => {
        if (!!data.image) {
          const template = token
            ? nftTemplates?.find(x =>
                x.name.includes(token.replaceAll('-', '')),
              )?.id
            : '';
          const nftContracts = nftContractsData?.filter(
            x => x.nftTemplateId === template,
          );

          // TODO: rewrite logic to multiple networks
          const myNftContractId = nftContracts?.find(
            x => x.chainCode === chainCodes[0],
          )?.id;

          if (isVerifiedWalletAddress || !!verificationData?.isSuccess) {
            const postNftData = await postNft({
              Content: data.image,
              Status: 2,
              Uri: '',
              Price: 0,
              Name: data.name,
              Description: data.description ?? '',
              Quantity: data.instances,
              // TODO: add royalty
              NftContractId: myNftContractId ?? '',
              Royalty: data.royalty,
              ChainCodes: [chainCodes[0]],
              NftCollectionId: data.collectionId,
              NftProperties:
                data.attributes.length > 0
                  ? data.attributes.map(x => ({
                      name: x.name,
                      value: x.value,
                      type: 'string',
                    }))
                  : '',
            }).unwrap();

            const getMintNftData = await getNftMintData({
              NftTokenId: postNftData.id,
              NftContractId: myNftContractId ?? '',
              ChainCode: chainCodes[0],
            }).unwrap();

            try {
              const mintData = await mintNft({
                address: getMintNftData.contractAddress,
                uri: getMintNftData.uri,
                signature: getMintNftData.signature,
                tokenId: data.tokenId,
                instances: data.instances,
                network: chainCodes[0],
                tokenStandart: (token as TokenStandart) ?? TokenStandart.ERC721,
              }).unwrap();

              await putNewNftData({
                id: postNftData.id,
                collectionId: data.collectionId,
                transactionHash: mintData.txHash,
                contractAddress: getMintNftData.contractAddress,
              }).unwrap();

              await getNftData({
                id: postNftData.id,
              })
                .unwrap()
                .then(() => onSuccessOpen());
            } catch {
              deleteNft({ id: postNftData.id });
            }
          }
        }
      });
    },
    [
      isMetamaskExists,
      getIsWalletConnected,
      verifyWallet,
      token,
      nftTemplates,
      nftContractsData,
      postNft,
      getNftMintData,
      mintNft,
      putNewNftData,
      getNftData,
      onSuccessOpen,
      deleteNft,
    ],
  );

  // TODO: add request to configuration service to get id of token (eth/matic/...)
  const handleClickLaunch = useCallback(
    async (price: string) => {
      const ETH_ID = 'd77e381e-08d9-4bca-ab83-5b673b88f7ec';
      const MATIC_ID = 'c025660c-1ff8-4b89-a2d5-2d082bd4d0fa';

      const network = !!mintedNftData?.chainCode
        ? mintedNftData?.chainCode
        : NftMintNetwork.SepETH;

      const nftUpdatedData = await getNftData({
        id: mintedNftData?.id ?? '',
      });

      const chainTokenId = nftUpdatedData?.data?.tokenId ?? '';

      if (!!chainTokenId) {
        publishOnPlaza({
          name: mintedNftData?.name ?? '',
          nftTokenId: mintedNftData?.id ?? '',
          nftChainTokenId: nftUpdatedData?.data?.tokenId ?? '',
          tokenId: network === NftMintNetwork.SepETH ? ETH_ID : MATIC_ID,
          listingStatus: NftStatus.LISTED,
          price: price,
          chainCode: network,
        })
          .unwrap()
          .then(() => handleCloseModal());
      } else {
        notify({
          message: tHTML('notifications.ntf-token-wait'),
          type: 'warning',
          key: uid('waiting'),
        });
      }
    },
    [
      mintedNftData?.chainCode,
      mintedNftData?.id,
      mintedNftData?.name,
      getNftData,
      publishOnPlaza,
      handleCloseModal,
    ],
  );

  return {
    isLoading: isCollectionsListLoading || isNftTemplatesLoading,
    isMintLoading:
      isGetNftMintDataLoading ||
      isPostNftLoading ||
      isMintNftLoading ||
      isPutNftLoading,
    token,
    collections: data?.items ?? [],
    isOpenedSuccessModal: isSuccessOpened,
    nftMintedData: !!mintedNftData
      ? {
          collectionId: mintedNftData.nftCollectionId ?? '',
          address: mintedNftData.contractAddress,
          name: mintedNftData?.name ?? '',
          fee: 2.5,
          imageSrc: !!mintedNftData.nftAsset
            ? mintedNftData.nftAsset.fileUri
            : '',
          tokenId: mintedNftData.tokenId,
          quantity: mintedNftData.quantity,
          network: !!mintedNftData.chainCode
            ? (mintedNftData.chainCode as NftMintNetwork)
            : NftMintNetwork.SepETH,
        }
      : undefined,
    isOpenedMetamaskWarning: isMetamaskOpened,
    isPublishOnPlazaLoading,
    onCloseMetamaskWarning: onMetamaskClose,
    onCloseSuccessModal: handleCloseModal,
    handleClickLaunch,
    onSubmit: handleMintNft,
  };
};
