import { generatePath, Route } from 'react-router-dom';
import { Layout } from '../common/components/Layout';
import { createRouteConfig, useQueryParams } from '../router';
import { CollectionInfo } from './screens/CollectionInfo';
import { CollectionsList } from './screens/CollectionsList';
import { CreateCollection } from './screens/CreateCollection';
import { MintNft } from './screens/MintNft';
import { NftInfo } from './screens/NftInfo';
import { TokenStandart } from './types';

export const ROOT_PATH = '/nft/';
const COLLECTIONS_LIST_PATH = `${ROOT_PATH}collections/`;
const COLLECTION_INFO_PATH = `${COLLECTIONS_LIST_PATH}:id/`;
const CREATE_COLLECTION_PATH = `${ROOT_PATH}create-collection/`;
const NFT_INFO_PATH = `${ROOT_PATH}:id/`;
const NFT_MINT_PATH = `${ROOT_PATH}mint/:token/`;

export const RoutesConfig = createRouteConfig(
  {
    collectionsList: {
      path: COLLECTIONS_LIST_PATH,
      generatePath: () => generatePath(COLLECTIONS_LIST_PATH),
    },

    collection: {
      path: COLLECTION_INFO_PATH,
      generatePath: (id?: string) => {
        return id
          ? generatePath(COLLECTION_INFO_PATH, { id })
          : generatePath(ROOT_PATH);
      },
      useParams: () => {
        return {
          id: useQueryParams().get('id') ?? undefined,
        };
      },
    },

    createCollection: {
      path: CREATE_COLLECTION_PATH,
      generatePath: () => generatePath(CREATE_COLLECTION_PATH),
    },

    nft: {
      path: NFT_INFO_PATH,
      generatePath: (id?: string) => {
        return id
          ? generatePath(NFT_INFO_PATH, { id })
          : generatePath(ROOT_PATH);
      },
      useParams: () => {
        return {
          id: useQueryParams().get('id') ?? undefined,
        };
      },
    },

    mintNft: {
      path: NFT_MINT_PATH,
      generatePath: (token?: TokenStandart) => {
        return token
          ? generatePath(NFT_MINT_PATH, { token })
          : generatePath(ROOT_PATH);
      },
      useParams: () => {
        return {
          id: useQueryParams().get('token') ?? undefined,
        };
      },
    },
  },

  ROOT_PATH,
);

export function getRoutes(): JSX.Element {
  return (
    <>
      <Route
        element={
          <Layout>
            <CollectionsList />
          </Layout>
        }
        path={RoutesConfig.collectionsList.path}
      />

      <Route
        element={
          <Layout>
            <CreateCollection />
          </Layout>
        }
        path={RoutesConfig.createCollection.path}
      />

      <Route
        element={
          <Layout>
            <CollectionInfo />
          </Layout>
        }
        path={RoutesConfig.collection.path}
      />

      <Route
        element={
          <Layout>
            <NftInfo />
          </Layout>
        }
        path={RoutesConfig.nft.path}
      />

      <Route
        element={
          <Layout>
            <MintNft />
          </Layout>
        }
        path={RoutesConfig.mintNft.path}
      />
    </>
  );
}
