import { ICollectionItem } from 'modules/nft-manager/types';
import { uid } from 'react-uid';
import { CollectionTile } from 'modules/nft-manager/components/CollectionTile';
import { useListStyles } from './useListStyles';

interface IListProps {
  data: ICollectionItem[];
}

export const List = ({ data }: IListProps): JSX.Element => {
  const { classes } = useListStyles();

  return (
    <div className={classes.root}>
      <div className={classes.wrap}>
        {data.map(item => (
          <CollectionTile key={uid(item)} {...item} />
        ))}
      </div>
    </div>
  );
};
