import { push } from '@lagunovsky/redux-react-router';
import {
  COMMON_ERROR,
  configFromEnv,
  POST_USER_INVITE,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
} from '../../api';
import { oryLoginAsync } from 'modules/auth';
import { RESPONSE_CODES } from '../../common/types';
import { ProjectCacheTags } from '../const';
import { INewUser } from '../screens/InviteUser/useInviteUserForm';
import { RoutesConfig } from '../Routes';

const {
  gatewayConfig: { mirageIdUrl },
} = configFromEnv();

export const { useInviteUserMutation } = web3Api.injectEndpoints({
  endpoints: build => ({
    inviteUser: build.mutation<boolean, INewUser>({
      queryFn: queryFnNotifyWrapper<INewUser, never, boolean>(
        async (data: INewUser) => {
          const request = await fetch(mirageIdUrl + POST_USER_INVITE, {
            method: 'POST',
            body: JSON.stringify({
              email: data.email,
              role: parseInt(data.role as string),
              gameProjectId: data.gameProjectId,
              organizationId: data.organizationId,
            }),
            headers: new Headers({
              'Content-Type': 'application/json;charset=utf-8',
            }),
            credentials: 'include',
          });

          if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
            await oryLoginAsync();
            throw UNAUTHORIZED_ERROR;
          }
          if (!request.ok) throw COMMON_ERROR;

          return { data: true };
        },
      ),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        return queryFulfilled.then(() => {
          dispatch(push(RoutesConfig.project.generatePath(args.gameProjectId)));
        });
      },
      invalidatesTags: (res, err, args) => [
        ProjectCacheTags.projectData + args.gameProjectId,
      ],
    }),
  }),
});
