import { LoaderCentered } from 'modules/common/components/Loader';
import { Empty } from './components/Empty';
import { Header } from './components/Header';
import { List } from './components/List';
import { Pagination } from 'modules/common/components/Pagination/Pagination';
import { NotFound } from './components/NotFound';
import { useProjectListData } from './useProjectListData';

export const ProjectList = (): JSX.Element => {
  const {
    projects,
    totalProjectsCount,
    page,
    totalPages,
    isLoading,
    searchValue,
    searchDelay,
    changePage,
    setSearch,
  } = useProjectListData();

  return (
    <>
      <Header
        searchValue={searchValue}
        setSearch={setSearch}
        searchDelay={searchDelay}
        projectsCount={totalProjectsCount}
      />

      {isLoading && <LoaderCentered />}

      {!isLoading && searchValue && projects?.length === 0 && (
        <NotFound searchValue={searchValue} />
      )}

      {!isLoading &&
        !searchValue &&
        !searchDelay &&
        (!projects || projects.length === 0) && <Empty />}

      {!isLoading && !!projects?.length && projects.length > 0 && (
        <>
          <List activeProjects={projects} />

          {totalPages > 1 && (
            <Pagination
              page={page}
              totalPages={totalPages}
              changePage={changePage}
            />
          )}
        </>
      )}
    </>
  );
};
