import { ButtonBase, Input, Typography } from '@mui/material';
import { ReactComponent as SearchIcon } from 'uiKit/icons/search.svg';
import { ReactComponent as CloseIcon } from 'uiKit/icons/close-small.svg';
import { ReactText } from 'modules/common/types';
import { t } from 'modules/utils/intl';
import { useHeaderStyles } from './useHeaderStyles';
import { Badge } from 'modules/common/components/Badge';

interface Props {
  projectsCount: number;
  className?: string;
  searchValue: ReactText;
  searchDelay: boolean;
  setSearch: (value: ReactText) => void;
}

export const Header = ({
  projectsCount,
  className,
  searchValue,
  searchDelay,
  setSearch,
}: Props): JSX.Element => {
  const { classes, cx } = useHeaderStyles();
  const isExistProjects = projectsCount > 0;

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.titles}>
        <div className={classes.firstTitle}>
          <Typography variant="h2">
            {isExistProjects || !!searchValue || searchDelay
              ? t('projects.header.title')
              : t('projects.header.welcome-title')}
          </Typography>
        </div>
        <div className={classes.secondTitle}>
          <Typography variant="h2">
            {t('projects.header.mirage-interactive')}
          </Typography>
          <Badge color="grey" text={t('projects.header.essential')} />
        </div>
      </div>
      {(searchValue || isExistProjects) && (
        <div className={classes.inputWrapper}>
          <SearchIcon />
          <Input
            className={classes.input}
            value={searchValue}
            inputProps={{ maxLength: 30 }}
            onChange={e => setSearch(e.target.value)}
            placeholder={t('projects.header.search')}
            endAdornment={
              searchValue && (
                <ButtonBase onClick={() => setSearch('')}>
                  <CloseIcon className={classes.closeIcon} />
                </ButtonBase>
              )
            }
          />
        </div>
      )}
    </div>
  );
};
