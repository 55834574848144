import {
  COMMON_ERROR,
  configFromEnv,
  CONTRACT_VERSION,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
} from 'modules/api';
import { oryLoginAsync } from 'modules/auth';
import { Network, RESPONSE_CODES } from 'modules/common/types';

interface IContractVersionResponse {
  contractFiles: unknown;
  contractStandard: number;
  id: string;
  name: string;
  state: number;
}

interface ICreateContractVersionArgs {
  name: string;
  contractStandart: Network;
}

const {
  gatewayConfig: { gamingUrl },
} = configFromEnv();

export const { useCreateContractVersionMutation } = web3Api.injectEndpoints({
  endpoints: build => ({
    createContractVersion: build.mutation<
      IContractVersionResponse,
      ICreateContractVersionArgs
    >({
      queryFn: queryFnNotifyWrapper<
        ICreateContractVersionArgs,
        never,
        IContractVersionResponse
      >(async (data: ICreateContractVersionArgs) => {
        const request = await fetch(gamingUrl + CONTRACT_VERSION, {
          method: 'POST',
          body: JSON.stringify({
            name: data.name,
            contractStandart: data.contractStandart,
          }),
          headers: new Headers({
            'Content-Type': 'application/json;charset=utf-8',
          }),
          credentials: 'include',
        });

        if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
          await oryLoginAsync();
          throw UNAUTHORIZED_ERROR;
        }
        if (!request.ok) throw COMMON_ERROR;

        return {
          data: await request.json(),
        };
      }),
    }),
  }),
});
