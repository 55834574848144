import { makeStyles } from 'tss-react/mui';

export const useLayoutStyles = makeStyles()(theme => ({
  root: {
    display: 'grid',
    minHeight: '100vh',
    gridTemplateAreas: `
      "sidebar header"
      "sidebar content";
    `,
    gridTemplateRows: `64px 1fr`,
    gridTemplateColumns: `250px 1fr`,
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    gridArea: 'content',
    padding: theme.spacing(2, 10, 5),
    // maxWidth: CONTENT_MAX_WIDTH + 32,
    width: '100%',
    overflowX: 'auto',
    minWidth: '450px',

    '& > :last-child': {
      marginTop: 'auto',
    },

    [theme.breakpoints.up('md')]: {
      // maxWidth: CONTENT_MAX_WIDTH + 60,
      padding: theme.spacing(2, 10, 5),
    },
  },

  sidebar: {
    gridArea: 'sidebar',
    position: 'sticky',
    top: 0,
    alignSelf: 'start',
  },

  header: {
    margin: '0 auto',
    gridArea: 'header',
    padding: '20px 48px',
    zIndex: 999,
  },
}));
