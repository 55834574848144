import { Box } from '@mui/material';
import { INftItem } from 'modules/nft-manager/types';
import { uid } from 'react-uid';
import { NftTile } from '../../screens/CollectionInfo/components/NftTile';
import { useNftListStyles } from './useNftListStyles';

interface INftListProps {
  data: INftItem[];
  mb?: number;
}

export const NftList = ({ data, mb = 10 }: INftListProps): JSX.Element => {
  const { classes } = useNftListStyles();

  return (
    <Box className={classes.root} mb={mb}>
      <div className={classes.wrap}>
        {data.map(item => (
          <NftTile key={uid(item)} {...item} />
        ))}
      </div>
    </Box>
  );
};
