import { Box, ButtonBase, Typography } from '@mui/material';
import { ReactComponent as CancelledIcon } from 'uiKit/icons/archived-circle.svg';
import { ReactComponent as DoneIcon } from 'uiKit/icons/done-circle.svg';
import { ReactComponent as FaultIcon } from 'uiKit/icons/fault-ring.svg';
import { ReactComponent as ClockIcon } from 'uiKit/icons/clock.svg';
// import { TooltipIcon } from '../../../../../common/components/TooltipIcon';
import { t } from 'modules/utils/intl';
import { FILE_STATUS } from '../../../../types';
import { useStatusItemStyles } from './useStatusItemStyles';

const SUCCESS_STATUS = [FILE_STATUS.UPLOADED, FILE_STATUS.COMPILED];
const PENDING_STATUS = [FILE_STATUS.UPLOADING, FILE_STATUS.COMPILING];
const ERROR_STATUS = [FILE_STATUS.UPLOAD_ERROR, FILE_STATUS.COMPILE_ERROR];

const STATUS_TEXT = {
  [FILE_STATUS.UPLOADING]: 'smart-contracts.upload.status-uploading',
  [FILE_STATUS.UPLOADED]: 'smart-contracts.upload.status-uploaded',
  [FILE_STATUS.UPLOAD_ERROR]: 'smart-contracts.upload.status-error',
  [FILE_STATUS.CANCELLED]: 'smart-contracts.upload.status-cancelled',
  [FILE_STATUS.COMPILED]: 'smart-contracts.compile.status-compiled',
  [FILE_STATUS.COMPILING]: 'smart-contracts.compile.status-compiling',
  [FILE_STATUS.COMPILE_ERROR]: 'smart-contracts.compile.status-error',
  [FILE_STATUS.COMPILE_WAIT]: 'smart-contracts.compile.status-wait',
};

interface IStatusItemProps {
  status: FILE_STATUS;
  handleViewMore?: () => void;
}

export const StatusItem = ({
  status,
  handleViewMore,
}: IStatusItemProps): JSX.Element => {
  const { classes } = useStatusItemStyles();

  if (SUCCESS_STATUS.includes(status)) {
    return (
      <Box display="flex" alignItems="center">
        <DoneIcon className={classes.mr2} />
        <Typography
          className={classes.secondColor}
          variant="captionAll"
          textTransform="uppercase"
        >
          {t(STATUS_TEXT[status])}
        </Typography>
      </Box>
    );
  }
  if (PENDING_STATUS.includes(status)) {
    return (
      <Typography
        className={classes.secondColor}
        variant="captionAll"
        textTransform="uppercase"
      >
        {t(STATUS_TEXT[status])}
      </Typography>
    );
  }
  if (ERROR_STATUS.includes(status)) {
    return (
      <Box display="flex" alignItems="center">
        <FaultIcon className={classes.mr2} />
        <Typography
          className={classes.secondColor}
          variant="captionAll"
          textTransform="uppercase"
        >
          {t(STATUS_TEXT[status])}
        </Typography>
        {/* {status === FILE_STATUS.UPLOAD_ERROR && (
          <TooltipIcon
            className={classes.ml2}
            placement="top"
            title={t('smart-contracts.upload.status-error-tooltip')}
          />
        )} */}
        {status === FILE_STATUS.COMPILE_ERROR && (
          <ButtonBase onClick={handleViewMore}>
            <Typography variant="caption" className={classes.viewMore}>
              {t('smart-contracts.compile.view-more')}
            </Typography>
          </ButtonBase>
        )}
      </Box>
    );
  }

  if (status === FILE_STATUS.CANCELLED) {
    return (
      <Box display="flex" alignItems="center">
        <CancelledIcon className={classes.mr2} />
        <Typography
          className={classes.secondColor}
          variant="captionAll"
          textTransform="uppercase"
        >
          {t(STATUS_TEXT[status])}
        </Typography>
      </Box>
    );
  }

  if (status === FILE_STATUS.COMPILE_WAIT) {
    return (
      <Box display="flex" alignItems="center">
        <ClockIcon className={classes.mr2} />
        <Typography
          className={classes.secondColor}
          variant="captionAll"
          textTransform="uppercase"
        >
          {t(STATUS_TEXT[status])}
        </Typography>
      </Box>
    );
  }

  return <>-</>;
};
