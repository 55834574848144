import { Button, Typography } from '@mui/material';
import { Dialog } from 'modules/common/components/Dialog';
import { useDialog } from 'modules/common/hooks';
import { SelectTokenStandart } from 'modules/nft-manager/components/SelectTokenStandart';
import { t } from 'modules/utils/intl';
import { useEmptyStyles } from './useEmptyStyles';

export const Empty = (): JSX.Element => {
  const { classes } = useEmptyStyles();
  const { isOpened, onOpen, onClose } = useDialog();

  return (
    <div className={classes.root}>
      <Typography variant="h3">
        {t('nft.collection-info.empty-nft-list')}
      </Typography>

      <Button className={classes.button} variant="outlined" onClick={onOpen}>
        {t('nft.collection-info.add-nft')}
      </Button>

      <Dialog
        isBigSize
        title={t('nft.erc-standart-select.title')}
        open={isOpened}
        onClose={onClose}
      >
        <SelectTokenStandart />
      </Dialog>
    </div>
  );
};
