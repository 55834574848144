import { makeStyles } from 'tss-react/mui';

export const useUpdatesItemStyles = makeStyles()(theme => ({
  root: {
    color: theme.palette.text.secondary,
  },
  topRoot: {
    marginBottom: theme.spacing(1.5),
  },
  bottomRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1.5),
  },
}));
