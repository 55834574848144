import React from 'react';
import { Typography } from '@mui/material';
import { useStepHeaderStyles } from './useStepHeaderStyles';
import { ReactComponent as TooltipIcon } from 'uiKit/icons/tooltip-white.svg';

interface Props {
  title: string;
  description: string;
}

export const StepHeader = ({ title, description }: Props): JSX.Element => {
  const { classes } = useStepHeaderStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h3">
        {title}
      </Typography>
      <div className={classes.description}>
        <TooltipIcon />
        <Typography variant="body2">{description}</Typography>
      </div>
    </div>
  );
};
