import { makeStyles } from 'tss-react/mui';

export const useEmptyStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100vh - 390px)',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16,
    marginBottom: theme.spacing(5),
  },
  button: {
    width: 160,
    padding: 0,
    marginTop: theme.spacing(5),
  },
}));
