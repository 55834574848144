import { makeStyles } from 'tss-react/mui';

export const useBasicInfoStyles = makeStyles()(theme => ({
  mb6: {
    marginBottom: theme.spacing(6),
  },
  mb15: {
    marginBottom: theme.spacing(15),
  },
  description: {
    '& .MuiInputBase-root': {
      height: 'unset',

      '& .MuiFilledInput-input': {
        padding: theme.spacing(3, 2, 3),
      },
    },
  },
  mr: {
    marginRight: theme.spacing(1),
  },
  icon: {
    color: theme.palette.text.primary,
  },
  doneIcon: {
    color: theme.palette.text.primary,
  },
  secretButton: {
    marginBottom: theme.spacing(4),
    '& p': {
      color: theme.palette.text.primary,
    },
  }
}));
