import { Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { LoaderCentered } from '../../../common/components/Loader';
import { t } from '../../../utils/intl';
import { RoutesConfig } from '../../Routes';
import { BasicInfo } from './components/BasicInfo';
import { ProjectMembers } from './components/ProjectMembers';
import { useProjectInfoData } from './useProjectInfoData';
import { useProjectInfoStyles } from './useProjectInfoStyles';

export const ProjectInfo = (): JSX.Element => {
  const { classes } = useProjectInfoStyles();
  const {
    id,
    name,
    description,
    platforms,
    image,
    banner,
    projectId,
    projectType,
    secret,
    members,
    isLoading,
  } = useProjectInfoData();

  if (isLoading) return <LoaderCentered />;

  return (
    <>
      <div className={classes.root}>
        <BasicInfo
          name={name}
          description={description}
          platforms={platforms}
          projectId={projectId}
          image={image}
          banner={banner}
          id={id}
          secret={secret}
          type={projectType}
        />
      </div>
      <ProjectMembers members={members} />

      {!!members && Array.isArray(members) && (
        <Button variant="outlined" className={classes.button}>
          <NavLink
            to={RoutesConfig.inviteUser.generatePath(projectId)}
          >
            {t('projects.projectInfo.invite')}
          </NavLink>
        </Button>
      )}
    </>
  );
};
