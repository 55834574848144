import { makeStyles } from 'tss-react/mui';

export const usePlatformStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    // flexDirection: 'column',
    alignItems: 'center',
    gap: 6,
    color: theme.palette.grey[900],
  },
  icon: {
    height: 24,
    width: 24,
  },
}));
