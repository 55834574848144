import { makeStyles } from 'tss-react/mui';

export const useAnalyticsStyles = makeStyles()(theme => ({
  root: {
    width: 544,
    marginBottom: theme.spacing(14),
  },
  divider: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  select: {
    width: 150,
    height: 30,
    padding: theme.spacing(0, 1, 0),
    textAlign: 'center',
    border: 'none',

    '&.Mui-focused': {
      border: 'none !important',
    },

    '&:hover': {
      border: 'none !important',
    },

    '& > div': {
      fontWeight: 500,
      fontSize: 15,
      lineHeight: '20px',
      letterSpacing: '-0.01em',
    },
  },
}));
