import {
  Input,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  TextField,
} from '@mui/material';
import { isEmptyOrSpaces } from 'modules/common/utils/isEmptyOrSpaces';
import { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { uid } from 'react-uid';
import { SelectItem } from 'uiKit/components/SelectItem';
import { Button } from '../../../../../../uiKit/components/Button';
import { EProjectTypes, projectTypesNaming } from '../../../../../common/types';
import { t } from '../../../../../utils/intl';
import { IUpdatedProjectData, useEditProjectForm } from './useEditProjectForm';
import { useEditProjectStyles } from './useEditProjectStyles';
import { IImageData } from 'modules/projects/types';
import { EPlatform } from 'modules/projects/const';
import { PlatformItem } from 'modules/projects/screens/AddingProject/components/PlatformItem';

export enum EditingType {
  Rename,
  ProjectType,
  Description,
  Platform,
}

interface IEditProjectProps {
  id?: string;
  initName: string;
  initImage?: IImageData;
  initBanner?: IImageData;
  initPlatforms?: EPlatform[];
  initDescription: string;
  initProjectType: EProjectTypes;
  editingType: EditingType;
}

export const EditProject = ({
  id,
  initName,
  initImage,
  initBanner,
  initPlatforms,
  initDescription,
  initProjectType,
  editingType,
}: IEditProjectProps): JSX.Element => {
  const { classes, cx } = useEditProjectStyles();
  const { isLoading, onSubmit } = useEditProjectForm();

  const {
    control,
    formState: { isValid },
    handleSubmit,
    getValues,
  } = useForm<IUpdatedProjectData>({
    defaultValues: {
      projectName: initName,
      description: initDescription,
      isPc: initPlatforms?.includes(EPlatform.PC),
      isMac: initPlatforms?.includes(EPlatform.MAC),
      isLinux: initPlatforms?.includes(EPlatform.LINUX),
      isIos: initPlatforms?.includes(EPlatform.IOS),
      isAndroid: initPlatforms?.includes(EPlatform.ANDROID),
      isWeb: initPlatforms?.includes(EPlatform.WEB),
      image: initImage,
      banner: initBanner,
      projectType: initProjectType,
      id: id,
    },
  });

  const inputRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.focus();
  }, []);

  const onPrepareSubmit = (data: IUpdatedProjectData): void => {
    if (isValid) {
      onSubmit(data);
    }
  };
  return (
    <form
      className={classes.root}
      onSubmit={handleSubmit(onPrepareSubmit)}
      noValidate
    >
      {editingType === EditingType.Rename && (
        <Controller
          name="projectName"
          control={control}
          rules={{
            required: t('validation.required'),
            validate: v => {
              return !isEmptyOrSpaces(v) || t('validation.required');
            },
          }}
          render={({ field, fieldState }) => {
            const errorText = fieldState.error?.message;
            return (
              <FormControl>
                <InputLabel>{t('projects.adding.project-name')}</InputLabel>
                <Input
                  {...field}
                  inputRef={inputRef}
                  fullWidth
                  error={!!fieldState.error}
                  inputProps={{ maxLength: 50 }}
                  className={!!fieldState.error ? classes.mb1 : classes.mb6}
                  onChange={field.onChange}
                  onFocus={e => e.target.select()}
                />
                {!!errorText && (
                  <FormHelperText error>{errorText}</FormHelperText>
                )}
              </FormControl>
            );
          }}
        />
      )}

      {editingType === EditingType.Description && (
        <Controller
          name="description"
          control={control}
          rules={{
            required: t('validation.required'),
            validate: v => {
              return !isEmptyOrSpaces(v) || t('validation.required');
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <TextField
                {...field}
                fullWidth
                multiline
                rows={8}
                helperText={t('projects.adding.characters-number', {
                  value: field.value?.length ?? 0,
                })}
                label={t('projects.adding.description')}
                error={!!fieldState.error}
                inputProps={{ maxLength: 1_000 }}
                className={cx(classes.mb6, classes.textfield)}
                onChange={field.onChange}
              />
            );
          }}
        />
      )}

      {editingType === EditingType.ProjectType && (
        <Controller
          name="projectType"
          control={control}
          rules={{
            required: t('validation.required'),
          }}
          render={({ field }) => {
            return (
              <FormControl fullWidth>
                <InputLabel>{t('projects.adding.type-of-app')}</InputLabel>
                <Select
                  fullWidth
                  value={field.value}
                  className={classes.mb6}
                  onChange={field.onChange}
                >
                  {Object.entries(projectTypesNaming).map(item => (
                    <SelectItem
                      itemValue={+item[0]}
                      value={+item[0]}
                      key={uid(item)}
                      itemText={item[1]}
                      selectValue={field.value}
                    >
                      {item[1]}
                    </SelectItem>
                  ))}
                </Select>
              </FormControl>
            );
          }}
        />
      )}

      {editingType === EditingType.Platform && (
        <>
          <Controller
            name="isPc"
            control={control}
            rules={{
              validate: () => {
                const values = getValues();
                return (
                  !!values.isPc ||
                  !!values.isMac ||
                  !!values.isLinux ||
                  !!values.isIos ||
                  !!values.isAndroid ||
                  !!values.isWeb ||
                  t('validation.required')
                );
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <PlatformItem
                  value={EPlatform.PC}
                  isActive={field.value}
                  isError={!!fieldState.error}
                  onChange={field.onChange}
                />
              );
            }}
          />
          <Controller
            name="isMac"
            control={control}
            rules={{
              validate: () => {
                const values = getValues();
                return (
                  !!values.isPc ||
                  !!values.isMac ||
                  !!values.isLinux ||
                  !!values.isIos ||
                  !!values.isAndroid ||
                  !!values.isWeb ||
                  t('validation.required')
                );
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <PlatformItem
                  value={EPlatform.MAC}
                  isActive={field.value}
                  isError={!!fieldState.error}
                  onChange={field.onChange}
                />
              );
            }}
          />
          <Controller
            name="isLinux"
            control={control}
            rules={{
              validate: () => {
                const values = getValues();
                return (
                  !!values.isPc ||
                  !!values.isMac ||
                  !!values.isLinux ||
                  !!values.isIos ||
                  !!values.isAndroid ||
                  !!values.isWeb ||
                  t('validation.required')
                );
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <PlatformItem
                  value={EPlatform.LINUX}
                  isActive={field.value}
                  isError={!!fieldState.error}
                  onChange={field.onChange}
                />
              );
            }}
          />
          <Controller
            name="isIos"
            control={control}
            rules={{
              validate: () => {
                const values = getValues();
                return (
                  !!values.isPc ||
                  !!values.isMac ||
                  !!values.isLinux ||
                  !!values.isIos ||
                  !!values.isAndroid ||
                  !!values.isWeb ||
                  t('validation.required')
                );
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <PlatformItem
                  value={EPlatform.IOS}
                  isActive={field.value}
                  isError={!!fieldState.error}
                  onChange={field.onChange}
                />
              );
            }}
          />
          <Controller
            name="isAndroid"
            control={control}
            rules={{
              validate: () => {
                const values = getValues();
                return (
                  !!values.isPc ||
                  !!values.isMac ||
                  !!values.isLinux ||
                  !!values.isIos ||
                  !!values.isAndroid ||
                  !!values.isWeb ||
                  t('validation.required')
                );
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <PlatformItem
                  value={EPlatform.ANDROID}
                  isActive={field.value}
                  isError={!!fieldState.error}
                  onChange={field.onChange}
                />
              );
            }}
          />
          <Controller
            name="isWeb"
            control={control}
            rules={{
              validate: () => {
                const values = getValues();
                return (
                  !!values.isPc ||
                  !!values.isMac ||
                  !!values.isLinux ||
                  !!values.isIos ||
                  !!values.isAndroid ||
                  !!values.isWeb ||
                  t('validation.required')
                );
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <PlatformItem
                  value={EPlatform.WEB}
                  isActive={field.value}
                  isError={!!fieldState.error}
                  onChange={field.onChange}
                />
              );
            }}
          />
        </>
      )}

      <Button
        fullWidth
        isLoading={isLoading}
        type="submit"
        variant="contained"
        size="large"
      >
        {t('projects.projectInfo.save')}
      </Button>
    </form>
  );
};
