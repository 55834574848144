import { TTokenStandart } from '../types';

export const getTokenStandartName = (tokenStandart: TTokenStandart): string => {
  switch (tokenStandart.toUpperCase()) {
    case 'ERC1155TEMPLATE':
      return 'ERC-1155';
    case 'ERC721TEMPLATE':
    default:
      return 'ERC-721';
  }
};
