import { usePublishStyles } from './usePublishStyles';
import { PublishedFiles } from './PublishedFiles';
import { ICVPublishData } from '../../../../types';

interface IPublishFiles {
  cvPublishData: ICVPublishData | null;
  byteCode?: string;
  isPublishLoading: boolean;
  isCVByteCodeLoading: boolean;
  handleGetCVByteCode: () => void;
  handlePublish: (gas: number) => void;
}

export const Publish = ({
  cvPublishData,
  byteCode,
  isPublishLoading,
  isCVByteCodeLoading,
  handleGetCVByteCode,
  handlePublish,
}: IPublishFiles): JSX.Element => {
  const { classes } = usePublishStyles();

  return (
    <div className={classes.wrapper}>
      <PublishedFiles
        cvPublishData={cvPublishData}
        byteCode={byteCode}
        isPublishLoading={isPublishLoading}
        isCVByteCodeLoading={isCVByteCodeLoading}
        handleGetCVByteCode={handleGetCVByteCode}
        handlePublish={handlePublish}
      />
    </div>
  );
};
