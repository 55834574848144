import { Box, ButtonBase, Typography } from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ReactComponent as CopyIcon } from 'uiKit/icons/copy.svg';
import { ReactComponent as DoneIcon } from 'uiKit/icons/done.svg';
import { useCopyWrappedTextStyles } from './useCopyWrappedTextStyles';

interface ICopyWrappedTextProps {
  text: string;
  textForCopy: string;
  isCopied: boolean;
  handleCopy: () => void;
}

export const CopyWrappedText = ({
  text,
  textForCopy,
  isCopied,
  handleCopy,
}: ICopyWrappedTextProps): JSX.Element => {
  const { classes, cx } = useCopyWrappedTextStyles();

  return (
    <Box display="flex">
      <Typography className={classes.violet}>{text}</Typography>
      <CopyToClipboard text={textForCopy} onCopy={handleCopy}>
        <ButtonBase>
          {isCopied ? (
            <DoneIcon
              onClick={e => e.preventDefault()}
              className={cx(classes.icon, classes.violet)}
            />
          ) : (
            <CopyIcon
              onClick={e => e.preventDefault()}
              className={cx(classes.icon, classes.violet)}
            />
          )}
        </ButtonBase>
      </CopyToClipboard>
    </Box>
  );
};
