import { makeStyles } from 'tss-react/mui';

export const useInfoStyles = makeStyles()(theme => ({
  root: {},
  statusIcon: {
    marginRight: theme.spacing(2),
  },
  networkIcon: {
    marginRight: theme.spacing(3),
  },
  mb4: {
    marginBottom: theme.spacing(4),
  },
  buttons: {
    marginBottom: theme.spacing(15),
  },
  itemRoot: {
    wordWrap: 'break-word',
    border: 'none',
  },
  expandIcon: {
    color: theme.palette.text.primary,
  },
  accordionTitle: {
    gap: theme.spacing(1),
    justifyContent: 'flex-start',
    '& .MuiAccordionSummary-content': {
      flexGrow: 'unset',
    },
    '&.Mui-expanded': {
      height: theme.spacing(10),
      minHeight: theme.spacing(10),
    },
  },
  smallNetworkIcon: {
    height: 20,
    width: 20,
  },
  notifyIcon: {
    height: 16,
    width: 16,
    marginRight: theme.spacing(2),
    color: theme.palette.grey[700],
  },
  attributeRow: {
    height: 56,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[500]}`,

    '&:last-of-type': {
      borderBottom: 'unset',
    },
  },
}));
