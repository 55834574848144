import { Button, Typography } from '@mui/material';
import { NftList } from 'modules/nft-manager/components/NftList';
import { INftItem } from 'modules/nft-manager/types';
import { t } from 'modules/utils/intl';
import { NavLink } from 'react-router-dom';

interface IMoreNftsProps {
  collectionLink: string;
  nfts: INftItem[];
}

export const MoreNfts = ({
  collectionLink,
  nfts,
}: IMoreNftsProps): JSX.Element => {
  return (
    <>
      <Typography variant="h2" mb={6}>
        {t('nft.nft-info.more-title')}
      </Typography>

      <NftList data={nfts} mb={4} />

      <NavLink to={collectionLink}>
        <Button variant="outlined">{t('nft.nft-info.view-collection')}</Button>
      </NavLink>
    </>
  );
};
