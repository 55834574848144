import Button from '@mui/material/Button';
import { featuresConfig } from 'modules/common/featuresConfig';
import { RoutesConfig } from 'modules/projects/Routes';
import { t } from 'modules/utils/intl';
import { NavLink } from 'react-router-dom';
import { ReactComponent as BellIcon } from 'uiKit/icons/notification.svg';
import { ReactComponent as UserIcon } from 'uiKit/icons/user.svg';
import { useHeaderStyles } from './useHeaderStyles';
import { oryLogoutAsync } from 'modules/auth';
import { Tooltip } from '@mui/material';

interface Props {
  className?: string;
}

// todo: add button which return us to previous page
export function Header({ className }: Props): JSX.Element {
  const { classes, cx } = useHeaderStyles();

  return (
    <header className={cx(classes.root, className)}>
      <NavLink
        className={classes.button}
        to={RoutesConfig.addingProject.generatePath()}
      >
        <Button>{t('layout.add-project')}</Button>
      </NavLink>
      {featuresConfig.isActiveHeaderIcons && (
        <>
          <div className={classes.divider} />
          <BellIcon className={classes.icon} />
          <Tooltip placement='bottom' title={t('layout.header.user-settings')}>
            <UserIcon
              onClick={() => oryLogoutAsync()}
              className={classes.icon}
            />
          </Tooltip>
        </>
      )}
    </header>
  );
}
