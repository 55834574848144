import { Header } from './components/Header';
import { Stepper } from './components/Stepper';
import { Upload } from './components/Upload';
import { CompiledFiles } from './components/Compile';
import { Publish } from './components/Publish';
import { STEP } from '../../types';
import { UnexistsMetamask } from '../../../common/components/UnexistsMetamask';
import { useMainUploadedData } from './useMainUploadedData';

const FILE_TYPES = ['SOL'];

export const UploadAndPublish = (): JSX.Element => {
  const {
    step,
    uploadedFile,
    compiledData,
    cvPublishData,
    byteCode,
    uploadStatus,
    compileStatus,
    isOpenedMetamaskWarning,
    isCompileFilesLoading,
    isPublishLoading,
    isUploadLoading,
    isCVByteCodeLoading,
    isDeleteLoading,
    isRetryUploadLoading,
    isUploadingDisabled,
    handleChangeStep,
    handleDeleteUploadedFile,
    handleChangeUploadedFile,
    handleRetryUploadFile,
    handleContractCompile,
    handleGetCVByteCode,
    handlePublish,
    onCloseMetamaskWarning,
    goToUploadStep,
    goToCompileStep,
    goToPublishStep,
  } = useMainUploadedData();

  return (
    <>
      <Header />
      <Stepper
        step={step}
        goToUploadStep={goToUploadStep}
        goToCompileStep={goToCompileStep}
        goToPublishStep={goToPublishStep}
      />
      {step === STEP.UPLOAD && (
        <Upload
          fileTypes={FILE_TYPES}
          file={uploadedFile}
          uploadStatus={uploadStatus}
          isLoading={isUploadLoading}
          isDeleteLoading={isDeleteLoading}
          isRetryUploadLoading={isRetryUploadLoading}
          isUploadingDisabled={isUploadingDisabled}
          handleRetryUploadFile={handleRetryUploadFile}
          handleDeleteUploadedFile={handleDeleteUploadedFile}
          handleChangeFile={handleChangeUploadedFile}
          handleNextStep={() => handleChangeStep(STEP.COMPILE)}
        />
      )}
      {step === STEP.COMPILE && (
        <CompiledFiles
          data={compiledData}
          compileStatus={compileStatus}
          isCompileFilesLoading={isCompileFilesLoading}
          handleContractCompile={handleContractCompile}
          handleNextStep={() => handleChangeStep(STEP.PUBLISH)}
        />
      )}
      {step === STEP.PUBLISH && (
        <Publish
          cvPublishData={cvPublishData}
          byteCode={byteCode}
          isPublishLoading={isPublishLoading}
          isCVByteCodeLoading={isCVByteCodeLoading}
          handleGetCVByteCode={handleGetCVByteCode}
          handlePublish={handlePublish}
        />
      )}

      <UnexistsMetamask
        isOpened={isOpenedMetamaskWarning}
        onClose={onCloseMetamaskWarning}
      />
    </>
  );
};
