import { makeStyles } from 'tss-react/mui';

export const useTransactionStyles = makeStyles()(theme => ({
  scNetworksWrapper: {
    display: 'flex',
    position: 'relative',
    height: 80,
    borderRadius: 16,
    border: `1px solid ${theme.palette.grey[500]}`,
  },

  tooltip: {
    marginLeft: theme.spacing(2),
  },

  mb4: {
    marginBottom: theme.spacing(4),
  },

  mb8: {
    marginBottom: theme.spacing(8),
  },

  contractNameCell: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    borderRight: `1px solid ${theme.palette.grey[500]}`,
  },

  networksCell: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },

  networkInternal: {
    marginLeft: theme.spacing(10),
  },

  verticalAlign: {
    display: 'flex',
    alignItems: 'center',
  },

  clockIcon: {
    marginLeft: theme.spacing(2.5),
  },

  wrapper: {
    color: theme.palette.text.secondary,
    position: 'relative',
  },

  arrowWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    margin: 'auto',
    display: 'flex',
    width: 40,
    height: 40,
    borderRadius: '50%',
    backgroundColor: theme.palette.violet.main,
    color: theme.palette.common.white,
  },

  arrowIcon: {
    margin: 'auto',
    transform: 'rotate(180deg)',
  },

  tokenIcon: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 100,

      '& + &': {
        left: '30px',
        zIndex: 99,
      },
      '& + & + &': {
        left: '60px',
        zIndex: 98,
      },
    },
  },

  button: {
    marginBottom: theme.spacing(3),
  },
}));
