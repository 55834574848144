import BigNumber from 'bignumber.js';
import { web3Api } from 'modules/api';
import { GET_COINGECKO_MATIC_DATA } from 'modules/api/urls';
import { queryFnNotifyWrapper } from 'modules/api/utils/queryFnNotifyWrapper';
import { ZERO } from 'modules/common/const';

export const { useGetMaticPriceQuery } = web3Api.injectEndpoints({
  endpoints: build => ({
    getMaticPrice: build.query<BigNumber, void>({
      queryFn: queryFnNotifyWrapper<void, never, BigNumber>(async () => {
        const request = await fetch(GET_COINGECKO_MATIC_DATA, {
          method: 'GET',
        }).then(res => res.json());

        return {
          data: new BigNumber(request.market_data.current_price.usd) ?? ZERO,
        };
      }),
    }),
  }),
});
