import { Components, Palette } from '@mui/material';

export function getMuiTooltip(palette: Palette): Components['MuiTooltip'] {
  return {
    styleOverrides: {
      tooltip: {
        backgroundColor: palette.common.black,
        color: palette.common.white,
        fontSize: 13,
        lineHeight: '16px',
        fontWeight: 500,
        borderRadius: 8,
        padding: 12,
      },
      arrow: {
        '&::before': {
          backgroundColor: palette.common.black,
        },
      },
    },
  };
}
