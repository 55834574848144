import DefaultIconBlue from 'uiKit/icons/default-icon-blue.svg';
import DefaultIconGreen from 'uiKit/icons/default-icon-green.svg';
import DefaultIconPink from 'uiKit/icons/default-icon-pink.svg';
import DefaultIconPurple from 'uiKit/icons/default-icon-purple.svg';
import { ReactComponent as ChevronDownIcon } from 'uiKit/icons/chevron-small-down.svg';
import { RoutesConfig } from 'modules/projects/Routes';
import { NavLink } from 'react-router-dom';
import { ButtonBase, Menu, MenuItem, Typography } from '@mui/material';
import { Tooltip } from '@mui/material';
import { useProjectIconStyles } from './useProjectIconStyles';
import { uid } from 'react-uid';
import { useState } from 'react';

interface IProjectIconsProps {
  projects: string[];
  maxShowing?: number;
}

const DEFAULT_ICONS_LIST = [
  DefaultIconBlue,
  DefaultIconGreen,
  DefaultIconPink,
  DefaultIconPurple,
];
const DEFAULT_ICONS_VARIANTS = DEFAULT_ICONS_LIST.length;
const SHOWING_PROJECTS = 2;

export const ProjectIcons = ({
  projects,
  maxShowing = SHOWING_PROJECTS,
}: IProjectIconsProps): JSX.Element => {
  const { classes } = useProjectIconStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const count = projects.length;

  if (count <= maxShowing + 1) {
    return (
      <div className={classes.root}>
        {projects.map((project, index) => (
          <div key={uid(project)} className={classes.iconWrapper}>
            <Tooltip arrow title={project}>
              <NavLink
                to={RoutesConfig.project.generatePath(project)}
                className={classes.iconWrapperLink}
              >
                <img
                  alt=""
                  className={classes.img}
                  src={DEFAULT_ICONS_LIST[index % DEFAULT_ICONS_VARIANTS]}
                />
              </NavLink>
            </Tooltip>
          </div>
        ))}
      </div>
    );
  }

  const additionalProjectsCount = projects.length - maxShowing;
  const hiddenProjects: string[] = [...projects];
  hiddenProjects.splice(0, maxShowing);

  return (
    <div className={classes.root}>
      {projects.slice(0, maxShowing).map((project, index) => (
        <div key={uid(project)} className={classes.iconWrapper}>
          <Tooltip arrow title={project}>
            <NavLink to={RoutesConfig.project.generatePath(project)}>
              <img
                alt=""
                className={classes.img}
                src={DEFAULT_ICONS_LIST[index % DEFAULT_ICONS_VARIANTS]}
              />
            </NavLink>
          </Tooltip>
        </div>
      ))}

      <ButtonBase className={classes.button} onClick={handleClick}>
        <Typography variant="caption">+{additionalProjectsCount}</Typography>
      </ButtonBase>

      <Menu
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 240,
            width: '178px',
            fontSize: '13px',
          },
        }}
      >
        {hiddenProjects.map((project, index) => (
          <MenuItem key={index}>
            <NavLink to={RoutesConfig.project.generatePath(project)}>
              <div className={classes.itemWrap}>
                <img
                  alt=""
                  className={classes.smallImg}
                  src={DEFAULT_ICONS_LIST[1]}
                />
                <Typography className={classes.name} variant="body2">
                  {project}
                </Typography>
                <ChevronDownIcon className={classes.icon} />
              </div>
            </NavLink>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
