export enum Env {
  BS = 'bs',
  Stage = 'stage',
  Prod = 'prod',
  Local = 'local',
}

export enum Locale {
  en = 'en-US',
  zh = 'zh-CN',
}

export enum Roles {
  Admin = 0,
  User = 1,
  ProjectOwner = 2,
  Guest = 3,
}

export type ReactText = string | number;
export type Days = number;
export type Minutes = number;
export type Seconds = number;
export type Milliseconds = number;
export type Address = string;

export enum EProjectTypes {
  Games,
  Action,
  Adventure,
  Board,
  Card,
  Family,
  Music,
  Puzzle,
  Role,
  Racing,
  Simulation,
  Sports,
  Strategy,
  SocialNetworking,
  NonGaming,
  Metaverse,
  Casual,
  Education,
  Kids,
  Trivia,
  Word,
  Arcade,
  FPS,
  Moba,
  MMO,
}

export const projectTypesNaming: Record<EProjectTypes, string> = {
  [EProjectTypes.Games]: 'Games',
  [EProjectTypes.Action]: 'Games - Action',
  [EProjectTypes.Adventure]: 'Games - Adventure',
  [EProjectTypes.Board]: 'Games - Board',
  [EProjectTypes.Card]: 'Games - Card',
  [EProjectTypes.Family]: 'Games - Family',
  [EProjectTypes.Music]: 'Games - Music',
  [EProjectTypes.Puzzle]: 'Games - Puzzle',
  [EProjectTypes.Role]: 'Games - Role',
  [EProjectTypes.Racing]: 'Games - Racing',
  [EProjectTypes.Simulation]: 'Games - Simulation',
  [EProjectTypes.Sports]: 'Games - Sports',
  [EProjectTypes.Strategy]: 'Games - Strategy',
  [EProjectTypes.SocialNetworking]: 'Social Networking',
  [EProjectTypes.NonGaming]: 'Non-gaming',
  [EProjectTypes.Metaverse]: 'Metaverse',
  [EProjectTypes.Casual]: 'Casual',
  [EProjectTypes.Education]: 'Education',
  [EProjectTypes.Kids]: 'Kids',
  [EProjectTypes.Trivia]: 'Trivia',
  [EProjectTypes.Word]: 'Word',
  [EProjectTypes.Arcade]: 'Arcade',
  [EProjectTypes.FPS]: 'First Person Shooter',
  [EProjectTypes.Moba]: 'Mobile',
  [EProjectTypes.MMO]: 'Massively multiplayer online',
};

export enum Network {
  Eth = 0,
  Bsc = 1,
  Polygon = 2,
  Arbitrum = 3,
}

export enum NftMintNetwork {
  Goerli = 'Goerli',
  SepETH = 'SepETH',
  BSC = 'BNB',
  Polygon = 'MATIC',
  ARB = 'ARB',
}

export enum RESPONSE_CODES {
  OK = 200,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  PRECONDITION_FAILED = 412,
}

export enum Token {
  ETH = 'ETH',
  BNB = 'BNB',
  MATIC = 'MATIC',
  ARB = 'ARB',
}

export type TTokenStandart = 'ERC721Template' | 'ERC1155Template';
