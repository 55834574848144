import { Box, Paper, Radio, Typography } from '@mui/material';
import { useTokenCardStyles } from './useTokenCardStyles';

interface ITokenCardArgs {
  tokenName: string;
  value: string;
  isActive: boolean;
}

export const TokenCard = ({
  tokenName,
  value,
  isActive,
}: ITokenCardArgs): JSX.Element => {
  const { classes, cx } = useTokenCardStyles();
  return (
    <Paper
      component="label"
      className={cx(classes.tokenCardRoot, isActive && classes.active)}
    >
      <Radio className={classes.radioWrapper} value={value} />
      <Box display="flex" flexDirection="column" mt={1}>
        <Typography>{tokenName}</Typography>
      </Box>
    </Paper>
  );
};
