import { LoaderCentered } from 'modules/common/components/Loader';
import { RootWrapper } from 'modules/nft-manager/components/RootWrapper';
import { BigImage } from './components/BigImage';
import { Info } from './components/Info';
import { MoreNfts } from './components/MoreNfts';
import { useNftInfoData } from './useNftInfoData';
import { useNftInfoStyles } from './useNftInfoStyles';

export const NftInfo = (): JSX.Element => {
  const { classes } = useNftInfoStyles();

  const {
    isLoading,
    name,
    description,
    tokenId,
    status,
    collection,
    tokenPrice,
    usdPrice,
    plazaLink,
    contractAddress,
    tokenTemplate,
    network,
    token,
    royalty,
    quantity,
    nftAsset: content,
    nftProperties,
    isOpenLaunch,
    collectionLink,
    moreNfts,
    isLaunchLoading,
    stopSale,
    handleOpenLaunch,
    onClose,
    handleLaunchNft,
  } = useNftInfoData();

  if (isLoading) return <LoaderCentered />;

  return (
    <RootWrapper>
      <div className={classes.root}>
        <div className={classes.main}>
          <div className={classes.mainSection}>
            <BigImage imageSrc={content ? content.fileUri : ''} />
          </div>
          <div className={classes.mainSection}>
            <Info
              isLoading={isLoading}
              name={name}
              description={description}
              plazaLink={plazaLink}
              tokenId={tokenId}
              status={status}
              collection={collection}
              tokenPrice={tokenPrice}
              usdPrice={usdPrice}
              contractAddress={contractAddress}
              tokenTemplate={tokenTemplate}
              network={network}
              quantity={quantity}
              token={token}
              royalty={royalty}
              nftProperties={nftProperties}
              isOpenLaunch={isOpenLaunch}
              isLaunchLoading={isLaunchLoading}
              onClose={onClose}
              stopSale={stopSale}
              handleOpenLaunch={handleOpenLaunch}
              handleLaunchNft={handleLaunchNft}
            />
          </div>
        </div>
        {!!moreNfts && !!moreNfts?.length && moreNfts.length > 0 && (
          <MoreNfts collectionLink={collectionLink} nfts={moreNfts} />
        )}
      </div>
    </RootWrapper>
  );
};
