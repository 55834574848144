import { Box } from '@mui/system';
import Lottie from 'lottie-react';
import animationConfig from './animationConfig.json';
import { useLoaderStyles } from './useLoaderStyles';

interface Props {
  /** Sets loader box height to `100vh` instead of '100%' */
  fullHeight?: boolean;
}

export const LoaderCentered = ({ fullHeight }: Props): JSX.Element => {
  const { classes } = useLoaderStyles();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={fullHeight ? '100vh' : '100%'}
      width="100%"
    >
      <Lottie
        className={classes.root}
        animationData={animationConfig}
        loop={true}
      />
    </Box>
  );
};
