import { ReactComponent as SuccessIcon } from './assets/success-icon.svg';
import { Box } from '@mui/system';

interface ISuccessNotificationProps {
  message: string;
}

export const SuccessNotification = ({
  message,
}: ISuccessNotificationProps): JSX.Element => {
  return (
    <Box display="flex" alignItems="center">
      <Box mr={2}>
        <SuccessIcon />
      </Box>
      {message}
    </Box>
  );
};
