import { Typography } from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ReactComponent as CopyIcon } from 'uiKit/icons/copy.svg';
import { ReactComponent as DoneIcon } from 'uiKit/icons/done.svg';
import { getShortId } from 'modules/common/utils/getShortId';
import { useCopyHook } from 'modules/common/utils/useCopyHook';
import { useContractIdStyles } from './useContractIdStyles';

interface IContractIdProps {
  id: string;
}

export const ContractId = ({ id }: IContractIdProps): JSX.Element => {
  const { classes, cx } = useContractIdStyles();
  const { isCopied, handleCopy } = useCopyHook();

  return (
    <div className={classes.root}>
      <Typography variant="body2" className={classes.id}>
        {getShortId(id)}
      </Typography>
      <CopyToClipboard text={id} onCopy={handleCopy}>
        {isCopied ? (
          <DoneIcon className={cx(classes.icon, classes.doneIcon)} />
        ) : (
          <CopyIcon className={classes.icon} />
        )}
      </CopyToClipboard>
    </div>
  );
};
