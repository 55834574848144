import { makeStyles } from 'tss-react/mui';

export const useDialogStyles = makeStyles()(theme => ({
  root: {
    justifyContent: 'center',
    margin: theme.spacing(4, 0),
    padding: theme.spacing(7, 2, 2, 2),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8, 8, 15),
    },
  },

  defaultSize: {
    width: 512,
    minWidth: 512,
  },

  bigSize: {
    width: 1000,
    minWidth: 1000,
  },

  title: {
    position: 'relative',
    fontWeight: 500,
    fontSize: 28,
    lineHeight: '32px',
    textAlign: 'start',
    padding: 0,
    marginBottom: theme.spacing(4),
  },

  closeBtn: {
    position: 'absolute',
    top: theme.spacing(-3),
    right: theme.spacing(1),

    [theme.breakpoints.up('sm')]: {
      top: theme.spacing(-2),
      right: theme.spacing(-2),
    },

    width: 40,
    minWidth: 0,
    height: 40,
    padding: 0,

    borderRadius: '50%',
    color: theme.palette.text.primary,
  },

  content: {
    padding: 0,
  },
}));
