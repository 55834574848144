import { Box, Paper, Radio } from '@mui/material';
import { ICollectionItem } from 'modules/nft-manager/types';
import { useCollectionItemStyles } from './useCollectionItemStyles';

interface ICollectionItemProps {
  className?: string;
  collection: ICollectionItem;
  isActive: boolean;
  isError: boolean;
}

export const CollectionItem = ({
  className,
  collection,
  isActive,
  isError,
}: ICollectionItemProps): JSX.Element => {
  const { classes, cx } = useCollectionItemStyles();

  return (
    <Paper
      component="label"
      className={cx(
        classes.root,
        isActive && classes.active,
        isError && classes.error,
        className,
      )}
    >
      <Box display="flex" alignItems="center">
        <Radio value={collection.id} className={classes.radio} />
        {collection.name}
      </Box>
      <img
        alt=""
        src={collection.logoImage.fileUri}
        className={classes.image}
      />
    </Paper>
  );
};
