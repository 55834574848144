import { SEARCH_DEBOUNCE_TIME, ZERO } from 'modules/common/const';
import { ReactText } from 'modules/common/types';
import { useLazyGetCollectionsListQuery } from 'modules/nft-manager/actions/useLazyGetCollectionsListQuery';
import { TimeFrames } from 'modules/nft-manager/const';
import { ICollectionsListData } from 'modules/nft-manager/types';
import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export const useCollectionsListData = (): ICollectionsListData => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<ReactText>('');
  const [timeFrame, setTimeFrame] = useState<TimeFrames>(TimeFrames.Today);
  const [getCollectionsList, { data, isFetching }] =
    useLazyGetCollectionsListQuery();

  useEffect(() => {
    getCollectionsList({
      pageNumber: page,
    });
  }, [getCollectionsList, page]);

  const fetchProjectsWithSearchField = (value: ReactText) => {};

  const debouncedFetchProjectList = useDebouncedCallback(
    fetchProjectsWithSearchField,
    SEARCH_DEBOUNCE_TIME,
  );

  const handleSearchChange = (value: ReactText) => {
    setSearch(value);
    debouncedFetchProjectList(value);
  };

  const changePage = (page: number) => {
    setPage(page);
  };

  const handleChangeTimeFrame = useCallback(
    (timeFrame: TimeFrames) => {
      setTimeFrame(timeFrame);
    },
    [setTimeFrame],
  );

  return {
    data: data?.items,
    isLoading: isFetching,
    page,
    totalCount: data?.totalCount ?? 0,
    totalPages: data?.totalPages ?? 1,
    // TODO: remove these fields
    hasNextPage: false,
    hasPreviousPage: false,
    searchValue: search,
    timeFrame,
    handleChangeTimeFrame,
    setSearch: handleSearchChange,
    // TODO: end
    changePage,
    nftSold: (10 + timeFrame) * 2,
    usdSold: ZERO.plus(13_789).plus(timeFrame * 7_312.12),
  };
};
