import {
  Components,
  formControlLabelClasses,
  Palette,
  radioClasses,
} from '@mui/material';

function RadioIcon(): JSX.Element {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="12"
        cy="12"
        r="10"
        fill="var(--radio-background)"
        stroke="var(--radio-border)"
        strokeWidth="2"
      />
      <circle cx="12" cy="12" r="4" fill="var(--radio-checked)" />
    </svg>
  );
}

export const getMuiRadio = (palette: Palette): Components['MuiRadio'] => ({
  defaultProps: {
    checkedIcon: <RadioIcon />,
    icon: <RadioIcon />,
  },

  styleOverrides: {
    root: {
      textTransform: 'none',
      fontFeatureSettings: "'case' on, 'cv11' on, 'calt' off",
      boxShadow: 'none',
      padding: 0,

      '--radio-background': palette.background.paper,
      '--radio-border': palette.common.black,
      '--radio-checked': 'unset',

      '&:hover': {
        '--radio-border': palette.violet[80],
      },

      [`&.${radioClasses.checked}`]: {
        '--radio-background': palette.violet.main,
        '--radio-border': palette.violet.main,
        '--radio-checked': palette.background.paper,

        '&:hover': {
          '--radio-background': palette.violet[80],
          '--radio-border': palette.violet[80],
        },
      },

      [`&.${radioClasses.disabled}`]: {
        '--radio-background': palette.background.paper,
        '--radio-border': palette.grey[700],
        '--radio-checked': 'unset',

        [`&.${radioClasses.checked}`]: {
          '--radio-background': palette.violet.main,
          '--radio-border': palette.violet.main,
          '--radio-checked': palette.grey[300],
        },
      },
    },
  },

  variants: [
    {
      props: { size: 'medium' },
      style: {
        width: 20,
        height: 20,

        [`+.${formControlLabelClasses.label}`]: {
          // margin: spacing(0, 0, 0, 3),
          fontWeight: 400,
          fontSize: 16,
          lineHeight: 1.5,
        },
      },
    },
  ],
});
