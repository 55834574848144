import { PropsWithChildren } from 'react';
import { CssBaseline } from '@mui/material';
import { ReduxRouter } from '@lagunovsky/redux-react-router';
import { OryLifecycleProvider } from 'modules/common/components/OryLifecycleProvider';
import { historyInstance } from '../common/utils/historyInstance';
import { Notifications } from '../common/components/Notifications';
import { useInitializeLocale } from '../../useInitializeLocale';

export const AppBase = ({ children }: PropsWithChildren): JSX.Element => {
  const isInitialized = useInitializeLocale();

  return (
    <>
      {isInitialized ? (
        <>
          <CssBaseline />
          <Notifications />
          <OryLifecycleProvider>
            <ReduxRouter history={historyInstance}>{children}</ReduxRouter>
          </OryLifecycleProvider>
        </>
      ) : (
        <>loading</>
      )}
    </>
  );
};
