import {
  COMMON_ERROR,
  configFromEnv,
  GET_POST_NFT_TOKEN,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
} from '../../api';
import { oryLoginAsync } from 'modules/auth';
import { RESPONSE_CODES } from '../../common/types';

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

interface IReponse {
  id: string;
}

// TODO: add royalty
interface INewNft {
  Content: File;
  Status: number;
  Uri: string;
  Price: number;
  Name: string;
  Description: string;
  Quantity: number | null;
  NftContractId: string;
  Royalty: number;
  ChainCodes: string[];
  NftCollectionId: string;
  NftProperties:
    | {
        type: string;
        name: string;
        value: string;
      }[]
    | '';
}

export const { usePostNftMutation } = web3Api.injectEndpoints({
  endpoints: build => ({
    postNft: build.mutation<IReponse, INewNft>({
      queryFn: queryFnNotifyWrapper<INewNft, never, IReponse>(
        async (data: INewNft) => {
          const bodyData = new FormData();
          bodyData.append('Name', data.Name);
          bodyData.append('Description', data.Description);
          bodyData.append('Content', data.Content);
          if (!!data.Quantity) {
            bodyData.append('Quantity', data.Quantity.toString());
          }
          bodyData.append('Status', data.Status.toString());
          bodyData.append('Uri', data.Uri);
          bodyData.append('Price', data.Price.toString());
          bodyData.append('NftContractId', data.NftContractId);
          bodyData.append('Royalty', data.Royalty.toString());
          bodyData.append('ChainCodes', data.ChainCodes.join(','));
          bodyData.append('NftCollectionId', data.NftCollectionId);
          bodyData.append(
            'NftProperties',
            typeof data.NftProperties === 'object'
              ? data.NftProperties.map(x => JSON.stringify(x)).join(',')
              : '',
          );
          bodyData.append(
            'NftPropertiesString',
            JSON.stringify(data.NftProperties),
          );

          const request = await fetch(nftManagerUrl + GET_POST_NFT_TOKEN, {
            method: 'POST',
            body: bodyData,
            credentials: 'include',
          });

          if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
            await oryLoginAsync();
            throw UNAUTHORIZED_ERROR;
          }
          if (!request.ok) {
            throw COMMON_ERROR;
          }
          return { data: await request.json() };
        },
      ),
    }),
  }),
});
