import { featuresConfig } from 'modules/common/featuresConfig';
import React from 'react';
import { generatePath, Route } from 'react-router-dom';
import { Layout } from '../common/components/Layout';
import { createRouteConfig } from '../router';
import { Manager } from './screens/Manager';
import { UploadAndPublish } from './screens/UploadAndPublish';
import { Wizard } from './screens/Wizard';

const ROOT_PATH = '/';
const MANAGER_PATH = `${ROOT_PATH}contract-manager/`;
const UPLOAD_PATH = `${MANAGER_PATH}upload/`;
const WIZARD_PATH = `${ROOT_PATH}contract-wizard/`;

export const RoutesConfig = createRouteConfig(
  {
    upload: {
      path: UPLOAD_PATH,
      generatePath: () => generatePath(UPLOAD_PATH),
    },

    manager: {
      path: MANAGER_PATH,
      generatePath: () => generatePath(MANAGER_PATH),
    },

    wizard: {
      path: WIZARD_PATH,
      generatePath: () => generatePath(WIZARD_PATH),
    },
  },

  ROOT_PATH,
);

export function getRoutes(): JSX.Element {
  return (
    <>
      <Route
        element={
          <Layout>
            <UploadAndPublish />
          </Layout>
        }
        path={RoutesConfig.upload.path}
      />
      <Route
        element={
          <Layout>
            <Manager />
          </Layout>
        }
        path={RoutesConfig.manager.path}
      />
      {featuresConfig.isActiveWizard && (
        <Route
          element={
            <Layout>
              <Wizard />
            </Layout>
          }
          path={RoutesConfig.wizard.path}
        />
      )}
    </>
  );
}
