import { ButtonBase, Typography } from '@mui/material';
import { usePaginationStyles } from './usePaginationStyles';

interface IPaginationItemProps {
  page: number;
  isActive: boolean;
  changePage: (value: number) => void;
}

export const PaginationItem = ({
  page,
  isActive,
  changePage,
}: IPaginationItemProps) => {
  const { classes, cx } = usePaginationStyles();

  return (
    <ButtonBase
      onClick={() => changePage(page)}
      className={cx(
        classes.itemRoot,
        classes.itemNumberRoot,
        isActive && classes.active,
      )}
    >
      <Typography>{page}</Typography>
    </ButtonBase>
  );
};
