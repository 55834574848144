import { makeStyles } from 'tss-react/mui';

export const useProjectIconStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
  },
  img: {
    width: 28,
    height: 28,
  },
  itemWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.primary,

    '&:hover': {
      '& > :last-child': {
        display: 'flex',
      },
    },
  },
  smallImg: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(3),
  },
  iconWrapperLink: {
    display: 'flex',
  },
  iconWrapper: {
    marginRight: theme.spacing(1),

    '&:last-child': {
      marginRight: 0,
    },
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 28,
    width: 28,
    border: `1px solid ${theme.palette.grey[700]}`,
    borderRadius: 6,
  },
  name: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 82,
  },
  icon: {
    display: 'none',
    transform: 'rotate(-90deg)',
  },
}));
