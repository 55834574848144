import Typography from '@mui/material/Typography';
import React, { cloneElement } from 'react';
import { usePlatformStyles } from './usePlatformStyles';
import {
  EPlatform,
  PLATFORM_ICON,
  PLATFORM_TITLE,
} from 'modules/projects/const';
import { t } from 'modules/utils/intl';
import { uid } from 'react-uid';

interface Props {
  types: EPlatform[];
  className?: string;
}

export const Platform = ({ types, className }: Props): JSX.Element => {
  const { classes, cx } = usePlatformStyles();

  return (
    <>
      {types.map(type => (
        <div key={uid(type)} className={cx(classes.root, className)}>
          {cloneElement(PLATFORM_ICON[type], {
            className: classes.icon,
          })}
          <Typography variant="body2">{t(PLATFORM_TITLE[type])}</Typography>
        </div>
      ))}
    </>
  );
};
