import {
  Button,
  Dialog as BaseDialog,
  DialogContent,
  DialogTitle,
  DialogProps,
  Box,
} from '@mui/material';
import { ReactComponent as CloseIcon } from 'uiKit/icons/close.svg';
import { useDialogStyles } from './useDialogStyles';

interface IDialogProps extends DialogProps {
  title?: string;
  titleIcon?: JSX.Element;
  isHiddenCloseBtn?: boolean;
  closeButtonClassName?: string;
  isBigSize?: boolean;
  isDisabledBackdropClick?: boolean;
  onClose?: () => void;
}

export const Dialog = ({
  children,
  classes: dialogClasses,
  className,
  closeButtonClassName,
  title,
  titleIcon,
  isHiddenCloseBtn = false,
  isBigSize = false,
  isDisabledBackdropClick = false,
  open,
  PaperProps,
  onClose,
  ...restDialogProps
}: IDialogProps): JSX.Element => {
  const { classes, cx } = useDialogStyles();

  return (
    <BaseDialog
      {...restDialogProps}
      fullWidth
      classes={{
        ...dialogClasses,
        paper: cx(
          classes.root,
          isBigSize ? classes.bigSize : classes.defaultSize,
          className,
        ),
      }}
      maxWidth="sm"
      open={open}
      PaperProps={{ ...PaperProps, square: false, variant: 'elevation' }}
      scroll="body"
      onClose={(_, reason) =>
        reason === 'backdropClick' && isDisabledBackdropClick ? null : onClose
      }
    >
      {(title || !isHiddenCloseBtn) && (
        <DialogTitle className={classes.title}>
          <Box display="flex">
            {titleIcon && titleIcon}
            {title && title}
          </Box>
          {!isHiddenCloseBtn && (
            <Button
              className={cx(classes.closeBtn, closeButtonClassName)}
              variant="outlined"
              onClick={onClose}
            >
              <CloseIcon />
            </Button>
          )}
        </DialogTitle>
      )}
      <DialogContent className={classes.content}>{children}</DialogContent>
    </BaseDialog>
  );
};
