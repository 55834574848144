import Typography from '@mui/material/Typography';
import { useProjectMembersStyles } from './useProjectMembersStyles';

interface IUserItem {
  name: string;
  email: string;
}

export const UserItem = ({ name, email }: IUserItem): JSX.Element => {
  const { classes } = useProjectMembersStyles();

  return (
    <div>
      <Typography variant="body1">{name}</Typography>
      <Typography className={classes.email} variant="body2">
        {email}
      </Typography>
    </div>
  );
};
