import { makeStyles } from 'tss-react/mui';

export const useActionCellStyles = makeStyles()(theme => ({
  mr: {
    marginRight: theme.spacing(5),
  },
  button: {
    borderRadius: 6,
  },
}));
