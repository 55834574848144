import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { featuresConfig } from 'modules/common/featuresConfig';
import { RoutesConfig as RoutesSCConfig } from 'modules/smart-contracts/Routes';
import {
  ROOT_PATH as NFT_ROOT_PATH,
  RoutesConfig as RoutesNftConfig,
} from 'modules/nft-manager/Routes';
import { t } from 'modules/utils/intl';
import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { uid } from 'react-uid';
import { ReactComponent as ChevronDownIcon } from 'uiKit/icons/chevron-down.svg';
import {
  checkPathMatch,
  checkPathMatchForBatchLinks,
} from '../../utils/checkPathMatch';
import { useNestedMenuStyles } from './useNestedMenuStyles';

interface NestedMenuProps {
  className?: string;
}

interface NestedMenuNestedItemProps {
  title: string;
  to: string;
  disabled?: boolean;
}

interface NestedMenuItemProps {
  title: string;
  to?: string;
  items?: NestedMenuNestedItemProps[];
}

// todo: check why 't' function doesn't work!
const MENU = [
  {
    title: 'layout.menu.smart-contracts',
    items: [
      {
        title: 'layout.menu.contract-manager',
        to: RoutesSCConfig.manager.generatePath(),
      },
      {
        title: 'layout.menu.contract-wizard',
        to: RoutesSCConfig.wizard.generatePath(),
        disabled: !featuresConfig.isActiveWizard,
      },
    ],
  },
  ...(featuresConfig.isActiveNFTManager
    ? [
        {
          title: 'layout.menu.nft-manager',
          to: RoutesNftConfig.collectionsList.generatePath(),
        },
      ]
    : []),
];

function NestedMenuItem({
  title,
  items,
  to,
}: NestedMenuItemProps): JSX.Element {
  const { classes, cx } = useNestedMenuStyles();
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!!items) {
      const isMatch = checkPathMatchForBatchLinks(
        pathname,
        items.map(x => x.to),
      );
      if (isMatch) setOpen(true);
    }
  }, [items, pathname]);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box className={classes.root}>
      {!!items && !to && (
        <ListItemButton onClick={handleClick}>
          <>
            <ListItemText primary={t(title)} />
            <ChevronDownIcon
              className={cx(classes.icon, open && classes.transformed)}
            />
          </>
        </ListItemButton>
      )}

      {!items && !!to && (
        <NavLink
          to={to}
          className={cx(
            classes.ordinaryMenuItem,
            checkPathMatch(pathname, NFT_ROOT_PATH) && classes.active,
          )}
        >
          {t(title)}
        </NavLink>
      )}

      {!!items && (
        <Collapse in={open}>
          <List component="div" disablePadding>
            {items.map(item => {
              const isActive = checkPathMatch(pathname, item.to);
              return (
                <ListItemButton
                  disabled={item.disabled}
                  className={cx(classes.item, isActive && classes.active)}
                  key={uid(item)}
                  sx={{ pl: 7 }}
                >
                  <NavLink className={classes.expandMenuItem} to={item.to}>
                    {t(item.title)}
                  </NavLink>
                </ListItemButton>
              );
            })}
          </List>
        </Collapse>
      )}
    </Box>
  );
}

export function NestedMenu({ className }: NestedMenuProps): JSX.Element {
  const { classes, cx } = useNestedMenuStyles();

  return (
    <Box className={cx(classes.root, className)}>
      {MENU.map(({ title, items, to }) => (
        <NestedMenuItem
          key={uid(items || to)}
          items={items}
          to={to}
          title={title}
        />
      ))}
    </Box>
  );
}
