import {
  COMMON_ERROR,
  configFromEnv,
  CONTRACT_FILES,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
} from 'modules/api';
import { oryLoginAsync } from 'modules/auth';
import { RESPONSE_CODES } from 'modules/common/types';

interface IDeleteFileArgs {
  fileId: string;
}

const {
  gatewayConfig: { gamingUrl },
} = configFromEnv();

export const { useDeleteUploadedFileMutation } = web3Api.injectEndpoints({
  endpoints: build => ({
    deleteUploadedFile: build.mutation<boolean, IDeleteFileArgs>({
      queryFn: queryFnNotifyWrapper<IDeleteFileArgs, never, boolean>(
        async (data: IDeleteFileArgs) => {
          const request = await fetch(
            gamingUrl + CONTRACT_FILES + '/' + data.fileId,
            {
              method: 'DELETE',
              credentials: 'include',
            },
          );

          if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
            await oryLoginAsync();
            throw UNAUTHORIZED_ERROR;
          }
          if (!request.ok) throw COMMON_ERROR;

          return {
            data: true,
          };
        },
      ),
    }),
  }),
});
