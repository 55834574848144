import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import { ReactComponent as ChevronDownIcon } from 'uiKit/icons/chevron-small-down.svg';
import { RoutesConfig } from '../../../../Routes';
import { Platform } from '../Platform';
import { useItemStyles } from './useItemStyles';
import { IProject } from 'modules/projects/types';
import { DefaultProjectIcon } from 'modules/common/components/DefaultProjectIcon';

interface Props {
  project: IProject;
  index: number;
}

export const Item = ({ project, index }: Props): JSX.Element => {
  const { classes } = useItemStyles();

  return (
    <NavLink to={RoutesConfig.project.generatePath(project.id)}>
      <div className={classes.listItem}>
        <div className={classes.logoBlock}>
          {!!project.image ? (
            <img alt="" src={project.image.fileUri} className={classes.logo} />
          ) : (
            <DefaultProjectIcon index={index} className={classes.logo} />
          )}

          <div className={classes.title}>
            <Typography variant="h3" className={classes.name}>
              {project.projectName}
            </Typography>
          </div>
        </div>
        {!!project.platformTypes && project.platformTypes.length > 0 && (
          <div className={classes.platforms}>
            <Platform types={project.platformTypes} />
          </div>
        )}
        <div className={classes.action}>
          <div className={classes.arrow}>
            <ChevronDownIcon />
          </div>
        </div>
      </div>
    </NavLink>
  );
};
