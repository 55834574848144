import { Box } from '@mui/material';
import { featuresConfig } from '../../../../../common/featuresConfig';
import { t } from '../../../../../utils/intl';
import { STEP } from '../../../../types';
import { StepItem } from './StepItem';

interface IStepper {
  step: STEP;
  goToUploadStep: () => void;
  goToCompileStep: () => void;
  goToPublishStep: () => void;
}

export const Stepper = ({
  step,
  goToUploadStep,
  goToCompileStep,
  goToPublishStep,
}: IStepper): JSX.Element => {
  return (
    <Box display="flex" flexDirection="row" mb={14.5}>
      <StepItem
        title={t('smart-contracts.step-1')}
        step={STEP.UPLOAD}
        isActive={step === STEP.UPLOAD}
        isDone={STEP.UPLOAD < step}
        goToStep={goToUploadStep}
      />
      {featuresConfig.isAvailableMerging && (
        <StepItem
          title={t('smart-contracts.step-2')}
          step={STEP.MERGE}
          isActive={step === STEP.MERGE}
          isDone={STEP.MERGE < step}
          goToStep={() => null}
        />
      )}
      <StepItem
        title={t('smart-contracts.step-3')}
        step={STEP.COMPILE}
        isActive={step === STEP.COMPILE}
        isDone={STEP.COMPILE < step}
        goToStep={goToCompileStep}
      />
      <StepItem
        title={t('smart-contracts.step-4')}
        step={STEP.PUBLISH}
        isActive={step === STEP.PUBLISH}
        isDone={STEP.PUBLISH < step}
        goToStep={goToPublishStep}
      />
    </Box>
  );
};
