import { makeStyles } from 'tss-react/mui';

export const useHeaderStyles = makeStyles()(theme => ({
  root: {
    marginBottom: theme.spacing(15),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  secondaryColor: {
    color: theme.palette.text.secondary,
  },
  button: {
    padding: 0,
    width: 160,
  },
  mr3: {
    marginRight: theme.spacing(3),
  },
}));
