import {
  COMMON_ERROR,
  configFromEnv,
  CONTRACT_VERSION,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
} from 'modules/api';
import { oryLoginAsync } from 'modules/auth';
import { RESPONSE_CODES } from 'modules/common/types';

interface IDeleteCVArgs {
  id: string;
}

const {
  gatewayConfig: { gamingUrl },
} = configFromEnv();

export const { useDeleteCVMutation } = web3Api.injectEndpoints({
  endpoints: build => ({
    deleteCV: build.mutation<boolean, IDeleteCVArgs>({
      queryFn: queryFnNotifyWrapper<IDeleteCVArgs, never, boolean>(
        async (data: IDeleteCVArgs) => {
          const request = await fetch(
            gamingUrl + CONTRACT_VERSION + '/' + data.id,
            {
              method: 'DELETE',
              credentials: 'include',
            },
          );

          if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
            await oryLoginAsync();
            throw UNAUTHORIZED_ERROR;
          }
          if (!request.ok) throw COMMON_ERROR;

          return {
            data: true,
          };
        },
      ),
    }),
  }),
});
