import { Components, Palette, selectClasses } from '@mui/material';
import { SVGProps } from 'react';

function ArrowDownIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 8L12 15L19 8" stroke="var(--select-arrow)" strokeWidth="2" />
    </svg>
  );
}

export function getMuiSelect(palette: Palette): Components['MuiSelect'] {
  return {
    defaultProps: {
      variant: 'standard',
      fullWidth: true,
      IconComponent: props => <ArrowDownIcon {...props} />,
    },
    styleOverrides: {
      select: {
        '&:focus': {
          backgroundColor: 'unset',
        },
      },

      icon: {
        top: 'unset',
        right: 20,
        transition: '0.25s',
        '--select-arrow': palette.common.black,

        [`&.${selectClasses.disabled}`]: {
          '--select-arrow': palette.grey[700],
        },
      },
    },
  };
}
