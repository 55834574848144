import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { uid } from 'react-uid';
import { t } from '../../../../../utils/intl';
import { Tab, Tabs } from '../../../../../common/components/Tabs';
import { Item } from './Item';
import { useListStyles } from './useListStyles';
import { featuresConfig } from '../../../../../common/featuresConfig';
import { IProject } from 'modules/projects/types';

interface Props {
  activeProjects?: IProject[];
  archivedProjects?: IProject[];
}

export const List = ({
  activeProjects,
  archivedProjects,
}: Props): JSX.Element => {
  const { classes } = useListStyles();

  const activeProjectsText = t('projects.projectList.active');
  const archivedProjectsText = t('projects.projectList.archived');

  const [currentTab, setCurrentTab] = useState<string>(activeProjectsText);

  const handleChangeTab = (newTab: string) => setCurrentTab(newTab);

  return (
    <div className={classes.root}>
      <div className={classes.tabs}>
        <Tabs value={currentTab} onChangeTab={handleChangeTab}>
          <Tab
            key={activeProjectsText}
            activeTab={currentTab}
            title={activeProjectsText}
            value={activeProjectsText}
          />
          {featuresConfig.isExistArchivedProjects && (
            <Tab
              key={archivedProjectsText}
              activeTab={currentTab}
              title={archivedProjectsText}
              value={archivedProjectsText}
            />
          )}
        </Tabs>
      </div>
      <div className={classes.list}>
        <div className={classes.listHeader}>
          <Typography variant="caption" textTransform="uppercase">
            {t('projects.projectList.name')}
          </Typography>
          <Typography variant="caption" textTransform="uppercase">
            {t('projects.projectList.connected')}
          </Typography>
        </div>
        <div>
          {currentTab === activeProjectsText &&
            activeProjects?.map((project, index) => (
              <Item project={project} index={index} key={uid(project)} />
            ))}
          {currentTab === archivedProjectsText &&
            archivedProjects?.map((project, index) => (
              <Item project={project} index={index} key={uid(project)} />
            ))}
        </div>
      </div>
    </div>
  );
};
