import { Button, RadioGroup, Typography } from '@mui/material';
import { RoutesConfig } from 'modules/nft-manager/Routes';
import { TokenStandart } from 'modules/nft-manager/types';
import { t } from 'modules/utils/intl';
import { ChangeEvent, useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { DescriptionItem } from './components/DescriptionItem';
import { TokenCard } from './components/TokenCard';
import { useSelectTokenStandartStyles } from './useSelectTokenStandartStyles';

export const SelectTokenStandart = (): JSX.Element => {
  const { classes } = useSelectTokenStandartStyles();
  const [tokenStandart, setTokenStandart] = useState<TokenStandart | undefined>(
    undefined,
  );

  const handleChangeTokenStandart = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setTokenStandart(event.target.value as TokenStandart);
    },
    [setTokenStandart],
  );

  return (
    <div className={classes.root}>
      <div className={classes.selectWrapper}>
        <Typography flex={1} variant="h3" mr={18}>
          {t('nft.erc-standart-select.select-token')}
        </Typography>
        <RadioGroup
          className={classes.radioWrapper}
          onChange={handleChangeTokenStandart}
        >
          <TokenCard
            isActive={tokenStandart === TokenStandart.ERC721}
            value={TokenStandart.ERC721}
            tokenName={t('nft.erc-standart-select.721-token')}
          />
          <TokenCard
            isActive={tokenStandart === TokenStandart.ERC1155}
            value={TokenStandart.ERC1155}
            tokenName={t('nft.erc-standart-select.1155-token')}
          />
        </RadioGroup>
      </div>
      <Typography flex={1} variant="h3" mb={7}>
        {t('nft.erc-standart-select.diff-title')}
      </Typography>

      <div>
        <DescriptionItem
          isHeader
          name=""
          firstText={t('nft.erc-standart-select.721.title')}
          secondText={t('nft.erc-standart-select.1155.title')}
        />
        <DescriptionItem
          name={t('nft.erc-standart-select.description')}
          firstText={t('nft.erc-standart-select.721.description')}
          secondText={t('nft.erc-standart-select.1155.description')}
        />
        <DescriptionItem
          name={t('nft.erc-standart-select.instances')}
          firstText={t('nft.erc-standart-select.721.instances')}
          secondText={t('nft.erc-standart-select.1155.instances')}
        />
        <DescriptionItem
          name={t('nft.erc-standart-select.support')}
          firstText={t('nft.erc-standart-select.721.support')}
          secondText={t('nft.erc-standart-select.1155.support')}
        />
        <DescriptionItem
          name={t('nft.erc-standart-select.transfers')}
          firstText={t('nft.erc-standart-select.721.transfers')}
          secondText={t('nft.erc-standart-select.1155.transfers')}
        />
        <DescriptionItem
          name={t('nft.erc-standart-select.fee')}
          firstText={t('nft.erc-standart-select.721.fee')}
          secondText={t('nft.erc-standart-select.1155.fee')}
        />
        <DescriptionItem
          name={t('nft.erc-standart-select.security')}
          firstText={t('nft.erc-standart-select.721.security')}
          secondText={t('nft.erc-standart-select.1155.security')}
        />
      </div>

      <NavLink to={RoutesConfig.mintNft.generatePath(tokenStandart)}>
        <Button fullWidth disabled={!tokenStandart} variant="contained">
          {t('nft.erc-standart-select.continue')}
        </Button>
      </NavLink>
    </div>
  );
};
