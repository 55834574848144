import { Box, ButtonBase } from '@mui/material';
import { ReactComponent as CancelIcon } from 'uiKit/icons/cancel.svg';
import { ReactComponent as RetryIcon } from 'uiKit/icons/retry.svg';
import { ReactComponent as TrashIcon } from 'uiKit/icons/trash.svg';
import { FILE_STATUS } from '../../../../types';
import { useActionCellStyles } from './useActionCellStyles';

interface IActionCellProps {
  status: FILE_STATUS;
  isDeleteLoading: boolean;
  isRetryUploadLoading: boolean;
  handleCancel?: () => void;
  handleDelete: () => void;
  handleRetry?: () => void;
}

export const ActionCell = ({
  status,
  isDeleteLoading,
  isRetryUploadLoading,
  handleCancel,
  handleDelete,
  handleRetry,
}: IActionCellProps): JSX.Element => {
  const { classes, cx } = useActionCellStyles();
  const isUploading = status === FILE_STATUS.UPLOADING;
  const isUploaded = status === FILE_STATUS.UPLOADED;

  return (
    <>
      {typeof handleCancel === 'function' && isUploading && (
        <ButtonBase onClick={handleCancel} className={classes.button}>
          <CancelIcon />
        </ButtonBase>
      )}
      {!isUploading && (
        <Box>
          {typeof handleRetry === 'function' && !isUploaded && (
            <ButtonBase
              disabled={isRetryUploadLoading}
              onClick={handleRetry}
              className={cx(classes.button, classes.mr)}
            >
              <RetryIcon />
            </ButtonBase>
          )}
          <ButtonBase
            disabled={isDeleteLoading}
            onClick={handleDelete}
            className={classes.button}
          >
            <TrashIcon />
          </ButtonBase>
        </Box>
      )}
    </>
  );
};
