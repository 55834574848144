import { makeStyles } from 'tss-react/mui';

export const useProjectMembersStyles = makeStyles()(theme => ({
  root: {
    marginBottom: theme.spacing(7),
  },
  headerCell: {
    color: theme.palette.grey[900],
  },
  chip: {
    letterSpacing: '0.01em',
  },
  email: {
    color: theme.palette.text.secondary,
  },
  mb6: {
    marginBottom: theme.spacing(6),
  },
  moreIcon: {
    color: theme.palette.text.primary,
  },
}));
