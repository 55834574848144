import { Box } from '@mui/material';
import { Header } from './component/Header';
import { List } from './component/List';

export const Manager = (): JSX.Element => {
  return (
    <Box mb={5}>
      <Header />
      <List />
    </Box>
  );
};
