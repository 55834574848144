import { makeStyles } from 'tss-react/mui';

export const useStatusItemStyles = makeStyles()(theme => ({
  secondColor: {
    color: theme.palette.text.secondary,
  },
  ml2: {
    marginLeft: theme.spacing(2),
  },
  mr2: {
    marginRight: theme.spacing(2),
  },

  viewMore: {
    color: theme.palette.violet.main,
    marginLeft: theme.spacing(2),
    cursor: 'pointer',
  },
}));
