import { ButtonBase, Tooltip, TooltipProps } from '@mui/material';
import { ReactComponent as TooltipIconSvg } from 'uiKit/icons/tooltip.svg';
import { useTooltipIconStyles } from './useTooltipIconStyles';

interface ITooltipProps extends Omit<TooltipProps, 'children'> {
  className?: string;
}

export const TooltipIcon = ({
  className,
  ...restProps
}: ITooltipProps): JSX.Element | null => {
  const { classes, cx } = useTooltipIconStyles();

  return restProps.title ? (
    <Tooltip arrow {...restProps}>
      <ButtonBase className={cx(classes.btn, className)} component="span">
        <TooltipIconSvg className={classes.icon} />
      </ButtonBase>
    </Tooltip>
  ) : null;
};
