import {
  checkboxClasses,
  Components,
  formControlLabelClasses,
  Palette,
} from '@mui/material';

function CheckboxIcon(): JSX.Element {
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1"
        y="1"
        width="18"
        height="18"
        rx="3"
        fill="var(--checkbox-background)"
        stroke="var(--checkbox-border)"
        strokeWidth="2"
      />
      <path
        d="M5 9L9 13L15 7"
        stroke="var(--checkbox-checked)"
        strokeWidth="2"
      />
    </svg>
  );
}

export const getMuiCheckbox = (
  palette: Palette,
): Components['MuiCheckbox'] => ({
  defaultProps: {
    icon: <CheckboxIcon />,
    checkedIcon: <CheckboxIcon />,
    indeterminateIcon: <CheckboxIcon />,
  },
  styleOverrides: {
    root: {
      textTransform: 'none',
      fontFeatureSettings: "'case' on, 'cv11' on, 'calt' off",
      boxShadow: 'none',
      padding: 0,
      width: 20,
      height: 20,
      borderRadius: 4,

      '--checkbox-background': palette.background.paper,
      '--checkbox-border': palette.common.black,
      '--checkbox-checked': 'unset',

      '&:hover': {
        '--checkbox-border': palette.violet[80],
      },

      [`&.${checkboxClasses.checked}`]: {
        '--checkbox-background': palette.violet.main,
        '--checkbox-border': palette.violet.main,
        '--checkbox-checked': palette.background.paper,

        '&:hover': {
          '--checkbox-background': palette.violet[80],
          '--checkbox-border': palette.violet[80],
        },
      },

      [`&.${checkboxClasses.disabled}`]: {
        '--checkbox-background': palette.background.paper,
        '--checkbox-border': palette.grey[700],
        '--checkbox-checked': 'unset',

        [`&.${checkboxClasses.checked}`]: {
          '--checkbox-background': palette.violet.main,
          '--checkbox-border': palette.violet.main,
          '--checkbox-checked': palette.grey[300],
        },
      },
    },
  },

  variants: [
    {
      props: { size: 'medium' },
      style: {
        width: 20,
        height: 20,

        [`+.${formControlLabelClasses.label}`]: {
          // margin: spacing(0, 0, 0, 3),
          fontWeight: 400,
          fontSize: 16,
          lineHeight: 1.5,
        },
      },
    },
  ],
});
