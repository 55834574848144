import { Box, Paper, Radio } from '@mui/material';
import { NetworkOrTokenIcon } from 'modules/common/components/NetworkOrTokenIcon';
import { NftMintNetwork } from 'modules/common/types';
import { useNetworkItemStyles } from './useNetworkItemStyles';

interface INetworkItemProps {
  className?: string;
  isActive: boolean;
  networkName: string;
  network: NftMintNetwork;
  isError: boolean;
}

export const NetworkItem = ({
  className,
  isActive,
  networkName,
  network,
  isError,
}: INetworkItemProps): JSX.Element => {
  const { classes, cx } = useNetworkItemStyles();
  return (
    <Paper
      component="label"
      className={cx(
        classes.root,
        isActive && classes.active,
        isError && classes.error,
        className,
      )}
    >
      <Box display="flex" alignItems="center">
        <Radio value={network} className={classes.checkBox} />
        {networkName}
      </Box>
      <NetworkOrTokenIcon name={network} />
    </Paper>
  );
};
