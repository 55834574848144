import BigNumber from 'bignumber.js';
import { useGetEthPriceQuery } from 'modules/common/actions/useGetEthPriceQuery';
import { useGetMaticPriceQuery } from 'modules/common/actions/useGetMaticPriceQuery';
import { ACTION_CACHE_SEC, ZERO, ZERO_ADDRESS } from 'modules/common/const';
import { useDialog } from 'modules/common/hooks';
import { NftMintNetwork, Token, TTokenStandart } from 'modules/common/types';
import { getNativeToken } from 'modules/common/utils/getNativeToken';
import { useGetCollectionQuery } from 'modules/nft-manager/actions/useGetCollectionQuery';
import { useGetNftContractsQuery } from 'modules/nft-manager/actions/useGetNftContractsQuery';
import { useGetNftQuery } from 'modules/nft-manager/actions/useGetNftQuery';
import { useGetNftTemplatesQuery } from 'modules/nft-manager/actions/useGetNftTemplatesQuery';
import { RoutesConfig } from 'modules/nft-manager/Routes';
import {
  ICollectionTokensItem,
  INftInfoData,
  INftItem,
  NftStatus,
} from 'modules/nft-manager/types';
import { useCallback } from 'react';
import { useParams } from 'react-router';

export interface IExtendedNftInfoData extends INftInfoData {
  isLoading: boolean;
  moreNfts: INftItem[];
  token: Token;
  network: NftMintNetwork;
  tokenPrice: BigNumber;
  collection?: ICollectionTokensItem;
  usdPrice: BigNumber;
  plazaLink: string;
  collectionLink: string;
  tokenTemplate: TTokenStandart;
  isOpenLaunch: boolean;
  isLaunchLoading: boolean;
  onClose: () => void;
  stopSale: () => void;
  handleOpenLaunch: () => void;
  handleLaunchNft: () => void;
}

export const useNftInfoData = (): IExtendedNftInfoData => {
  const { id } = useParams();

  const { isOpened, onClose, onOpen } = useDialog();

  const { data: nftData, isFetching: isFetchingNftData } = useGetNftQuery(
    { id: id ?? '' },
    {
      skip: !id,
      refetchOnMountOrArgChange: ACTION_CACHE_SEC,
    },
  );
  const nftCollectionId = nftData?.nftCollectionId;

  const { data: nftContractsData, isFetching: isFetchingNftContractData } =
    useGetNftContractsQuery(undefined, {
      refetchOnMountOrArgChange: ACTION_CACHE_SEC,
    });
  const { data: nftTemplates, isFetching: isNftTemplatesLoading } =
    useGetNftTemplatesQuery(undefined, {
      refetchOnMountOrArgChange: ACTION_CACHE_SEC,
    });
  const { data: collectionData, isFetching: isFetchingCollectionData } =
    useGetCollectionQuery(
      { id: nftCollectionId ?? '' },
      {
        skip: !nftCollectionId,
        refetchOnMountOrArgChange: ACTION_CACHE_SEC,
      },
    );

  const network = nftData?.chainCode
    ? nftData.chainCode
    : NftMintNetwork.SepETH;
  const token = getNativeToken(network);
  const { data: ethPrice } = useGetEthPriceQuery(undefined, {
    skip: token !== Token.ETH,
    refetchOnMountOrArgChange: ACTION_CACHE_SEC,
  });
  const { data: maticPrice } = useGetMaticPriceQuery(undefined, {
    skip: token !== Token.MATIC,
    refetchOnMountOrArgChange: ACTION_CACHE_SEC,
  });

  const price = nftData?.price ?? 0;
  const tokenPrice = new BigNumber(price);
  const externalUsdPrice = ethPrice ?? maticPrice ?? ZERO;

  const tokenContractId = nftData?.nftContractId;
  const tokenContract = tokenContractId
    ? nftContractsData?.find(x => x.id === tokenContractId)?.name
    : 'ERC721Template';

  const handleLaunchNft = useCallback(() => console.log('handleLaunchNft'), []);

  // todo: timofei change hardcode
  return {
    isLoading:
      isFetchingNftData ||
      isFetchingNftContractData ||
      isFetchingCollectionData ||
      isNftTemplatesLoading,
    id,
    name: nftData?.name ?? '',
    description: nftData?.description ?? '',
    tokenId: nftData?.tokenId ?? '',
    status: nftData?.status ?? NftStatus.NotListed,
    price,
    royalty: nftData?.royalty ?? 0,
    quantity: nftData?.quantity ?? null,
    chainCode: nftData?.chainCode ?? NftMintNetwork.SepETH,
    contractAddress: nftData?.contractAddress ?? ZERO_ADDRESS,
    uri: nftData?.uri ?? '',
    nftContractId: nftData?.nftContractId ?? '',
    token,
    network,
    tokenPrice,
    usdPrice: tokenPrice.multipliedBy(externalUsdPrice),
    nftAsset: nftData?.nftAsset,
    nftProperties: nftData?.nftProperties?.filter(x => !!x.name && !!x.value),
    nftCollectionId: nftData?.nftCollectionId ?? '',
    moreNfts:
      collectionData?.nfts
        .filter(nft => nft.id !== id)
        .map(nft => {
          const nftContract = nftContractsData?.find(
            x => x.contractAddress === nft.contractAddress,
          );
          const nftTemplate = !!nftContract
            ? nftTemplates?.find(x => x.id === nftContract.nftTemplateId)
                ?.name ?? 'ERC721Template'
            : 'ERC721Template';

          let token = Token.ETH;
          if (!!nft.chainCodes && nft.chainCodes[0] === NftMintNetwork.Polygon)
            token = Token.MATIC;

          return {
            ...nft,
            network: nft.chainCodes as NftMintNetwork,
            token,
            tokenPrice: new BigNumber(nft.price),
            usdPrice: ZERO,
            address: nft.contractAddress ?? ZERO_ADDRESS,
            tokenTemplate: nftTemplate as TTokenStandart,
            instances: 1,
          };
        }) ?? [],
    collectionLink: RoutesConfig.collection.generatePath(nftCollectionId),
    plazaLink: 'plazaLink',
    collection: collectionData,
    tokenTemplate: (tokenContract as TTokenStandart) ?? 'ERC721Template',
    isOpenLaunch: isOpened,
    isLaunchLoading: false,
    onClose,
    stopSale: () => console.log('stopSale'),
    handleOpenLaunch: onOpen,
    handleLaunchNft,
  };
};
