import { ProviderManager } from '@ankr.com/provider';
import {
  COMMON_ERROR,
  configFromEnv,
  GET_IS_WALLET_CONNECTED,
  METAMASK_ID,
  queryFnNotifyWrapper,
  UNAUTHORIZED_ERROR,
  web3Api,
  web3ModalTheme,
} from '../../api';
import { oryLoginAsync } from 'modules/auth';
import { RESPONSE_CODES } from '../../common/types';

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

// TODO Refactor to singleton
export const providerManager = new ProviderManager(web3ModalTheme);

export const { useLazyGetIsWalletConnectedQuery } = web3Api.injectEndpoints({
  endpoints: build => ({
    getIsWalletConnected: build.query<boolean, void>({
      queryFn: queryFnNotifyWrapper<void, never, boolean>(async () => {
        const provider = await providerManager.getETHWriteProvider(METAMASK_ID);

        const address = provider.currentAccount;

        const request = await fetch(
          nftManagerUrl +
            GET_IS_WALLET_CONNECTED +
            '?' +
            new URLSearchParams({
              address,
            }),
          {
            method: 'GET',
            credentials: 'include',
          },
        );

        if (request.status === RESPONSE_CODES.UNAUTHORIZED) {
          await oryLoginAsync();
          throw UNAUTHORIZED_ERROR;
        }
        if (!request.ok) {
          throw COMMON_ERROR;
        }
        return { data: await request.json() };
      }),
    }),
  }),
});
