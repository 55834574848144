import { makeStyles } from 'tss-react/mui';

export const useListHeaderStyles = makeStyles()(theme => ({
  title: {
    marginBottom: theme.spacing(6),
  },

  select: {
    width: 262,
    height: 40,
  },

  mr5: {
    marginRight: theme.spacing(5),
  },

  mr2: {
    marginRight: theme.spacing(2),
  },

  viewButton: {
    width: 40,
    height: 40,
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.palette.grey[900]}`,
  },

  activeView: {
    backgroundColor: theme.palette.violet.main,
    color: theme.palette.common.white,
  },

  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    gap: 12,
    padding: theme.spacing(2, 0),
    margin: 'auto 0',
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
  },

  input: {
    border: 'none !important',
    padding: '0 !important',
    height: 30,
    width: 262,
    fontSize: 15,
    fontWeight: 500,
  },

  closeIcon: {
    marginTop: 11,
    color: theme.palette.error.main,
  },
}));
