import { Components, Palette } from '@mui/material';

export function getMuiTableContainer(
  palette: Palette,
): Components['MuiTableContainer'] {
  return {
    styleOverrides: {
      root: {
        border: 'none',
        boxShadow: 'none',
        borderRadius: 0,
      },
    },
  };
}
