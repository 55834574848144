import { Box, Paper, Radio, Typography } from '@mui/material';
import { ProjectId } from 'modules/common/components/ProjectId';
import { IProjectListItem } from 'modules/projects/actions/useLazyGetProjectListQuery';
import { useProjectItemStyles } from './useProjectItemStyles';
import { DefaultProjectIcon } from 'modules/common/components/DefaultProjectIcon';

interface IProjectItemProps {
  className?: string;
  project: IProjectListItem;
  isActive: boolean;
  isError: boolean;
  index: number;
}

export const ProjectItem = ({
  className,
  project,
  isActive,
  isError,
  index,
}: IProjectItemProps): JSX.Element => {
  const { classes, cx } = useProjectItemStyles();

  return (
    <Paper
      component="label"
      className={cx(
        classes.root,
        isActive && classes.active,
        isError && classes.error,
        className,
      )}
    >
      <Radio value={project.id} className={classes.radio} />
      {!!project.image ? (
        <img alt="" src={project.image.fileUri} className={classes.image} />
      ) : (
        <DefaultProjectIcon index={index} className={classes.image} />
      )}

      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Typography variant="h3">{project.projectName}</Typography>
        <ProjectId id={project.id} className={classes.id} />
      </Box>
    </Paper>
  );
};
